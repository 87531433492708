import { css } from 'styled-components';

export const updateGainStyles = (props: any) => {
  return css`
    display: flex;
   
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    height: 100%;
    min-height: 175px;
    border-radius: 10px;
    .div-container {
      display: flex;
      flex: 1;
      /* height: 60%; */
      width: 80%;
      /* justify-content: center; */
      align-items: center;
    }
  `;
};
