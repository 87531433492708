import { createFerry, getFerry, updateFerry } from '../apis/ferryApi';
import {
  FERRY_FORM_PROPS,
  FERRY_FORM_PROPS_CHANGED,
  AppThunk,
  FERRIES_ADD_FERRY_TO_LIST,
  FERRY_INITIAL_STATE,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
  FERRY_GET_FERRY_PROPS,
  FERRIES_GET_LIST_FERRIES,
} from './types';
import {
  FerryInitialStateAction,
  FerryPropsChangedAction,
  FerryState,
} from '../modules/Ship/ship.types';

import {
  validateFieldsFerry,
  newFerryObject,
} from '../modules/Ship/ship.utils';
import { URL_IMAGE } from '../apis/config';
import { getFerriesList } from '../apis/ferriesApi';
import { FerriesApiResponse } from '../apis/ApiTypes';

export const ferryPropsChanged = (
  prop: string,
  value: any
): FerryPropsChangedAction => {
  return { type: FERRY_FORM_PROPS_CHANGED, prop, value };
};

export const addFerry = (
  walletId: string | null,
  props: FerryState,
  callback: () => void
): AppThunk => (dispatch) => {
  const { newState, valid } = validateFieldsFerry(props);
  dispatch({ type: FERRY_FORM_PROPS, newState });
  const newFerry = newFerryObject(newState);

  if (valid) {
    dispatch({
      type: FERRY_FORM_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });

    createFerry(walletId, newFerry).then((res: any) => {
      if (res.status == 200) {
        dispatch({
          type: FERRIES_ADD_FERRY_TO_LIST,
          payload: res.data,
        });
        dispatch({
          type: SNACKBAR_SUCCESS,
          value: "L'événement a été ajouté avec succès",
        });
        callback();
      } else {
        dispatch({
          type: SNACKBAR_ERROR,
          value: 'Une erreur est survenue',
        });
        dispatch({
          type: FERRY_FORM_PROPS_CHANGED,
          prop: 'loading',
          value: false,
        });
      }
    });
  }
};

export const getFerrybyId = (walletId: string, ferryId: string): AppThunk => (
  dispatch
) => {
  getFerry(walletId, ferryId).then((res: any) => {
    const ferry = res.response.data;
    dispatch({
      type: FERRY_GET_FERRY_PROPS,
      payload: {
        ...ferry,
        strip: `${URL_IMAGE}/${ferry.strip}`,
      },
    });
  });
};

// export const editFerryConfirm = (
//   walletId: string | null,
//   ferryId: string | null,
//   props: FerryState,
//   callback: () => void
// ): AppThunk => (dispatch) => {
//   const { newState, valid } = validateFieldsFerry(props);
//   dispatch({ type: FERRY_FORM_PROPS, newState });
//   const newFerry = newFerryObject(newState);

//   if (valid) {
//     dispatch({
//       type: FERRY_FORM_PROPS_CHANGED,
//       prop: 'loading',
//       value: true,
//     });

//     updateFerry(walletId, ferryId, newFerry).then((res: any) => {
//       dispatch({
//         type: FERRY_FORM_PROPS_CHANGED,
//         prop: 'loading',
//         value: false,
//       });
//       if (res.status == 200) {
//         dispatch({
//           type: SNACKBAR_SUCCESS,
//           value: 'Le Transit a été mis à jour avec succès',
//         });
//         callback();
//       } else {
//         dispatch({
//           type: SNACKBAR_ERROR,
//           value: 'Une erreur est survenue',
//         });
//       }
//     });
//   }
// };
// export const editFerryInitialState = (ferry: any): any => {
//   return {
//     type: EDIT_FERRY_INITIAL_STATE,
//     prop: 'payload',
//     value: {
//       ...ferry,
//     },
//   };
// };

export const editFerryConfirm = (
  walletId: string | null,
  ferryId: string | null,
  props: FerryState,
  callback: () => void
): AppThunk => (dispatch) => {
  const { newState, valid } = validateFieldsFerry(props);
  dispatch({ type: FERRY_FORM_PROPS, newState });
  const newFerry = newFerryObject(newState);

  if (valid) {
    dispatch({
      type: FERRY_FORM_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });

    updateFerry(walletId,ferryId , newFerry)
      .then((res: any) => {
        if (res.status === 200) {
          dispatch({
            type: SNACKBAR_SUCCESS,
            value: 'Le transit a été modifié avec succès',
          });

          callback();
        } else if (res.status === 400) {
          dispatch({
            type: FERRY_FORM_PROPS_CHANGED,
            prop: 'messageError',
            value: 'coupon.error.datesUnavailable',
          });
          dispatch({
            type: SNACKBAR_ERROR,
            value: 'Une erreur est survenue',
          });
        }
        dispatch({
          type: FERRY_FORM_PROPS_CHANGED,
          prop: 'loading',
          value: false,
        });
      })
      .finally(() => {
        getFerriesList(ferryId as any).then((res: Array<FerriesApiResponse>) => {
          dispatch({
            type: FERRIES_GET_LIST_FERRIES,
            payload: res,
          });
        });
      });
  }
};

export const ferryInitialState = (): FerryInitialStateAction => {
  return { type: FERRY_INITIAL_STATE };
};
