import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='28'
      height='28'
      fill='none'
      viewBox='0 0 28 28'
    >
      <path
        fill='#C7C7C7'
        fillRule='evenodd'
        d='M8.866 1.069C6.906 1.46 5.47 2.883 5.09 4.806c-.12.611-.12 12.128 0 12.786a4.63 4.63 0 001.325 2.457c.6.576 1.136.895 1.963 1.168l.372.123-1.377 2.204c-1.165 1.864-1.381 2.246-1.404 2.474a.876.876 0 00.896.982c.516 0 .656-.122 1.269-1.109l.465-.75h11.816l.44.712c.638 1.03.77 1.147 1.295 1.147.55 0 .948-.437.895-.982-.023-.228-.24-.61-1.403-2.472l-1.375-2.202.537-.188a4.62 4.62 0 001.845-1.168c.735-.751 1.156-1.579 1.297-2.544.048-.328.063-2.217.05-6.53-.021-6.591 0-6.142-.343-7.04-.171-.448-.621-1.106-1.038-1.518-.409-.404-1.095-.855-1.563-1.026-.914-.335-.536-.316-6.396-.328-4.296-.008-5.483.005-5.79.067zM20.031 3.93c.423.206.673.44.893.837l.204.368V11.697l-.203.366c-.225.407-.41.582-.86.813l-.303.157H9.252l-.376-.2a1.844 1.844 0 01-.856-.873l-.134-.263V5.135l.147-.29a2 2 0 011.278-1.022c.098-.023 2.49-.039 5.315-.033l5.136.009.27.131zm-9.898 11.999a.906.906 0 01.284 1.495.913.913 0 01-1.32 0c-.735-.718.076-1.887 1.036-1.495zm9.5 0a.906.906 0 01.285 1.495c-.668.653-1.76.069-1.585-.847.105-.547.755-.871 1.3-.648zm-.966 6.412l.567.913-2.363.015c-1.3.009-3.427.009-4.727 0l-2.364-.015.567-.913.568-.913H18.1l.567.913z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}

export default Icon;
