import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='28'
      height='28'
      fill='none'
      viewBox='0 0 28 28'
    >
      <path
        fill='#C7C7C7'
        fillRule='evenodd'
        d='M11.093 4.055c-2.252.33-4.201 1.264-5.807 2.783-3.723 3.523-4.362 9.244-1.509 13.52 1.61 2.414 4.057 4 6.987 4.53.591.108 2.213.149 2.867.073a10.719 10.719 0 003.015-.81c1.29-.57 2.24-.879 3.194-1.036.558-.092 2.007-.08 2.546.02a7.84 7.84 0 012.051.716c.678.344.828.37 1.15.198a.78.78 0 00.157-1.267c-.198-.186-1.279-.715-1.847-.905-.929-.31-2.117-.484-3.034-.443-.266.012-.484.012-.484 0 0-.013.113-.163.25-.335a10.641 10.641 0 002.221-5.165c.108-.747.108-2.247 0-2.937-.248-1.589-.795-3.03-1.631-4.295-1.63-2.467-4.063-4.058-7.03-4.6-.607-.11-2.469-.139-3.096-.047zm1.901 2.596c.422.11.756.298 1.058.596.884.87.904 2.243.048 3.163a2.18 2.18 0 01-1.624.696c-.444 0-.793-.095-1.15-.312a2.25 2.25 0 01-.426-3.547c.57-.562 1.363-.787 2.094-.596zm-4.992 3.874c1.555.554 2.007 2.562.84 3.735a2.227 2.227 0 01-3.299-.136c-.759-.895-.725-2.134.082-3.01.28-.304.776-.592 1.153-.67.316-.066.924-.026 1.224.081zm10.129-.082c.413.079.902.351 1.19.664.99 1.075.783 2.677-.453 3.485-.392.256-.778.348-1.325.315-.387-.023-.492-.05-.808-.202a2.24 2.24 0 01-1.071-1.075c-.163-.332-.184-.416-.201-.803-.022-.5.065-.917.26-1.252.52-.896 1.421-1.32 2.408-1.132zm-5.274 3.247c.662.303.662 1.309 0 1.611-.376.172-.839.065-1.074-.246-.57-.757.217-1.756 1.074-1.365zm-3.053 2.953c.434.113.676.254 1.012.586.359.355.537.677.636 1.15a2.38 2.38 0 01-.288 1.656c-.15.26-.585.656-.887.81-.874.446-1.937.28-2.608-.408-.721-.739-.868-1.773-.385-2.715.44-.857 1.55-1.333 2.52-1.08zm6.544.021c.56.168 1.084.594 1.333 1.084.255.501.331 1.161.194 1.68-.21.794-.927 1.472-1.712 1.619-.775.145-1.432-.048-2.018-.593-.704-.655-.893-1.71-.469-2.613.165-.35.668-.875.981-1.025a2.393 2.393 0 011.691-.151z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}

export default Icon;
