import {
  EVENTS_GET_LIST_EVENTS,
  EVENTS_INITIAL_STATE,
  EVENTS_ADD_EVENT_TO_LIST,
  EVENTS_PROPS_CHANGED,
  EVENTS_FILTER_EVENTS,
  EVENTS_DELETE_EVENT,
  EVENTS_DELETE_EVENT_LOADING,
} from '../actions/types';
import { EventsActionTypes, EventsState } from '../modules/Events/events.types';
import { EventsApiResponse } from '../apis/ApiTypes';

const INITIAL_STATE: EventsState = {
  eventsList: [],
  loading: true,
  eventsFilterList: [],
  startDate: null,
  endDate: null,
  allTime: false,
  //error
  startDateError: '',
  endDateError: '',
};

export default (state = INITIAL_STATE, action: EventsActionTypes) => {
  switch (action.type) {
    case EVENTS_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };
    case EVENTS_FILTER_EVENTS:
      if (action.allTime) {
        return {
          ...state,
          startDate: null,
          endDate: null,
          eventsList: action.payload,
        };
      }
      return {
        ...state,
        eventsList: action.payload,
      };
    case EVENTS_GET_LIST_EVENTS:
      return {
        ...state,
        eventsList: action.payload,
        eventsFilterList: action.payload,
        loading: false,
      };
    case EVENTS_INITIAL_STATE:
      return { ...INITIAL_STATE };
    case EVENTS_ADD_EVENT_TO_LIST:
      let eventsSortedByStartDate: Array<EventsApiResponse> = Object.assign(
        [],
        state.eventsList
      );

      eventsSortedByStartDate = [...state.eventsList, ...[action.payload]];

      return {
        ...state,
        eventsList: [
          ...eventsSortedByStartDate.sort(
            (a, b) => <any>new Date(a.createdAt) - <any>new Date(b.createdAt)
          ),
        ],
      };
    case EVENTS_DELETE_EVENT_LOADING: {
      return { ...state, loading: true };
    }
    case EVENTS_DELETE_EVENT:
      var newEventsList = state.eventsList.filter(function (item) {
        return item._id !== action.payload;
      });
      return { ...state, loading: false, eventsList: newEventsList };
    default:
      return state;
  }
};
