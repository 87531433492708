import { css } from 'styled-components';
import { opacify } from 'polished';

export const inputStyles = (props: any) => {
  let border = 'blue';
  let shadow = 'rgba(0, 0, 0, 0.4)';
  let margin = props.margin ? props.margin : '5px 0';
  let width = props.width ? `${props.width}px` : '100%';
  let background = props.background ? props.background : '#F8F6FE';
  if (props.theme) {
    border = props.theme.primary;
    shadow = opacify(0.8, props.theme.primary);
  }

  return css`
    height: 46px;
    min-height: 46px;
    width: ${width};
    color: #828282;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 100;
    background: ${background};
    border: 1px solid transparent;
    border-radius: 15px;
    padding: 0px 15px;
    margin: ${margin};
    outline: none;
    transition: all 0.2s ease-in-out;
    ::placeholder {
      color: #c0c0c0;
      font-size: 14px;
    }
    ::-moz-placeholder {
      color: #c0c0c0;
      font-size: 14px;
    }
    :focus {
      border: 1px solid ${border}4a;
      box-shadow: 0 0 3px 3px ${shadow}24;
      background: white;
    }
  `;
};
