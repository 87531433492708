import { updateWallet } from '../apis/walletApi';
import {
  WALLET_FORM_PROPS_CHANGED,
  WALLET_FORM_WALLET,
  WALLETS_UPDATE_WALLET_TO_LIST,
  // WALLET_FORM_CREATE_WALLET_FAILED,
  WALLET_FORM_INITIAL_STATE,
  WALLETS_SET_WALLET,
  AppThunk,
  SNACKBAR_SUCCESS,
  SNACKBAR_ERROR,
} from './types';
import {
  validateFieldsUpdateWallet,
  updateWalletObject,
} from '../components/walletForm/walletForm.utils';
import { handleDuplicateFieldsDB } from '../utils/validations';
import { currenciesApi, WalletApiResponse } from '../apis/ApiTypes';
import { URL_IMAGE } from '../apis/config';
import {
  WalletFormPropsChangedAction,
  WalletFormInitalStateAction,
} from '../components/walletForm/walletForm.types';
import { getCurrencies } from '../apis/currencyApi';
import { setInStorage } from '../utils/cache';

export const walletFormPropsChanged = (
  prop: string,
  value: any
): WalletFormPropsChangedAction => {
  return { type: WALLET_FORM_PROPS_CHANGED, prop, value };
};

export const walletFormInitalState = (): WalletFormInitalStateAction => {
  return { type: WALLET_FORM_INITIAL_STATE };
};

export const walletFormEditWallet = (
  props: any,
  callback: () => void
): AppThunk => (dispatch) => {
  const { newProps, valid } = validateFieldsUpdateWallet(props);
  dispatch({ type: WALLET_FORM_WALLET, newProps });
  const newWallet = updateWalletObject(newProps);

  if (valid && props.id && newWallet) {
    dispatch({ type: WALLET_FORM_PROPS_CHANGED, prop: 'loading', value: true });
    updateWallet(props.id, newWallet).then((res) => {
      dispatch({
        type: WALLET_FORM_PROPS_CHANGED,
        prop: 'loading',
        value: false,
      });
      if (res.data.response) {
        setInStorage('wallet', res.data.response.data);
      }
      if (res.data.status === 'success') {
        dispatch({
          type: WALLETS_UPDATE_WALLET_TO_LIST,
          payload: res.data.response.data,
        });
        dispatch({
          type: SNACKBAR_SUCCESS,
          value: 'Les paramètres sont sauvegardés avec succès',
        });
      } else if (res.data.status === 'error' && res.data.error.code === 11000) {
        dispatch({
          type: WALLET_FORM_PROPS_CHANGED,
          prop: 'identifierError',
          value: handleDuplicateFieldsDB(res.data.message),
        });
      } else {
        dispatch({
          type: SNACKBAR_ERROR,
          value: 'Une erreur est survenue',
        });
      }
    });
  }
};

export const setWallet = (wallet: WalletApiResponse): AppThunk => (
  dispatch
) => {
  const newWallet = {
    id: wallet._id,
    name: wallet.name,
    logo: `${URL_IMAGE}/${wallet.logoPictureUrl}`,
    icon: `${URL_IMAGE}/${wallet.iconPictureUrl}`,
    strip: `${URL_IMAGE}/${wallet.stripPictureUrl}`,
    labelColor: {
      hex: wallet?.labelColor?.hex,
      rgb: wallet?.labelColor?.rgb,
    },
    backgroundColor: {
      hex: wallet.backgroundColor.hex,
      rgb: wallet.backgroundColor.rgb,
    },
    additionalContacts:
      wallet.additionalContacts && wallet.additionalContacts.length !== 0
        ? wallet.additionalContacts
        : [{ title: '', kind: 'URL', value: '' }],
    locations: wallet.locations
      ? wallet.locations
      : [
          {
            longitude: 2.3125185928956062,
            latitude: 49.07575430649549,
            relevantText: '',
          },
        ],
    businessPhone: wallet.businessPhone,
    gainUnit: wallet.gainUnit,

    servicePhone: wallet.servicePhone,
    foregroundColor: {
      hex: wallet.foregroundColor.hex,
      rgb: wallet.foregroundColor.rgb,
    },
    address: wallet.address,
    website: wallet.website,
    facebook:
      wallet.socialMedia && wallet.socialMedia.facebook
        ? wallet.socialMedia.facebook
        : '',
    instagram:
      wallet.socialMedia && wallet.socialMedia.instagram
        ? wallet.socialMedia.instagram
        : '',
    linkedin:
      wallet.socialMedia && wallet.socialMedia.linkedin
        ? wallet.socialMedia.linkedin
        : '',
    openingHours: wallet.openingHours,
    descriptions:
      wallet.descriptions && wallet.descriptions.length !== 0
        ? wallet.descriptions
        : [
            {
              title: '',
              value: '',
            },
          ],
    button: 'Modifier',
    title: 'Modifier Wallet',
    login: wallet.login,
    communicationEmail: wallet.communicationEmail,
    email: wallet.email,
    //errors
    nameError: '',
    logoError: '',
    iconError: '',
    stripError: '',
    labelColorError: '',
    backgroundColorError: '',
    foregroundColorError: '',
    addressError: '',
    openingHoursError: '',
    facebookError: '',
    instagramError: '',
    linkedinError: '',
  };

  getCurrencies().then((res: currenciesApi) => {
    dispatch({
      type: WALLET_FORM_PROPS_CHANGED,
      prop: 'currencies',
      value: res.data,
    });

    dispatch({
      type: WALLETS_SET_WALLET,
      payload: newWallet,
    });
  });
};
