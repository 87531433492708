export default {
  //**************************************Input File****************************//
  //Buttons
  'inputFile.button.select': 'Bild auswählen',
  //**************************************Dialog****************************//
  //Buttons
  'dialog.button.agree': 'Ja',
  'dialog.button.desagree': 'Nein',
  //**************************************DRAWER****************************//
  //Menu List
  'drawer.menuList.wallet': 'Brieftasche',
  'drawer.menuList.offers': 'Angebot/Kommunikation',
  'drawer.menuList.coupons': 'Gutscheine',
  'drawer.menuList.event': 'Veranstaltung',
  'drawer.menuList.settings': 'Die Einstellungen',
  'drawer.menuList.stats': 'Statistiken',

  //Menu Item
  'drawer.menuItem.profile': 'Profil',
  'drawer.menuItem.logout': 'Ausloggen',
  //**************************************Login****************************//
  //Labels
  'login.label.forgotPassword': 'Passwort vergessen ?',
  //Placeholder
  'login.placeholder.username': 'Nutzername',
  'login.placeholder.password': 'Passwort',
  //Buttons
  'login.button.login': 'Anmeldung',
  //Error
  'login.error.fieldsReqiured': 'Benutzername und Passwort sind erforderlich',
  'login.error.incorrectUsernameOrPassword':
    'Benutzername oder Passwort ist falsch',
  'login.error.userNotAuthorized': 'Der Nutzer ist nicht berechtigt',
  //**************************************Settings****************************//
  //Settings Languages
  'settings.langauges.english': 'Englisch',
  'settings.langauges.french': 'Französisch',
  'settings.langauges.german': 'Deutsche',
  //Settings Labels
  'settings.label.settingsTitle': 'Die Einstellungen',
  'settings.label.registrationParameterTitle': 'Registrierungsparameter :',
  'settings.label.link': 'Verknüpfung:',
  'settings.label.QrCode': 'QR Code:',
  'settings.label.validationMessage': 'Registrierungsüberprüfungsnachricht:',
  'settings.label.emailMessage': 'Registrierungs-E-Mail-Nachricht:',
  'settings.label.offerEmailMessage':
    'Angebots- / Kommunikations-E-Mail-Nachricht:',
  'settings.label.couponEmailMessage': 'Gutschein-E-Mail-Nachricht:',
  'settings.label.changeLanguageTitle': 'Sprache ändern :',
  'settings.label.linkCopied': 'Link kopiert',
  'settings.label.subscriptionMessage': 'Abonnementsbericht',
  //Placeholder
  'settings.placeholder.validationMessage':
    'Registrierungsüberprüfungsnachricht',
  'settings.placeholder.emailMessage': 'Registrierungs-E-Mail-Nachricht',
  'settings.placeholder.offerEmailMessage':
    'Angebots- / Kommunikations-E-Mail-Nachricht',
  'settings.placeholder.couponEmailMessage': 'Gutschein-E-Mail-Nachricht',
  'settings.placeholder.subscriptionMessage': 'uw aanmeldingsbericht hier',
  //Settings Buttons
  'settings.button.save': 'Sparen',
  'settings.button.cancel': 'Stornieren',
  'settings.button.copyLink': 'Link kopieren',
  'settings.button.verifyEmail': 'Verifieer Email',

  //**************************************WALLET****************************//
  //Labels
  'wallet.label.searchCustomer': 'Finden Sie einen Kunden',
  'wallet.label.filter': 'Filtern nach',
  'wallet.label.customersList': 'Liste der Kunden',
  'wallet.label.customersEmptyList': 'Kundenliste ist leer',
  //Select
  'wallet.select.firstName': 'Vorname',
  'wallet.select.lastName': 'Nachname',
  'wallet.select.email': 'Email',
  //Placeholder
  'wallet.placeholder.searchCustomer': 'Suche',
  //Buttons
  'wallet.add': 'Kunden hinzufügen',
  //**************************************Customers Table****************************//
  //Labels
  'customersTable.Barcode': 'Barcode',
  'customersTable.id': 'ID',
  'customersTable.firstName': 'Vorname',
  'customersTable.lastName': 'Nachname',
  'customersTable.createdAt': 'Hergestellt in',
  'customersTable.gains': 'Gewinne',
  'customersTable.email': 'Email',
  'customersTable.phoneNumber': 'Telefonnummer',
  'customersTable.source': 'Quelle',
  'customersTable.source.internal': 'Intern',
  'customersTable.source.external': 'Extern',
  'customersTable.source.crm': 'CRM',

  'customersTable.status': 'Status',
  'customersTable.suprimer': 'Gelöscht',
  'customersTable.system': 'System',
  'customerTable.isDeleted': 'Ja',
  'customerTable.isNotDeleted': 'Nein',
  //Buttons
  'customersTable.updateGain': 'Gewinn aktualisieren',
  //Historical
  'customersTable.historical.title': 'historisch',
  'customersTable.historical.emptyList': 'Die Verlaufsliste ist leer',
  'customersTable.historical.date': 'das Datum',
  'customersTable.historical.gain': 'der Gewinn',
  //**************************************Update Gain****************************//
  //Labels
  'updateGain.label.gain': 'Gewinn aktualisieren :',
  //Placeholder
  'updateGain.placehoder.gain': 'Gewinn',
  //Buttons
  'updateGain.button.confirm': 'aktualisieren',
  //Error
  'updateGain.error.gainNotNumber': 'Der Gewinn muss eine Zahl sein',
  //**************************************Add Customer****************************//
  //Labels
  'addCustomer.label.firstName': 'Vorname',
  'addCustomer.label.lastName': 'Nachname',
  'addCustomer.label.email': 'Email',
  'addCustomer.label.barcode': 'Barcode',
  'addCustomer.label.phoneNumber': 'Telefonnummer',
  //Placeholder
  'addCustomer.placeholder.firstName': 'Vorname',
  'addCustomer.placeholder.lastName': 'Nachname',
  'addCustomer.placeholder.email': 'Email',
  'addCustomer.placeholder.barcode': 'Barcode',
  'addCustomer.placeholder.phoneNumber': '+33757130710',
  //Buttons
  'addCustomer.button.add': 'Hinzufügen',
  //Error
  'addCustomer.error.firstNameRequired': 'Vorname ist erforderlich',
  'addCustomer.error.lastNameRequired': 'Nachname ist erforderlich',
  'addCustomer.error.emailRequired': 'E-Mail ist erforderlich',
  'addCustomer.error.emailInvalid': 'ungültige E-Mail',
  'addCustomer.error.emailExist': 'E-Mail oder Barcode sind bereits vorhanden',
  'addCustomer.error.phoneNumberInvalid': 'Ungültige Telefonnummer',
  'addCustomer.error.barcodeInvalid':
    'der Strichcode muss mindestens 8 Zeichen lang sein',
  //**************************************Offers List****************************//
  //Labels
  'offers.label.title': 'Liste der Angebote',
  'offers.label.emptyList': 'Die Liste der Angebote ist leer',
  'offers.label.filter': 'Filtern nach',
  'offers.label.startDate': 'Anfangsdatum',
  'offers.label.endDate': 'Endtermin',
  'offers.label.allTime': 'Alle Zeit',
  //Select
  'offers.select.all': 'ALLES',
  'offers.select.opened': 'GEÖFFNET',
  'offers.select.actif': 'AKTIV',
  'offers.select.inactif': 'INAKTIV',
  'offers.select.expired': 'ABGELAUFEN',
  //Dialog
  'offers.dialog.title': 'Information',
  'offers.dialog.text': 'Möchten Sie dieses Angebot wirklich deaktivieren ?',
  //Buttons
  'offers.button.search': 'Suche',
  'offers.button.create': 'Neues Angebot',
  //**************************************Offer Card****************************//
  //Labels
  'offerCard.label.status': 'Status',
  'offerCard.label.startDate': 'Anfangsdatum',
  'offerCard.label.endDate': 'Endtermin',
  'offerCard.label.description': 'Beschreibung',
  //Buttons
  'offerCard.button.desactivate': 'Deaktivieren',
  'offerCard.button.delete': 'Löschen',
  //**************************************Statistics****************************//
  //Labels
  'statsSMS.label.title': 'SMS-Bericht senden',
  'statsSMS.label.newAccount': 'Neues Konto',
  'statsSMS.label.offers': 'bietet an',
  'statsSMS.label.coupons': 'Gutscheine',
  'statsSMS.label.events': 'Veranstaltungen',
  //**************************************Offer****************************//
  //Labels
  'offer.label.title': 'Erstellen Sie ein Angebot',
  'offer.label.image': 'Bild zum Einfügen:',
  'offer.label.description': 'Beschreibung:',
  'offer.label.startDate': 'Anfangsdatum',
  'offer.label.endDate': 'Endtermin',
  //Placeholder
  'offer.placeholder.description': 'Beschreibung ...',
  //Buttons
  'offer.button.add': 'Hinzufügen',
  //Error
  'offer.error.imageRequired': 'Bild ist erforderlich',
  'offer.error.startDateRequired': 'Startdatum ist erforderlich',
  'offer.error.startDateInvalid': 'Ungültiges Startdatum',
  'offer.error.endDateRequired': 'Enddatum ist erforderlich',
  'offer.error.endDateInvalid': 'Ungültiges Enddatum',
  'offer.error.endDateBeforeStartDate': 'Das Enddatum liegt vor dem Startdatum',
  'offer.error.descriptionRequired': 'Beschreibung ist erforderlich',
  'offer.error.datesUnavailable': 'Termine sind nicht verfügbar',
  //**************************************Coupons List****************************//
  //Labels
  'coupons.label.title': 'Liste der Gutscheine',
  'coupons.label.emptyList': 'Die Liste der Gutscheine ist leer',
  //Dialog
  'coupons.dialog.title': 'Information',
  'coupons.dialog.text': 'Möchten Sie diesen Gutschein an alle Kunden senden ?',
  //Buttons
  'coupons.button.create': 'Neuer Gutschein',
  //**************************************Coupon Card****************************//
  //Labels
  'couponCard.label.expirationDate': 'Haltbarkeitsdatum:',
  'couponCard.label.percent': 'Prozentuale Reduzierung:',
  'couponCard.label.promoCode': 'Werbe-Code:',
  'couponCard.label.description': 'Beschreibung:',
  'couponCard.label.status': 'Status',
  'couponCard.label.source': 'Quelle',
  //Buttons
  'couponCard.button.send': 'senden',
  'couponCard.switch.activate': 'aktivieren Sie',
  //**************************************Coupon****************************//
  //Labels
  'coupon.label.expirationDate': 'Haltbarkeitsdatum',
  'coupon.label.textColor': 'Textfarbe:',
  'coupon.label.backgroundColor': 'Hintergrundfarbe:',
  'coupon.label.foregroundColor': 'FVordergrundfarbe:',
  'coupon.label.image': 'Bild zum Einfügen:',
  'coupon.label.percent': 'Percentage of waarde:',
  'coupon.label.description': 'Beschreibung:',
  'coupon.label.promoCode': 'Werbe-Code',
  'coupon.label.All': 'ALLE',
  'coupon.label.Internal': 'INTERN',
  'coupon.label.External': 'EXTERN',
  'coupon.label.crm': 'CRM',

  'coupon.label.Inactif': 'INAKTIV',
  'coupon.label.Actif': 'AKTIV',
  'coupon.label.Expire': 'ABGELAUFEN',
  //Placeholder
  'coupon.placeholder.description': 'Beschreibung ...',
  //Buttons
  'coupon.button.add': 'Hinzufügen',
  //Error
  'coupon.error.expirationDateRequired': 'Ablaufdatum ist erforderlich',
  'coupon.error.expirationDateInvalid': 'Ungültiges Ablaufdatum',
  'coupon.error.labelColorRequired': 'Textfarbe ist erforderlich',
  'coupon.error.backgroundColorRequired': 'Hintergrundfarbe ist erforderlich',
  'coupon.error.foregroundColorRequired': 'Vordergrundfarbe ist erforderlich',
  'coupon.error.imageRequired': 'Bild ist erforderlich',
  'coupon.error.percentReductionRequired':
    'Eine prozentuale Reduzierung ist erforderlich',
  'coupon.error.descriptionRequired': 'Beschreibung ist erforderlich',
  'coupon.error.promoCode': 'Promo-Code ist erforderlich',

  //**************************************Events List****************************//
  //Labels
  'events.label.title': 'Ereignisliste',
  'events.button.create': 'neues Event',
  'event.label.name': 'Nachname',
  'event.label.openingDate': 'Eröffnungsdatum',
  'event.label.startingDate': 'Anfangsdatum',
  'event.label.closingDate': 'Einsendeschluss',
  'event.label.eventLocation': 'Ort der Veranstaltung',
  'event.label.title': 'Erstellen Sie ein Ereignis',
  'event.label.image': 'Bild zum Einfügen',
  'event.label.openingHour': 'Startdatum und -zeit der Veranstaltung',
  'event.label.closingHour': 'Enddatum und Uhrzeit der Veranstaltung',
  'event.label.doorOpeningHour': 'Datum und Uhrzeit der Türöffnung',
  'event.label.price': 'Veranstaltungspreis',
  'event.label.locationName': 'Standortnamen',
  'event.label.address': 'Standort-Adresse',
  'event.label.contactPhone': 'Kontakttelefon',
  'event.label.email': 'Kommunikations-E-Mail',
  'event.label.website': 'Webseite',
  'event.label.terms': 'Bedingungen',
  'event.label.labelColor': 'Textfarbe',
  'EventsCustomersTable.Id': 'ID (Ticket)',
  'EventsCustomersTable.firstName': 'Vorname',
  'EventsCustomersTable.lastName': 'Nachname',
  'EventsCustomersTable.email': 'Email',
  'EventsCustomersTable.enregistre': 'Eingecheckt',
  'EventsCustomersTable.systeme': 'System',
  'EventsCustomersTable.enregistre.true': 'Ja',
  'EventsCustomersTable.enregistre.false': 'Nein',
  'event.label.section.style': 'Ereignisstil',
  'event.label.section.timeLocation': 'Zeit und Ort der Veranstaltung',
  'event.label.selectLocation': 'Wählen Sie den genauen Ort',
  'event.label.section.contact': 'Kontaktinformation',
  'event.label.subMessage': 'Abonnementnachricht',
  'event.label.section.emailMessage': 'E-Mail Nachricht',
  'event.label.emailMessage': 'E-Mail Nachricht',
  'event.label.create': 'Erstellen',
  'event.label.section.info': 'Informationen zur Veranstaltung',
  'email.label.emailMessage': 'Ereignismeldung',
  'mapComponent.label.search': 'Karte suchen',

  //Errors
  'event.error.nameRequired': 'Name der Veranstaltung ist erforderlich',
  'event.error.priceRequired': 'Der Veranstaltungspreis ist obligatorisch',
  'event.error.locationNameRequired': 'Standortname ist erforderlich',
  'event.error.addressRequired': 'Ereignisadresse ist erforderlich',
  'event.error.contactPhoneRequired': 'Kontakttelefon ist erforderlich',
  'event.error.emailRequired': 'Kommunikations-E-Mail ist erforderlich',
  'event.error.websiteError': 'Website ist erforderlich',
  'event.error.termsRequired': 'Bedingungen sind erforderlich',

  'event.error.imageRequiredRequired': 'Streifenbild ist erforderlich',
  'event.error.subscriptionMessageRequired':
    'Abonnementnachricht ist erforderlich',

  //**************************************Profile****************************//
  //Labels
  'profile.label.emailSettings': 'E-mail parameter :',
  'profile.label.managerEmail': 'Belangrijkste e-mailadres :',
  'profile.label.communicationEmail': 'Communicatie E-mail :',
  'profile.label.communicationEmailHost': 'Host-Server :',
  'profile.label.communicationEmailPort': 'Poortnummer:',
  'profile.label.communicationEmailUser': 'Gebruikersnaam :',
  'profile.label.communicationEmailPass': 'Passwort :',
  'profile.label.emailSettingsConfirmation': 'Voer uw wachtwoord in:',

  'profile.label.updatePasswordTitle': 'Ändere das Passwort :',
  'profile.label.oldPassword': 'Altes Passwort',
  'profile.label.newPassword': 'Neues Kennwort',
  'profile.label.confirmPassword': 'Bestätige das Passwort',
  //Placeholder
  'profile.placeholder.managerEmail': 'Jouw email ...',
  'profile.placeholder.communicationEmailHost': 'xx.xxx.xx ',
  'profile.placeholder.communicationEmailPort': '0000',
  'profile.placeholder.communicationEmailUser': 'username ....',
  'profile.placeholder.communicationEmailPass': 'Passwort ...',
  'profile.placeholder.oldPassword': 'Altes Passwort ...',
  'profile.placeholder.newPassword': 'Neues Kennwort ...',
  'profile.placeholder.confirmPassword': 'Bestätige das Passwort ...',
  //Buttons
  'profile.button.confirmPassword': 'Confirm Password',
  'profile.button.updateEmailSettings': 'Update e-mailinstellingen',
  'profile.button.updatePassword': 'Kennwort aktualisieren',
  //Success
  'profile.success.successMessage': 'Met succes bijgewerkt',
  //Error
  'profile.error.oldPasswordRequired': 'Altes Passwort ist erforderlich.',
  'profile.error.oldPasswordIncorrect': 'Ihr aktuelles Passwort ist falsch.',
  'profile.error.newPasswordRequired': 'Neues Passwort ist erforderlich.',
  'profile.error.newPasswordRequiredLength':
    'Das Passwort muss mindestens 4 Zeichen enthalten.',
  'profile.error.confirmPasswordRequired':
    'Bestätigen Sie, dass das Passwort erforderlich ist.',
  'profile.error.confirmPasswordNotMatch':
    'Die Bestätigung stimmt nicht mit dem neuen Passwort überein.',
  'profile.error.emailSettingsError':
    'Konfigurationsfehler, überprüfen Sie bitte die eingegebenen Parameter.',

  'profile.error.emailCommunication':
    'ungültige Kommunikations-E-Mail-Parameter',
  'profile.error.managerEmail': 'Principal-e-mailadres is vereist',
  'profile.error.userPasswordError': 'Incorrect password',
  'profile.error.userPrincipalEamilExistsError':
    'Versuchen Sie es mit einer bereits vorhandenen E-Mail-Adresse',
  //**************************************Forgot Password****************************//
  //Labels
  'forgotPassword.label.email': 'Geben Sie Ihren Benutzernamen.',
  //Placeholder
  'forgotPassword.placeholder.email': 'Benutzername',
  //Buttons
  'forgotPassword.button.confirm': 'Bestätigen',
  //Error
  'forgotPassword.error.emailRequired': 'Benutzername ist erforderlich',
  'forgotPassword.error.invalidEmail': 'Ungültige Benutzername',
  //**************************************Reset Password****************************//
  //Labels
  'resetPassword.label.password': 'Neues Kennwort:',
  'resetPassword.label.passwordConfirm': 'Bestätigen Sie das Passwort:',
  //Placeholder
  'resetPassword.placeholder.password': 'Gib dein neues Passwort ein',
  'resetPassword.placeholder.passwordConfirm': 'Bestätigen Sie Ihr Passwort',
  //Buttons
  'resetPassword.button.confirm': 'Bestätigen',
  //Error
  'resetPassword.error.passwordRequired': 'Neues Passwort ist erforderlich.',
  'resetPassword.error.passwordRequiredLength':
    'Das Passwort muss mindestens 4 Zeichen enthalten.',
  'resetPassword.error.passwordConfirmRequired':
    'Bestätigen Sie, dass das Passwort erforderlich ist.',
  'resetPassword.error.passwordConfirmNotMatch':
    'Die Bestätigung stimmt nicht mit dem neuen Passwort überein.',
  'resetPassword.error.tokenExpired':
    'Entschuldigung, Ihr Token ist abgelaufen! Sie müssen Ihre E-Mail erneut senden.',
};
