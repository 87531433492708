import React from 'react';
import { Wrapper } from './eventWireframe.style';

import { ic_close } from 'react-icons-kit/md/ic_close';
import { ic_call } from 'react-icons-kit/md/ic_call';
import { ic_email } from 'react-icons-kit/md/ic_email';
import { ic_location_on } from 'react-icons-kit/md/ic_location_on';

import { ratio } from 'wcag-color';
import barcode from '../../assets/images/barcode.png';

import cover from '../../assets/images/lg-thumbnail.png';

import { ic_more_vert } from 'react-icons-kit/md/ic_more_vert';
import Icon from 'react-icons-kit';

import moment from 'moment';

import { getDataFromCache } from '../../utils/cache';
import { URL_IMAGE } from '../../apis/config';
import QRCode from 'qrcode.react';

type WireframeProps = {
  backgroundColor: any;
  strip: any;
  price: any;
  endDate: any;
  startDate: any;
  openDate: any;
  terms: any;
  name: any;
  locationName: any;
  subscriptionMessage: any;
  QrCode: any;
};

const EventWireframeAndroid: React.FC<WireframeProps> = (props) => {
  const {
    backgroundColor,
    strip,
    locationName,
    subscriptionMessage,
    name,
    price,
    endDate,
    startDate,
    openDate,
    terms,
    QrCode,
  } = props;
  const currentWallet = getDataFromCache('wallet');

  return (
    <Wrapper
      backgroundColor={backgroundColor}
      mainContentTextColor={
        ratio('#ffffff', backgroundColor.hex) > 3.0 ? '#ffffff' : '#000000'
      }
    >
      <div className='mainSection'>
        <div className='mainSection-header'>
          <Icon
            size={18}
            icon={ic_close}
            style={
              ratio('#ffffff', backgroundColor.hex) > 3.0
                ? { color: '#ffffff' }
                : { color: '#000000' }
            }
          />
          <Icon
            size={18}
            icon={ic_more_vert}
            style={
              ratio('#ffffff', backgroundColor.hex) > 3.0
                ? { color: '#ffffff' }
                : { color: '#000000' }
            }
          />
        </div>
        <div className='mainSection-header-logo'>
          <img
            src={`${URL_IMAGE}/${currentWallet.logoPictureUrl}`}
            alt='wallet Logo'
          />
        </div>
        <div className='mainSection-content-enseigne'>
          <div className='mainSection-issuerName'>Astrolab Agency</div>
          <div className='mainSection-eventName'>
            {name || "Nom de l'évènement"}
          </div>
          <div className='mainSection-location'>
            {locationName || "Nom d'emplacement"}
          </div>
        </div>
        <div className='mainSection-content-datePoints'>
          <div className='date'>
            <h3 className='mainSection-label'>Date</h3>
            <h3 className='mainSection-data'>
              {moment(openDate).format('MMM DD, YYYY') || ''}
            </h3>
          </div>
          <div className='points'>
            <h3 className='mainSection-label'>Time</h3>
            <h3 className='mainSection-data'>
              {moment(openDate).format('h:mm a') || ''}
            </h3>
          </div>
        </div>
        <div className='mainSection-content-banner'>
          <img
            src={
              !strip
                ? cover
                : typeof strip === 'string'
                ? strip
                : URL.createObjectURL(strip)
            }
            alt='banner'
          />
        </div>
        <div className='mainSection-content-datePoints'>
          <div className='date'>
            <h3 className='mainSection-label'>Porte</h3>
            <h3 className='mainSection-data'>porte 11</h3>
          </div>
          <div className='points'>
            <h3 className='mainSection-label'>rangee</h3>
            <h3 className='mainSection-data'>rangee 11</h3>
          </div>
          <div className='points'>
            <h3 className='mainSection-label'>chaise</h3>
            <h3 className='mainSection-data'>chaise 11</h3>
          </div>
        </div>
        <div className='mainSection-content-barCode'>
          {QrCode.state ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '270px',
              }}
            >
              <QRCode includeMargin value={QrCode.value} />
            </div>
          ) : (
            <>
              <div className='barCode'>
                <img src={barcode} alt='barcode' />
              </div>
              <p className='walletId'>6066e4315abbcc1f9526d392</p>
            </>
          )}
        </div>
      </div>
      <div className='dataSection'>
        <span className='divider'></span>
        <div className='sectionLabel'>
          <h3 className='label'>Ticket holder</h3>
          <h3 className='data'>example@someMail.com</h3>
        </div>
        <span className='divider'></span>
        <div className='sectionLabel'>
          <h3 className='label'>{name || 'event name'}</h3>
          <h3 className='data'>{locationName || 'location name'}</h3>
        </div>
        <span className='divider'></span>
        <div className='sectionLabel'>
          <h3 className='label'>Doors open</h3>
          <h3 className='data'>
            {moment(openDate).format('MMM DD, YYYY, h:mm a') || ''}
          </h3>
        </div>
        <span className='divider'></span>
        <div className='sectionLabel'>
          <h3 className='label'>Event start time</h3>
          <h3 className='data'>
            {moment(startDate).format('MMM DD, YYYY, h:mm a') || ''}
          </h3>
        </div>
        <span className='divider'></span>
        <div className='sectionLabel'>
          <h3 className='label'>Event end time</h3>
          <h3 className='data'>
            {moment(endDate).format('MMM DD, YYYY, h:mm a') || ''}
          </h3>
        </div>
        <span className='divider'></span>
        <div className='sectionLabel'>
          <h3 className='label'>Ticket number</h3>
          <h3 className='data'>6066e4315abbcc1f9526d392</h3>
        </div>
        <span className='divider'></span>
        <div className='sectionLabel'>
          <h3 className='label'>valeur</h3>
          <h3 className='data'>{price || ''}</h3>
        </div>
        <span className='divider'></span>
        <div className='sectionLabel'>
          <h3 className='label'>Terms & conditions</h3>
          <h3 className='data'>{terms || ''}</h3>
        </div>
        <div className='sectionIcon'>
          <Icon icon={ic_location_on} size={18} style={{ color: '#999999' }} />
          <h3 className='data'>Google Map</h3>
        </div>
        <div className='sectionIcon'>
          <Icon icon={ic_call} size={18} style={{ color: '#999999' }} />
          <h3 className='data'>Info Line</h3>
        </div>
        <div className='sectionIcon'>
          <Icon icon={ic_email} size={18} style={{ color: '#999999' }} />
          <h3 className='data'>Email</h3>
        </div>
      </div>
    </Wrapper>
  );
};

export default EventWireframeAndroid;
