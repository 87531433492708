import {
  WALLET_FORM_PROPS_CHANGED,
  WALLET_FORM_WALLET,
  WALLETS_SET_WALLET,
  WALLET_FORM_INITIAL_STATE,
} from '../actions/types';
import {
  WalletFormActionTypes,
  WalletState,
} from '../components/walletForm/walletForm.types';

const INITIAL_STATE: WalletState = {
  id: null,
  name: '',
  logo: null,
  icon: null,
  strip: null,
  businessPhone: '',
  servicePhone: '',
  locations: [
    {
      name: null,
      longitude: 2.3125185928956062,
      latitude: 49.07575430649549,
      relevantText: '',
    },
  ],
  labelColor: {
    hex: '#000000',
    rgb: { r: 0, g: 0, b: 0, a: 1 },
  },
  backgroundColor: {
    hex: '#ffffff',
    rgb: { r: 255, g: 255, b: 255, a: 1 },
  },
  foregroundColor: {
    hex: '#000000',
    rgb: { r: 0, g: 0, b: 0, a: 1 },
  },
  address: '',
  facebook: '',
  instagram: '',
  linkedin: '',
  website: '',
  openingHours: '',
  descriptions: [
    {
      title: '',
      value: '',
    },
  ],
  additionalContacts: [
    {
      title: '',
      kind: 'URL',
      value: '',
    },
  ],
  username: '',
  email: '',
  login: '',
  gainUnit: '',
  communicationEmail: '',
  password: '',
  confirmPassword: '',
  //errors
  nameError: '',
  logoError: '',
  iconError: '',
  stripError: '',
  labelColorError: '',
  backgroundColorError: '',
  foregroundColorError: '',
  addressError: '',
  openingHoursError: '',
  businessPhoneError: '',
  facebookError: '',
  instagramError: '',
  linkedinError: '',
  websiteError: '',
  usernameError: '',
  emailError: '',
  passwordError: '',
  confirmPasswordError: '',
  descriptionError: '',
  additionalContactError: '',
  currencies: [],
  //Label
  title: 'Nouveau Wallet',
  //Button
  button: 'Créer',

  //Loading
  loading: false,
};

export default (state = INITIAL_STATE, action: WalletFormActionTypes) => {
  switch (action.type) {
    case WALLET_FORM_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };
    case WALLETS_SET_WALLET:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case WALLET_FORM_WALLET:
      return { ...state, ...action.newProps, loading: false };

    case WALLET_FORM_INITIAL_STATE:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};
