import { authentication } from '../apis/loginApi';
import {
  LOGIN_FORM_PROPS_CHANGED,
  LOGINING_SUCCESS,
  LOGINING_FAILED,
  AppThunk,
  LOGINING_INITIAL_STATE,
} from './types';

import { LoginPropsChangedAction } from '../modules/Login/login.types';
import { LoginApiResponse } from '../apis/ApiTypes';
import { setInStorage } from '../utils/cache';

export const loginPropsChanged = (
  prop: string,
  value: string
): LoginPropsChangedAction => {
  return { type: LOGIN_FORM_PROPS_CHANGED, prop, value };
};

export const onLogin = (
  username: string,
  password: string,
  callback: (value: string) => void
): AppThunk => (dispatch) => {
  dispatch({
    type: LOGIN_FORM_PROPS_CHANGED,
    prop: 'loading',
    value: true,
  });
  authentication(username, password).then((res: any) => {
    if (res.data && res.status === 401) {
      dispatch({
        type: LOGINING_FAILED,
        payload: 'login.error.incorrectUsernameOrPassword',
      });
    } else if (res.data && res.status === 400) {
      dispatch({
        type: LOGINING_FAILED,
        payload: 'login.error.fieldsReqiured',
      });
    } else if (res.data && res.data.user && res.data.user.role === 'admin') {
      dispatch({
        type: LOGINING_FAILED,
        payload: 'login.error.userNotAuthorized',
      });
    } else {
      setInStorage('token', res.token);
      setInStorage('user', res.data.user);
      setInStorage('wallet', res.data.wallet);

      dispatch({ type: LOGINING_SUCCESS, payload: res.data });
      callback(res.data.user.wallet);
      dispatch({
        type: LOGINING_INITIAL_STATE,
      });
    }
  });
};
