import {
  getCouponsList,
  sendCoupon,
  activateCoupon,
  removeCoupons,
  getCouponsChildrenList,
  removeCustomerCoupon,
} from '../apis/couponsApi';
import {
  COUPONS_GET_LIST_COUPONS,
  COUPONS_INITIAL_STATE,
  COUPONS_DELETE_COUPON_LOADING,
  COUPONS_DELETE_COUPON,
  AppThunk,
  COUPONS_UPDATE_COUPON_STATUS,
  SNACKBAR_SUCCESS,
  SNACKBAR_INFO,
  SNACKBAR_ERROR,
  COUPONS_UPDATE_PROPS,
  WALLET_FORM_PROPS_CHANGED,
  COUPONS_GET_SENT_COUPONS,
  COUPONS_PROPS_CHANGED,
} from './types';
import { CouponApiResponse, currenciesApi } from '../apis/ApiTypes';
import {
  CouponsPropsChangedAction,
  CouponsInitialStateAction,
} from '../modules/Coupons/coupons.types';
import { getCurrencies } from '../apis/currencyApi';

export const couponsGetAllCoupons = (walletId: string): AppThunk => (
  dispatch
) => {
  getCouponsList(walletId).then((res: Array<CouponApiResponse>) => {
    dispatch({
      type: COUPONS_GET_LIST_COUPONS,
      payload: res,
    });
  });
  getCurrencies().then((res: currenciesApi) => {
    dispatch({
      type: WALLET_FORM_PROPS_CHANGED,
      prop: 'currencies',
      value: res.data,
    });
  });
};
export const couponsGetChildrens = (
  walletId: string,
  couponId: string,
  page: number | string,
  size: number | string,
  searchFilter: any
): AppThunk => (dispatch) => {
  getCouponsChildrenList(walletId, couponId, page, size, searchFilter).then(
    (res: any) => {
      if (res.status === 500) {
        dispatch({
          type: SNACKBAR_ERROR,
          value: 'Erruer est survenue',
        });
      } else
        dispatch({
          type: COUPONS_GET_SENT_COUPONS,
          payload: res.data,
          total: res.total,
        });
    }
  );
  getCurrencies().then((res: currenciesApi) => {
    dispatch({
      type: WALLET_FORM_PROPS_CHANGED,
      prop: 'currencies',
      value: res.data,
    });
  });
};

export const couponsSendCoupon = (
  walletId: string,
  couponId: string
): AppThunk => (dispatch) => {
  dispatch({
    type: COUPONS_UPDATE_PROPS,
    prop: 'loadingSend',
    value: true,
  });
  sendCoupon(walletId, couponId).then((res: any) => {
    if (res.status === 200) {
      const sentSMS = res.data.response.data.sentSMS;
      const sentEmails = res.data.response.data.sentEmail;
      dispatch({
        type: COUPONS_UPDATE_PROPS,
        prop: 'loadingSend',
        value: false,
      });
      dispatch({
        type: SNACKBAR_SUCCESS,
        value: `Les coupons ont été envoyés avec succès.`,
      }); /*
      if (sentSMS === 0 && sentEmails === 0) {
        dispatch({
          type: SNACKBAR_INFO,
          value: `Aucun coupon n'a été envoyé`,
        });
      } else {
        dispatch({
          type: SNACKBAR_SUCCESS,
          value: `Les coupons ont été envoyés avec succès. SMSs: ${sentSMS} Emails: ${sentEmails}`,
        });
      }*/
    } else {
      dispatch({
        type: SNACKBAR_ERROR,
        value: 'Erruer est survenue',
      });
    }
    dispatch({
      type: COUPONS_UPDATE_PROPS,
      prop: 'loadingSend',
      value: false,
    });
  });
};

export const couponsUpdateCouponStatus = (
  walletId: string,
  couponId: string,
  status: string
): AppThunk => (dispatch) => {
  activateCoupon(walletId, couponId, status).then((res: any) => {
    if (res.status === 200) {
      dispatch({
        type: COUPONS_UPDATE_COUPON_STATUS,
        id: couponId,
        status,
      });
    } else if (res.status === 400) {
      dispatch({
        type: SNACKBAR_INFO,
        value: 'Il y a déjà un coupon actif',
      });
    } else {
      dispatch({
        type: SNACKBAR_ERROR,
        value: 'Une erreur est survenue',
      });
    }
  });
};
export const couponsPropsChanged = (prop: string, value: any): any => {
  return { type: COUPONS_PROPS_CHANGED, prop, value };
};
// export const couponsPropsChanged = (
//   prop: string,
//   value: any
// ): CouponsPropsChangedAction => {
//   return { type: COUPONS_PROPS_CHANGED, prop, value };
// };

export const deleteCoupon = (couponId: string, walletId: string): AppThunk => (
  dispatch
) => {
  dispatch({
    type: COUPONS_DELETE_COUPON_LOADING,
    payload: true,
  });
  removeCoupons(couponId, walletId).then((res) => {
    if (res.data.status === 'success') {
      dispatch({
        type: COUPONS_DELETE_COUPON,
        payload: couponId,
      });

      dispatch({
        type: SNACKBAR_SUCCESS,
        value: 'Le coupon a été supprimé avec succès ',
      });
    } else {
      dispatch({
        type: SNACKBAR_ERROR,
        value: 'Une erreur est survenue ',
      });
    }
  });
};

export const deleteCustomerCoupon = (
  couponId: string,
  customerId: string,
  walletId: string
): AppThunk => (dispatch) => {
  dispatch({
    type: COUPONS_DELETE_COUPON_LOADING,
    payload: true,
  });
  removeCustomerCoupon(couponId, customerId, walletId).then((res) => {
    if (res.data.status === 'success') {
      dispatch({
        type: COUPONS_DELETE_COUPON,
        payload: couponId,
      });

      dispatch({
        type: SNACKBAR_SUCCESS,
        value: 'Le coupon a été supprimé avec succès ',
      });
    } else {
      dispatch({
        type: SNACKBAR_ERROR,
        value: 'Une erreur est survenue ',
      });
    }
  });
};
export const couponsInitialState = (): CouponsInitialStateAction => {
  return { type: COUPONS_INITIAL_STATE };
};
