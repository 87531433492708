import {
  SETTINGS_PROPS_CHANGED,
  SETTINGS_GET_WALLET_SETTINGS,
  SETTINGS_GET_WALLET_DETAIL,
  SETTINGS_INITIAL_STATE,
  SETTINGS_GET_WALLET_FORM,
} from '../actions/types';
import {
  SettingsState,
  SettingsActionTypes,
} from '../modules/Settings/settings.types';

const INITIAL_STATE: SettingsState = {
  wallet: null,
  walletDetails: null,
  successMessage: '',
  emailMessage: '',
  subscriptionMessage: '',
  newOfferEmailMessage: '',
  newOfferNotificationMessage: '',
  newCouponEmailMessage: '',
  newCouponNotificationMessage: '',
  expiredOfferEmailMessage: '',
  expiredOfferNotificationMessage: '',
  newUpdateEmailMessage: '',
  newUpdateNotificationMessage: '',
  enableNewUpdateEmailMessage: false,
  enableOfferEmailMessage: false,
  enableOfferSMSMessage: false,
  showBarcode: true,
  hasSMSAccount: false,
  smsCredits: -1,
  enableCouponEmailMessage: false,
  communicationChannels: '',
  walletForm: {},
  smsTitle: '',
  smsTitleError: '',
  newAccountSMSMessage: '',
  newEventSMSMessage: '',
  newOfferSMSMessage: '',
  expiredOfferSMSMessage: '',
  newCouponSMSMessage: '',
  newUpdateSMSMessage: '',
  newAccountSMSMessageError: '',
  newCouponSMSMessageError: '',
  newUpdateNotificationMessageError: '',
  newOfferSMSMessageError: '',
  newCouponEmailMessageError: '',
  newOfferNotificationMessageError: '',
  newCouponNotificationMessageError: '',
  expiredOfferNotificationMessageError: '',
  loadingSaveCommunicationChannelsError: false,
  //Loading
  loading: false,
  loadingSaveCommunicationChannels: false,
};

export default (state = INITIAL_STATE, action: SettingsActionTypes) => {
  switch (action.type) {
    case SETTINGS_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };

    case SETTINGS_GET_WALLET_SETTINGS:
      return {
        ...state,
        ...action.payload,
      };

    case SETTINGS_GET_WALLET_DETAIL:
      return {
        ...state,
        wallet: action.payload,
      };

    case SETTINGS_GET_WALLET_FORM:
      return {
        ...state,
        walletForm: action.payload,
      };

    case SETTINGS_INITIAL_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};
