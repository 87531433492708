import {
  createCoupon,
  editCoupon,
  editCustomerCoupon,
} from '../apis/couponApi';
import {
  COUPON_PROPS_CHANGED,
  COUPON_FORM_PROPS,
  COUPON_INITIAL_STATE,
  COUPONS_ADD_COUPON_TO_LIST,
  AppThunk,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
  EDIT_COUPON_INITIAL_STATE,
  COUPONS_GET_LIST_COUPONS,
  COUPONS_GET_SENT_COUPONS,
  UPDATE_SENT_COUPONS_LIST,
} from './types';
import {
  CouponState,
  CouponPropsChangedAction,
} from '../modules/Coupon/coupon.types';
import {
  validateFieldsCoupon,
  newCouponObject,
  validateCustomerFieldsCoupon,
  newCustomerCouponObject,
} from '../modules/Coupon/coupon.utils';
import { URL_IMAGE } from '../apis/config';
import moment from 'moment';
import { CouponApiResponse, Currency } from '../apis/ApiTypes';
import { getCouponsList, getCouponsChildrenList } from '../apis/couponsApi';

export const couponPropsChanged = (
  prop: string,
  value: any
): CouponPropsChangedAction => {
  return { type: COUPON_PROPS_CHANGED, prop, value };
};

export const addCoupon = (
  walletId: string | null,
  props: CouponState,
  callback: () => void
): AppThunk => (dispatch) => {
  const { newState, valid } = validateFieldsCoupon(props);
  dispatch({ type: COUPON_FORM_PROPS, newState });
  const newCoupon = newCouponObject(newState);

  if (valid) {
    dispatch({
      type: COUPON_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });

    createCoupon(walletId, newCoupon).then((res: any) => {
      if (res.status === 200) {
        dispatch({
          type: COUPONS_ADD_COUPON_TO_LIST,
          payload: res.data,
        });
        dispatch({
          type: SNACKBAR_SUCCESS,
          value: 'Le coupon a été ajouté avec succès',
        });
        dispatch({ type: COUPON_INITIAL_STATE });

        callback();
      } else if (res.status == 500 && res.data.indexOf('duplicate') != -1) {
        dispatch({
          type: SNACKBAR_ERROR,
          value: 'Le code promo est dupliqué',
        });
      } else {
        dispatch({
          type: SNACKBAR_ERROR,
          value: 'Une erreur est survenue',
        });
      }
      dispatch({
        type: COUPON_PROPS_CHANGED,
        prop: 'loading',
        value: false,
      });
    });
  }
};

export const couponInitialState = (): AppThunk => (dispatch) => {
  dispatch({ type: COUPON_INITIAL_STATE });
};

const extractSymbol = (value: string, currencies: Currency[]) => {
  if (value.includes('%')) return '%';

  for (const { currencySymbol } of currencies) {
    if (value.includes(currencySymbol)) return currencySymbol;
  }
  return null;
};

export const editCouponInitialState = (
  coupon: any,
  currencies: Currency[]
): any => {
  const symbol = extractSymbol(coupon.value, currencies);
  return {
    type: EDIT_COUPON_INITIAL_STATE,
    prop: 'payload',
    value: {
      ...coupon,
      percentReduction: coupon.value.replace(symbol, '').replace(' ', ''),
      symbol: symbol,
      image: URL_IMAGE + '/' + coupon.couponPictureUrl,
      expirationDate: moment(coupon.expirationDate),
    },
  };
};

export const editCouponConfirm = (
  walletId: string | null,
  couponId: string | null,
  props: CouponState,
  callback: () => void
): AppThunk => (dispatch) => {
  const { newState, valid } = validateFieldsCoupon(props);
  dispatch({ type: COUPON_FORM_PROPS, newState });
  const newCoupon = newCouponObject(newState);

  if (valid) {
    dispatch({
      type: COUPON_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });

    editCoupon(walletId, couponId, newCoupon)
      .then((res: any) => {
        if (res.status === 200) {
          dispatch({
            type: SNACKBAR_SUCCESS,
            value: 'Le coupon a été modifié avec succès',
          });

          callback();
        } else if (res.status === 400) {
          dispatch({
            type: COUPON_PROPS_CHANGED,
            prop: 'messageError',
            value: 'coupon.error.datesUnavailable',
          });
          dispatch({
            type: SNACKBAR_ERROR,
            value: 'Une erreur est survenue',
          });
        }
        dispatch({
          type: COUPON_PROPS_CHANGED,
          prop: 'loading',
          value: false,
        });
      })
      .finally(() => {
        getCouponsList(walletId).then((res: Array<CouponApiResponse>) => {
          dispatch({
            type: COUPONS_GET_LIST_COUPONS,
            payload: res,
          });
        });
      });
  }
};

export const editCouponCustomerConfirm = (
  walletId: string | null,
  couponId: string | null,
  customerId: string | null,
  originalCouponId: string | null,
  props: CouponState,
  callback: () => void
): AppThunk => (dispatch) => {
  const { newState, valid } = validateCustomerFieldsCoupon(props);
  dispatch({ type: COUPON_FORM_PROPS, newState });
  newState.customerId = customerId;
  const { formData: newCoupon, objectData } = newCustomerCouponObject(newState);

  if (valid) {
    dispatch({
      type: COUPON_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });

    editCoupon(walletId, couponId, newCoupon)
      .then((res: any) => {
        if (res.status === 200) {
          dispatch({
            type: SNACKBAR_SUCCESS,
            value: 'Le coupon a été modifié avec succès',
          });

          callback();
        } else if (res.status === 400) {
          dispatch({
            type: COUPON_PROPS_CHANGED,
            prop: 'messageError',
            value: 'coupon.error.datesUnavailable',
          });
          dispatch({
            type: SNACKBAR_ERROR,
            value: 'Une erreur est survenue',
          });
        }
        dispatch({
          type: COUPON_PROPS_CHANGED,
          prop: 'loading',
          value: false,
        });
      })
      .finally(() => {
        dispatch({
          type: UPDATE_SENT_COUPONS_LIST,
          row: { ...objectData, _id: couponId },
        });
      });
  }
};
