import React from 'react';
import { Wrapper } from './eventWireframe.style';

import { ellipses } from 'react-icons-kit/iconic/ellipses';
import { ratio } from 'wcag-color';
import barcode from '../../assets/images/barcode.png';

import cover from '../../assets/images/lg-thumbnail.png';
import { getDataFromCache } from '../../utils/cache';
import { URL_IMAGE } from '../../apis/config';
import Icon from 'react-icons-kit';

import moment from 'moment';
import QRCode from 'qrcode.react';

type WireframeProps = {
  backgroundColor: any;
  strip: any;
  price: any;
  endDate: any;
  startDate: any;
  openDate: any;
  terms: any;
  name: any;
  locationName: any;
  subscriptionMessage: any;
  titleColor: any;
  textColor: any;
  QrCode: any;
  locations: any[];
  email: string;
  contactPhone: string;
};

const EventWireframeIPhone: React.FC<WireframeProps> = (props) => {
  const {
    backgroundColor,
    strip,
    locationName,
    name,
    price,
    endDate,
    startDate,
    openDate,
    terms,
    titleColor,
    textColor,
    QrCode,
    locations,
    contactPhone,
    email,
  } = props;
  const currentWallet = getDataFromCache('wallet');

  return (
    <Wrapper
      backgroundColor={backgroundColor}
      mainContentTextColor={
        ratio('#ffffff', backgroundColor.hex) > 3.0 ? '#ffffff' : '#000000'
      }
      titleColor={titleColor}
      textColor={textColor}
      style={{ maxWidth: '334px' }}
    >
      <div className='mainSection-header-more-logo'>
        <h4>Done</h4>
        <Icon size={8} icon={ellipses} style={{ color: '#ffffff' }} />
      </div>
      <div className='mainSection'>
        <div className='mainSection-header'>
          <div className='mainSection-header-logoSection'>
            <img
              className='mainSection-header-logo'
              src={`${URL_IMAGE}/${currentWallet.logoPictureUrl}`}
              alt='wallet Logo'
            />
            <h5 className='mainSection-header-eventName'>
              {name || "Nom de l'évènement"}
            </h5>
          </div>
        </div>

        <div className='mainSection-content-banner'>
          <img
            src={
              !strip
                ? cover
                : typeof strip === 'string'
                ? strip
                : URL.createObjectURL(strip)
            }
            alt='banner'
          />
        </div>
        <div className='mainSection-content-datePoints'>
          <div className='date'>
            <h3 className='mainSection-label'>Porte</h3>
            <h3 className='mainSection-data'>porte 11</h3>
          </div>
          <div className='points'>
            <h3 className='mainSection-label'>rangee</h3>
            <h3 className='mainSection-data'>rangee 11</h3>
          </div>
          <div className='points'>
            <h3 className='mainSection-label'>chaise</h3>
            <h3 className='mainSection-data'>chaise 11</h3>
          </div>
        </div>
        <div className='mainSection-content-datePoints'>
          <div className='points'>
            <p className='mainSection-label'>beneficiere du billet</p>
            <h3 className='mainSection-data'>{'Nom Prenom'}</h3>
          </div>
          <div className='date'>
            <h3 className='mainSection-label'>Date</h3>
            <h3 className='mainSection-data'>
              {moment(openDate).format('L') +
                ' at ' +
                moment(openDate).format('h:mm a') || ''}
            </h3>
          </div>
        </div>
        <div className='mainSection-content-barCode'>
          {QrCode.state ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '270px',
              }}
            >
              <QRCode includeMargin value={QrCode.value} />
            </div>
          ) : (
            <div className='barCode'>
              <img src={barcode} alt='barcode' />
              <p className='walletId'>6066e4315abbcc1f9526d392</p>
            </div>
          )}
        </div>
      </div>
      <div className='dataSection'>
        <div className='sectionLabel'>
          <h3 className='label'>{name || 'event name'}</h3>
          <h3 className='data'>{locationName || 'location name'}</h3>
        </div>
        <span className='divider'></span>
        <div className='sectionLabel'>
          <h3 className='label'>Doors open</h3>
          <h3 className='data'>
            {moment(openDate).format('MMM DD, YYYY, h:mm a') || ''}
          </h3>
        </div>
        <span className='divider'></span>
        <div className='sectionLabel'>
          <h3 className='label'>Event start time</h3>
          <h3 className='data'>
            {moment(startDate).format('MMM DD, YYYY, h:mm a') || ''}
          </h3>
        </div>
        <span className='divider'></span>
        <div className='sectionLabel'>
          <h3 className='label'>Event end time</h3>
          <h3 className='data'>
            {moment(endDate).format('MMM DD, YYYY, h:mm a') || ''}
          </h3>
        </div>
        <span className='divider'></span>
        <div className='sectionLabel'>
          <h3 className='label'>Numero du billet</h3>
          <h3 className='data'>6066e4315abbcc1f9526d392</h3>
        </div>
        <span className='divider'></span>
        <div className='sectionLabel'>
          <h3 className='label'>valeur</h3>
          <h3 className='data'>{`${price} €` || ''}</h3>
        </div>
        <span className='divider'></span>
        <div className='sectionLabel'>
          <h3 className='label'>Terms & conditions</h3>
          <h3 className='data'>{terms || ''}</h3>
        </div>
        <div className='sectionLabel'>
          <h3 className='label'>Google Map</h3>
          <h3 className='data'>
            {`https://www.google.com/maps?q=${locations[0].latitude},${locations[0].longitude}` ||
              ''}
          </h3>
        </div>
        <div className='sectionLabel'>
          <h3 className='label'>Info Line</h3>
          <h3 className='data'>{contactPhone || ''}</h3>
        </div>
        <div className='sectionLabel'>
          <h3 className='label'>Email</h3>
          <h3 className='data'>{email || ''}</h3>
        </div>
      </div>
    </Wrapper>
  );
};

export default EventWireframeIPhone;
