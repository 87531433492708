import React from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { CircularProgress, Card } from '@material-ui/core';

import SideBar from '../../components/Drawer';
import { profileStyles } from './profile.styles';
import { ProfileStateError } from './profile.types';

import InputLabel from '../../commons/Inputs/InputLabel';
import Button from '../../commons/Button';
import ConfirmPasswordModal from '../../components/Modal';

import invisible from '../../assets/images/invisible.svg';
import visible from '../../assets/images/visibility.svg';

import {
  profilePropsChanged,
  profileConfirmUpdatePassword,
  profileInitialState,
  profileGetWalletSettings,
  profileUpdateEmailSettings,
  profileSuccessMessage,
  updateManagerEmail,
} from '../../actions';

import {
  getDataFromCache,
  removeFromCache,
  setInStorage,
} from '../../utils/cache';

import { RootState } from '../../reducers';
import { ObjectUser } from '../../apis/ApiTypes';
import {
  ProfileEmailSettings,
  CommunicationEmail,
  CommunicationEmailAuth,
} from './profile.types';
import Text from '../../commons/Text';

const Profile: React.FC<any> = (props) => {
  const intl = useIntl();
  const [open, setOpen] = React.useState<boolean>(false);
  const {
    oldPassword,
    newPassword,
    confirmPassword,
    userEmail,
    userPassword,
    emailingConfig,
    communicationEmailHost,
    communicationEmailPort,
    communicationEmailUser,
    communicationEmailPassword,
    oldPasswordError,
    newPasswordError,
    confirmPasswordError,
    emailSettingsError,
    userEmailError,
    userPasswordError,
    userPrincipalEamilExistsError,
    successMessage,
    loading,
  } = props;

  const object: ObjectUser = getDataFromCache('user');

  React.useEffect(() => {
    props.profileGetWalletSettings(object.email, object.wallet);
    /* eslint-disable-next-line */
  }, []);
  const currentWallet = getDataFromCache('wallet');
  const [showoldPassword, setShowoldPassword] = React.useState<boolean>(false);
  const [shownewPassword, setShownewPassword] = React.useState<boolean>(false);

  const [showconfirmPassword, setShowconfirmPassword] = React.useState<boolean>(
    false
  );
  const handleSaveEmail = async () => {
    props.updateManagerEmail(userEmail);
  };

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.profilePropsChanged(event.target.name, event.target.value);
  };

  const handleUpdateEmailSettings = () => {
    const objectUser: any = getDataFromCache('user');

    let auth: CommunicationEmailAuth = {
      user: communicationEmailUser,
      pass: communicationEmailPassword,
    };

    let communicationEmail: CommunicationEmail = {
      host: communicationEmailHost,
      port: communicationEmailPort,
      auth,
    };

    let settings: ProfileEmailSettings = {
      communicationEmail,
      email: userEmail,
      password: userPassword,
    };

    props.profileUpdateEmailSettings(settings, async () => {
      await setInStorage('user', {
        _id: object._id,
        username: object.username,
        email: userEmail,
        role: object.role,
        wallet: object.wallet,
        createdAt: object.createdAt,
        updatedAt: object.updatedAt,
        __v: object.__v,
      });
      props.profileGetWalletSettings(object.email, object.wallet);
      props.profileInitialState();
      props.profileSuccessMessage();
      setOpen(false);
    });
  };

  const handleClickUpdatePassword = () => {
    const objectUser: any = getDataFromCache('user');
    props.profileConfirmUpdatePassword(objectUser._id, props, async () => {
      await removeFromCache('token');
      await removeFromCache('user');
      props.history.push('/login');
    });
  };

  const getFormatedMessage = (prop: ProfileStateError) => {
    return props[prop] !== ''
      ? intl.formatMessage({ id: props[prop] })
      : props[prop];
  };

  const renderErrorMessage = (prop: any) => {
    if (userPrincipalEamilExistsError != '') {
      if (userPrincipalEamilExistsError === 'email already exists') {
        return intl.formatMessage({
          id: 'profile.error.userPrincipalEamilExistsError',
        });
      } else {
        return intl.formatMessage({ id: 'profile.error.emailCommunication' });
      }
    }
    if (emailSettingsError != '') {
      return getFormatedMessage('emailSettingsError');
    }
    if (oldPasswordError != '') {
      return getFormatedMessage('oldPasswordError');
    }
    if (newPasswordError != '') {
      return getFormatedMessage('newPasswordError');
    }
    if (userEmailError != '') {
      return getFormatedMessage('userEmailError');
    }
    if (userPasswordError) {
      return getFormatedMessage('userPasswordError');
    }
  };

  return (
    <SideBar title={intl.formatMessage({ id: 'drawer.menuItem.profile' })}>
      <Wrapper
        style={
          emailingConfig == 'SES'
            ? { marginLeft: '10px', gap: '50px', marginTop: '10px' }
            : { display: 'flex', gap: '50px', marginTop: '10px' }
        }
      >
        <div>
          <div className='header-buttons-container'>
            <Button onClick={() => handleSaveEmail()}>
              {intl.formatMessage({
                id: 'profile.button.updateEmailSettings',
              })}
            </Button>
          </div>

          <div className='section-one'>
            <div className='section-smpt'>
              <Text
                color='#404040'
                lineHeight={27}
                fontWeight={500}
                fontSize={18}
                style={{ alignSelf: 'flex-start', marginBottom: '10px' }}
              >
                {intl.formatMessage({ id: 'profile.label.emailSettings' })}
              </Text>
              <div style={{ marginTop: '-9px' }}>
                <InputLabel
                  width={280}
                  type='input'
                  disabled={emailingConfig === 'SES'}
                  className='manager-email-input'
                  // width={420}
                  label={intl.formatMessage({
                    id: 'profile.label.managerEmail',
                  })}
                  name='userEmail'
                  value={userEmail}
                  placeholder={intl.formatMessage({
                    id: 'profile.placeholder.managerEmail',
                  })}
                  error={getFormatedMessage('userEmailError')}
                  onChange={(e) => handleEventChange(e)}
                />
              </div>
            </div>
            {emailingConfig !== 'SES' && (
              <div className='section-ses'>
                <Text
                  color='#404040'
                  lineHeight={27}
                  fontWeight={500}
                  fontSize={18}
                  style={{ alignSelf: 'flex-start', marginBottom: '10px' }}
                >
                  {intl.formatMessage({
                    id: 'profile.label.communicationEmail',
                  })}
                </Text>
                {/* {emailingConfig === 'SES' && (
              <Text
                color='red'
                lineHeight={27}
                fontWeight={500}
                fontSize={18}
                style={{
                  alignSelf: 'flex-start',
                  marginBottom: '10px',
                  marginTop: '20px',
                }}
              >
                Veuillez contacter l'administrateur pour modifier ces paramètres
              </Text>
            )} */}

                <div className='user-crendentials'>
                  <InputLabel
                    //disabled={emailingConfig === 'SES'}
                    type='input'
                    width={280}
                    // width={320}
                    name='communicationEmailUser'
                    value={communicationEmailUser}
                    label={intl.formatMessage({
                      id: 'profile.label.communicationEmailUser',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'profile.placeholder.communicationEmailUser',
                    })}
                    onChange={(e) => handleEventChange(e)}
                  />
                  <InputLabel
                    //disabled={emailingConfig === 'SES'}
                    type='input'
                    width={280}
                    // width={320}
                    name='communicationEmailPassword'
                    value={communicationEmailPassword}
                    label={intl.formatMessage({
                      id: 'profile.label.communicationEmailPass',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'profile.placeholder.communicationEmailPass',
                    })}
                    onChange={(e) => handleEventChange(e)}
                  />
                </div>

                <div className='server-credentials'>
                  <InputLabel
                    // disabled={emailingConfig === 'SES'}
                    type='input'
                    width={280}
                    // width={320}
                    name='communicationEmailHost'
                    value={communicationEmailHost}
                    label={intl.formatMessage({
                      id: 'profile.label.communicationEmailHost',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'profile.placeholder.communicationEmailHost',
                    })}
                    onChange={(e) => handleEventChange(e)}
                  />
                  <InputLabel
                    //disabled={emailingConfig === 'SES'}
                    type='input'
                    width={280}
                    // width={320}
                    name='communicationEmailPort'
                    value={
                      communicationEmailPort == 0 ? '' : communicationEmailPort
                    }
                    label={intl.formatMessage({
                      id: 'profile.label.communicationEmailPort',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'profile.placeholder.communicationEmailPort',
                    })}
                    onChange={(e) => handleEventChange(e)}
                  />
                </div>
                <Button
                  style={{ marginLeft: '64px' }}
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  {intl.formatMessage({
                    id: 'profile.button.updateEmailSettings',
                  })}
                </Button>
                {successMessage ? (
                  <span className='success-message'>
                    {intl.formatMessage({
                      id: 'profile.success.successMessage',
                    })}
                  </span>
                ) : (
                  ''
                )}
              </div>
            )}
          </div>
        </div>
        <div>
          {' '}
          <div className='header-buttons-container'>
            <Button onClick={handleClickUpdatePassword}>
              {loading ? (
                <CircularProgress size={20} color='inherit' />
              ) : (
                intl.formatMessage({ id: 'profile.button.updatePassword' })
              )}
            </Button>
          </div>
          <div className='section-two'>
            <Text
              color='#404040'
              lineHeight={27}
              fontWeight={500}
              fontSize={18}
              style={{ alignSelf: 'flex-start', marginBottom: '10px' }}
            >
              {intl.formatMessage({ id: 'profile.label.updatePasswordTitle' })}
            </Text>
            <div className='password-form'>
              <div className='password-section'>
                <InputLabel
                  type={showoldPassword ? 'text' : 'password'}
                  width={315}
                  label={intl.formatMessage({
                    id: 'profile.label.oldPassword',
                  })}
                  name='oldPassword'
                  value={oldPassword}
                  error={getFormatedMessage('oldPasswordError')}
                  placeholder={intl.formatMessage({
                    id: 'profile.placeholder.oldPassword',
                  })}
                  onChange={(e) => handleEventChange(e)}
                />
                <span className='icon'>
                  {showoldPassword ? (
                    <img
                      src={visible}
                      alt='visible'
                      onClick={() => {
                        setShowoldPassword(false);
                      }}
                    />
                  ) : (
                    <img
                      src={invisible}
                      alt='invisible'
                      onClick={() => {
                        setShowoldPassword(true);
                      }}
                    />
                  )}
                </span>
              </div>
              <div className='password-section'>
                <InputLabel
                  type={shownewPassword ? 'text' : 'password'}
                  width={315}
                  label={intl.formatMessage({
                    id: 'profile.label.newPassword',
                  })}
                  name='newPassword'
                  value={newPassword}
                  error={getFormatedMessage('newPasswordError')}
                  placeholder={intl.formatMessage({
                    id: 'profile.placeholder.newPassword',
                  })}
                  onChange={(e) => handleEventChange(e)}
                />
                <span className='icon'>
                  {shownewPassword ? (
                    <img
                      src={visible}
                      alt='visible'
                      onClick={() => {
                        setShownewPassword(false);
                      }}
                    />
                  ) : (
                    <img
                      src={invisible}
                      alt='invisible'
                      onClick={() => {
                        setShownewPassword(true);
                      }}
                    />
                  )}
                </span>
              </div>
              <div className='password-section'>
                <InputLabel
                  type={showconfirmPassword ? 'text' : 'password'}
                  width={315}
                  label={intl.formatMessage({
                    id: 'profile.label.confirmPassword',
                  })}
                  name='confirmPassword'
                  value={confirmPassword}
                  error={getFormatedMessage('confirmPasswordError')}
                  placeholder={intl.formatMessage({
                    id: 'profile.placeholder.confirmPassword',
                  })}
                  onChange={(e) => handleEventChange(e)}
                />
                <span className='icon'>
                  {showconfirmPassword ? (
                    <img
                      src={visible}
                      alt='visible'
                      onClick={() => {
                        setShowconfirmPassword(false);
                      }}
                    />
                  ) : (
                    <img
                      src={invisible}
                      alt='invisible'
                      onClick={() => {
                        setShowconfirmPassword(true);
                      }}
                    />
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
        {emailingConfig == 'SES' && (
          <div className='section-three'>
            <div className='email-Alert-box'>
              <Text
                color='#404040'
                lineHeight={27}
                fontWeight={500}
                fontSize={18}
                style={{ alignSelf: 'flex-start', marginBottom: '10px' }}
              >
                Note
              </Text>
              <div className='message-box'>
                <p>
                  Vous utilisez actuellement le service e-mail de Bestwallet ,
                  si vous souhaitez utiliser votre configuration SMTP veuillez
                  contacter l'administrateur
                </p>
              </div>
            </div>
          </div>
        )}
        {/**Confirm Password on submit  */}
        <div>
          <ConfirmPasswordModal
            open={open}
            onClose={() => {
              if (
                userPrincipalEamilExistsError !== '' ||
                emailSettingsError !== '' ||
                userPasswordError !== ''
              ) {
                props.profileGetWalletSettings(object.email, object.wallet);
                props.profileInitialState();
              }
              setOpen(!open);
            }}
          >
            <InputLabel
              type='password'
              width={310}
              label={intl.formatMessage({
                id: 'profile.label.emailSettingsConfirmation',
              })}
              name='userPassword'
              value={userPassword}
              placeholder={intl.formatMessage({
                id: 'profile.placeholder.confirmPassword',
              })}
              //error={userPasswordError !== ''?getFormatedMessage('userPasswordError'): getFormatedMessage('emailSettingsError')}
              error={renderErrorMessage('')}
              onChange={(e) => handleEventChange(e)}
            />
            <Button onClick={handleUpdateEmailSettings}>
              {loading ? (
                <CircularProgress size={20} color='inherit' />
              ) : (
                intl.formatMessage({ id: 'profile.button.confirmPassword' })
              )}
            </Button>
          </ConfirmPasswordModal>
        </div>
        {/** */}
      </Wrapper>
    </SideBar>
  );
};

const Wrapper = styled('div')`
  ${(props) => profileStyles(props)}
`;

const mapStateToProps = ({ profile }: RootState) => {
  const {
    oldPassword,
    newPassword,
    userEmail,
    userPassword,
    communicationEmailHost,
    communicationEmailPort,
    communicationEmailUser,
    communicationEmailPassword,
    confirmPassword,
    oldPasswordError,
    newPasswordError,
    confirmPasswordError,
    emailSettingsError,
    userEmailError,
    userPasswordError,
    userPrincipalEamilExistsError,
    successMessage,
    emailingConfig,
    loading,
  } = profile;
  return {
    oldPassword,
    newPassword,
    userEmail,
    userPassword,
    communicationEmailHost,
    communicationEmailPort,
    communicationEmailUser,
    communicationEmailPassword,
    confirmPassword,
    oldPasswordError,
    newPasswordError,
    confirmPasswordError,
    emailSettingsError,
    userPasswordError,
    userPrincipalEamilExistsError,
    userEmailError,
    emailingConfig,
    successMessage,
    loading,
  };
};

export const connector = connect(mapStateToProps, {
  profilePropsChanged,
  profileConfirmUpdatePassword,
  profileInitialState,
  profileGetWalletSettings,
  profileUpdateEmailSettings,
  profileSuccessMessage,
  updateManagerEmail,
});

export default connector(Profile);
