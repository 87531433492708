import { css } from 'styled-components';

export const offerStyles = (props: any) => {
  return css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0;
    overflow: hidden;
    .div-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 20px;

      /* width: 95%; */
    }
    .div-content {
      display: flex;
    }
    .input-text-error {
      font-size: 14px;
      color: #ed0723;
      margin-bottom: 8px;
      margin: 5px;
    }
    .div-date-cont {
      display: flex;
    }
    .div-date {
      margin-right: 20px;
    }
    .header {
      display: flex;
      gap: 7rem;
      align-items: center;
    }
    .title {
      display: flex;
      align-self: flex-start;

      font-size: 18px;
      font-family: 500;
      font-weight: bold;
      margin: 16px;
    }
    .MuiOutlinedInput-adornedEnd {
      background: #f8f6fe;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #404040;

      border-radius: 15px;
    }
    .MuiIconButton-root {
      color: #ff5078;
    }
    .MuiIconButton-root.Mui-disabled {
      color: rgba(0, 0, 0, 0.38) !important;
    }
  `;
};
