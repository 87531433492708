import React, { Fragment, useState, useEffect, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import InputLabel from '../../commons/Inputs/InputLabel';
import InputFile from '../../commons/Inputs/InputFile';
import Button from '../../commons/Button';
import ColorPicker from '../../commons/ColorPicker';
import MapComponent from '../../components/MapComponent';
import SelectInput from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { FormWrapper } from './walletForm.styles';
import { walletFormPropsChanged, walletFormEditWallet } from '../../actions';
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
} from '@material-ui/core';
import defaultLogo from '../../assets/images/sm-thumbnail.png';
import cover from '../../assets/images/lg-thumbnail.png';
import FormLabel from '@material-ui/core/FormLabel';
import {
  additionalContacts,
  Currency,
  description,
  FromWalletProps,
} from './walletForm.types';
import { RootState } from '../../reducers';
import { isUrl, isEmail, isPhoneNumber } from '../../utils/validations';

import Wireframe from '../../components/Wireframe';
import WireframeIPhone from '../../components/WireframeIPhone';

import invisible from '../../assets/images/invisible.svg';
import visible from '../../assets/images/visibility.svg';

import { ic_add_circle_outline } from 'react-icons-kit/md/ic_add_circle_outline';
import { ic_delete } from 'react-icons-kit/md/ic_delete';
import { ic_warning } from 'react-icons-kit/md/ic_warning';
import Icon from 'react-icons-kit';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Input from '../../commons/Inputs/Input';
import Select from '@material-ui/core/Select';

import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';

import axios from 'axios';
import { useIntl } from 'react-intl';
import SimpleDialog from '../Dialog';

const WalletForm: React.FC<FromWalletProps> = (props) => {
  const {
    id,
    name,
    logo,
    icon,
    strip,
    labelColor,
    backgroundColor,
    foregroundColor,
    address,
    facebook,
    instagram,
    linkedin,
    website,
    locations,
    businessPhone,
    servicePhone,
    openingHours,
    descriptions,
    additionalContacts,
    username,
    email,
    login,
    communicationEmail,
    password,
    confirmPassword,
    button,
    gainUnit,
    loading,
    currencies,
    title,
    //errors
    businessPhoneError,
    nameError,
    logoError,
    iconError,
    stripError,
    labelColorError,
    backgroundColorError,
    foregroundColorError,
    addressError,
    openingHoursError,
    facebookError,
    instagramError,
    linkedinError,
    websiteError,
    usernameError,
    emailError,
    passwordError,
    confirmPasswordError,
    descriptionError,
    additionalContactError,
    openWalletStyle,
    openWalletInfos,
  } = props;

  const history = useHistory();
  const location = useLocation();
  const intl = useIntl();

  const [switchPreview, setSwitchPreview] = React.useState<string>('android');
  const [selectOpener, setSelectOpener] = React.useState<boolean>(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [newGainUnit, setNewGainUnit] = React.useState<string>('points');
  const handlePreviewChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSwitchPreview((event.target as HTMLInputElement).value);
  };

  const areTextAreasValid = (fieldName: string, value: string): boolean => {
    if (value.length > 200) {
      props.walletFormPropsChanged(
        `${fieldName}Error`,
        'La longueur maximale a été atteinte'
      );
      return false;
    }
    return true;
  };

  const isInputTextValid = (fieldName: string, value: string): boolean => {
    const phones = ['businessPhone', 'servicePhone'];
    const websites = ['website', 'facebook', 'instagram', 'linkedin'];

    if (value.length > 25) {
      props.walletFormPropsChanged(
        `${fieldName}Error`,
        'La longueur maximale a été atteinte'
      );
      return false;
    }
    if (websites.includes(fieldName)) {
      if (!isUrl(value)) {
        props.walletFormPropsChanged(fieldName, value);
        props.walletFormPropsChanged(`${fieldName}Error`, 'URL is invalid');
        return false;
      }
    }

    if (phones.includes(fieldName)) {
      if (!isPhoneNumber(value)) {
        props.walletFormPropsChanged(fieldName, value);
        props.walletFormPropsChanged(`${fieldName}Error`, 'Phone is invalid');
        return false;
      }
    }

    return true;
  };

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const textAreas = ['address', 'openingHours'];

    const inputFeilds = [
      'businessPhone',
      'servicePhone',
      'website',
      'facebook',
      'instagram',
      'linkedin',
    ];

    const value = event.target.value;
    const fieldName = event.target.name;
    if (textAreas.includes(fieldName))
      if (!areTextAreasValid(fieldName, value)) return;

    //all other fields
    if (inputFeilds.includes(fieldName))
      if (!isInputTextValid(fieldName, value)) return;

    props.walletFormPropsChanged(fieldName, value);
  };

  const handleColorChange = (color: any, prop: string) => {
    props.walletFormPropsChanged(prop, color);
  };
  const updateGains = () => {
    props.walletFormPropsChanged('gainUnit', newGainUnit);
    setOpenDialog(false);
  };

  const handleSelectChange = (event: any) => {
    setNewGainUnit(event.target.value);
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
    // props.history.push(`/wallets/${objectUser.wallet}`);
  };
  const handleLocationEventChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    let locationClone = [...locations];
    if (type === 'lat') {
      locationClone[0].latitude = Number(event.target.value);
    } else {
      locationClone[0].longitude = Number(event.target.value);
    }
    props.walletFormPropsChanged('locations', locationClone);
  };

  const handleLocationChange = (value: any, prop: string, type: string) => {
    let locationClone = [...locations];
    if (type === 'latLng') {
      locationClone[0].latitude = value.lat;
      locationClone[0].longitude = value.lng;
    } else if (type === 'description') {
      locationClone[0].relevantText = value;
    }
    props.walletFormPropsChanged(prop, locationClone);
  };

  const handleFileChange = (event: any, prop: string) => {
    const file = event.target.files[0];
    props.walletFormPropsChanged(prop, file);
  };

  if (location.pathname.includes('edit') && id === null) {
    history.push('/wallets');
  }

  const handleClickSave = () => {
    if (button === 'Modifier') {
      props.walletFormEditWallet(props, () => window.location.reload());
    }
  };

  const handleDescriptionTitleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    props.walletFormPropsChanged('descriptionError', '');
    let descriptionsClone = [...descriptions];
    descriptionsClone[index].title = e.target.value;
    props.walletFormPropsChanged('descriptions', descriptionsClone);
  };

  const handleDescriptionValueChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    props.walletFormPropsChanged('descriptionError', '');
    let descriptionsClone = [...descriptions];
    descriptionsClone[index].value = e.target.value;
    props.walletFormPropsChanged('descriptions', descriptionsClone);
  };

  const handleAddDescriptionChange = () => {
    if (
      descriptions[descriptions.length - 1].title === '' ||
      descriptions[descriptions.length - 1].value === ''
    ) {
      props.walletFormPropsChanged(
        'descriptionError',
        'La description ' +
          descriptions.length +
          " doit être remplie avant d'en ajouter une nouvelle."
      );
    } else {
      props.walletFormPropsChanged('descriptionError', '');
      let descriptionsClone = [...descriptions];
      descriptionsClone.push({ title: '', value: '' });
      props.walletFormPropsChanged('descriptions', descriptionsClone);
    }
  };

  const handleAdditionalContactValueChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    props.walletFormPropsChanged('additionalContactError', '');
    let additionalContactClone = [...additionalContacts];
    additionalContactClone[index].value = e.target.value;
    props.walletFormPropsChanged('additionalContacts', additionalContactClone);
  };

  const handleDeleteDescriptionChange = (index: number) => {
    let descriptionsClone = [...descriptions];
    descriptionsClone.splice(index, 1);
    props.walletFormPropsChanged('descriptions', descriptionsClone);
  };

  const handleDeleteAdditionalContactChange = (index: number) => {
    let additionalContactClone = [...additionalContacts];
    additionalContactClone.splice(index, 1);
    props.walletFormPropsChanged('additionalContacts', additionalContactClone);
  };

  const handleAdditionalContactTypeChnage = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
    index: number
  ) => {
    props.walletFormPropsChanged('additionalContactError', '');
    let additionalContactClone = [...additionalContacts];
    additionalContactClone[index].kind = e.target.value;
    props.walletFormPropsChanged('additionalContacts', additionalContactClone);
  };

  const handleAdditionalContactTitleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    props.walletFormPropsChanged('additionalContactError', '');
    let additionalContactClone = [...additionalContacts];
    additionalContactClone[index].title = e.target.value;
    props.walletFormPropsChanged('additionalContacts', additionalContactClone);
  };

  const handleAddAdditionalContactChange = () => {
    if (
      additionalContacts[additionalContacts.length - 1].title === '' ||
      additionalContacts[additionalContacts.length - 1].value === ''
    ) {
      props.walletFormPropsChanged(
        'additionalContactError',
        'Contact supplémentaire ' +
          descriptions.length +
          " doit être remplie avant d'en ajouter une nouvelle."
      );
    } else {
      props.walletFormPropsChanged('additionalContactError', '');
      let additionalContactClone = [...additionalContacts];
      additionalContactClone.push({ title: '', kind: 'URL', value: '' });
      props.walletFormPropsChanged(
        'additionalContacts',
        additionalContactClone
      );
    }
  };

  //password
  const [showpassword, setShowpassword] = useState<boolean>(false);
  const [showConfirmpassword, setShowConfirmpassword] = useState<boolean>(
    false
  );
  const [dycriptedPassword, setdycriptedPassword] = useState<
    string | undefined
  >('');
  const getPassword = () => {
    const URL = process.env.REACT_APP_API_URL;
    const requestURL = `${URL}/settings/${id}/password/`;
    axios.get(requestURL).then(
      (response) => {
        setdycriptedPassword(response.data.response.data.decryptedPassword);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <FormWrapper>
      <Fragment>
        {/* <div className='buttons-container'>
          {(businessPhoneError ||
            nameError ||
            logoError ||
            iconError ||
            stripError ||
            labelColorError ||
            backgroundColorError ||
            foregroundColorError ||
            addressError ||
            openingHoursError ||
            facebookError ||
            instagramError ||
            linkedinError ||
            websiteError ||
            usernameError ||
            emailError ||
            passwordError ||
            confirmPasswordError ||
            descriptionError ||
            additionalContactError) && (
            <div className='error-icon'>
              <Icon icon={ic_warning} size={24} style={{ color: '#FF5078' }} />
            </div>
          )}
          <Button onClick={() => handleClickSave()}>
            {loading ? (
              <CircularProgress size={20} color='inherit' />
            ) : (
              intl.formatMessage({ id: 'settings.button.save' })
            )}
          </Button>
        </div> */}
        <div
          className='container'
          style={{
            marginLeft: '-3rem',
          }}
        >
          <div className='form-container'>
            {openWalletInfos && (
              <div className='section' style={{ boxShadow: 'none' }}>
                <h3 className='section-title'>Information du compte</h3>
                {button !== 'Modifier' && (
                  <Fragment>
                    <div className='section-row'>
                      <InputLabel
                        label='Email'
                        name='email'
                        value={email}
                        error={emailError}
                        placeholder='Email'
                        onChange={(e) => handleEventChange(e)}
                      />
                      <InputLabel
                        label="Nom d'utilisateur"
                        name='username'
                        value={username}
                        error={usernameError}
                        placeholder='Login'
                        onChange={(e) => handleEventChange(e)}
                      />
                    </div>
                    <h3 className='section-title'>Mot de passe</h3>
                    <div className='section-row'>
                      <div className='password-section'>
                        <InputLabel
                          type={showpassword ? 'text' : 'password'}
                          label='Mot de passe'
                          name='password'
                          value={password}
                          error={passwordError}
                          placeholder='Mot de passe'
                          // disabled={button === 'Modifier'}
                          onChange={(e) => handleEventChange(e)}
                        />
                        <span className='icon'>
                          {showpassword ? (
                            <img
                              src={visible}
                              alt='visible'
                              onClick={() => {
                                setShowpassword(false);
                              }}
                            />
                          ) : (
                            <img
                              src={invisible}
                              alt='invisible'
                              onClick={() => {
                                setShowpassword(true);
                              }}
                            />
                          )}
                        </span>
                      </div>
                      <div className='password-section'>
                        <InputLabel
                          type={showConfirmpassword ? 'text' : 'password'}
                          label='Mot de passe'
                          name='confirmPassword'
                          value={confirmPassword}
                          error={confirmPasswordError}
                          placeholder='Confirmez le mot de passe'
                          // disabled={button === 'Modifier'}
                          onChange={(e) => handleEventChange(e)}
                        />
                        <span className='icon'>
                          {showConfirmpassword ? (
                            <img
                              src={visible}
                              alt='visible'
                              onClick={() => {
                                setShowConfirmpassword(false);
                              }}
                            />
                          ) : (
                            <img
                              src={invisible}
                              alt='invisible'
                              onClick={() => {
                                setShowConfirmpassword(true);
                              }}
                            />
                          )}
                        </span>
                      </div>
                    </div>
                  </Fragment>
                )}
                {button === 'Modifier' && (
                  <Fragment>
                    <div className='section-row'>
                      <InputLabel
                        label="Nom d'utilisateur"
                        name='username'
                        value={login}
                        placeholder="Nom d'utilisateur"
                        disabled
                      />
                      <div className='password-section'>
                        <InputLabel
                          label='Mot de passe'
                          name='mdp'
                          value={
                            dycriptedPassword ? dycriptedPassword : '**********'
                          }
                          placeholder='Mot de passe'
                          disabled
                        />
                      </div>
                    </div>
                    <div className='section-row'>
                      <InputLabel
                        label='Email de Communication'
                        name='communicationEmail'
                        value={communicationEmail}
                        placeholder='Email de Communication'
                        disabled
                      />
                      <InputLabel
                        label='Email'
                        name='email'
                        value={email}
                        placeholder='email'
                        disabled
                      />
                    </div>
                  </Fragment>
                )}
              </div>
            )}
            {openWalletInfos && (
              <div className='section' style={{ boxShadow: 'none' }}>
                <h3 className='section-title'>Information du wallet</h3>

                <div className='section-row'>
                  <InputLabel
                    label='Enseigne'
                    name='name'
                    value={name}
                    error={nameError}
                    placeholder='Enseigne'
                    onChange={(e) => handleEventChange(e)}
                  />
                </div>

                <div>
                  <div className='div-input-filter'>
                    <FormControl className='filter-container' fullWidth>
                      <Input value={gainUnit} type='text' disabled />
                      <div
                        className='filter-button'
                        onClick={() => setSelectOpener(!selectOpener)}
                      >
                        <h3>Gains</h3>
                        <ExpandMoreRoundedIcon
                          style={{ color: 'white' }}
                          className={selectOpener ? 'filter-opened' : ''}
                        />
                      </div>
                      <SimpleDialog
                        title={intl.formatMessage({
                          id: 'gainUnit.dialog.text.title',
                        })}
                        text={intl.formatMessage({
                          id: `gainUnit.dialog.text.change`,
                        })}
                        handleAgree={updateGains}
                        open={openDialog}
                        handleClose={handleCloseDialog}
                      ></SimpleDialog>
                      <Select
                        className='select-input'
                        value={gainUnit}
                        onChange={handleSelectChange}
                        placeholder='Gains'
                        id='filter'
                        inputProps={{ className: 'input' }}
                        open={selectOpener}
                        onClose={() => setSelectOpener(false)}
                      >
                        <MenuItem value='points'>
                          <span>Points</span>
                        </MenuItem>
                        {currencies.map((currency: Currency) => {
                          return (
                            <MenuItem value={currency.currencySymbol}>
                              <span>
                                {currency.currency} / {currency.currencySymbol}
                              </span>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className='section-row'>
                  <InputLabel
                    label='Téléphone commercial'
                    name='businessPhone'
                    error={businessPhoneError}
                    value={businessPhone}
                    // error={nameError}
                    placeholder='Téléphone commercial'
                    onChange={(e) => handleEventChange(e)}
                  />
                  <InputLabel
                    label='Téléphone S.A.V'
                    name='servicePhone'
                    value={servicePhone}
                    // error={nameError}
                    placeholder='Téléphone S.A.V'
                    onChange={(e) => handleEventChange(e)}
                  />
                </div>
                <div className='section-row'>
                  <InputLabel
                    label='Site web'
                    name='website'
                    value={website}
                    error={websiteError}
                    placeholder='https://'
                    onChange={(e) => handleEventChange(e)}
                  />

                  <InputLabel
                    label='Facebook'
                    name='facebook'
                    value={facebook}
                    error={facebookError}
                    placeholder='https://'
                    onChange={(e) => handleEventChange(e)}
                  />
                </div>
                <div className='section-row'>
                  <InputLabel
                    label='Instagram'
                    name='instagram'
                    value={instagram}
                    error={instagramError}
                    placeholder='https://'
                    onChange={(e) => handleEventChange(e)}
                  />
                  <InputLabel
                    label='Linkedin'
                    name='linkedin'
                    value={linkedin}
                    error={linkedinError}
                    placeholder='https://'
                    onChange={(e) => handleEventChange(e)}
                  />
                </div>
                <div className='section-row'>
                  <InputLabel
                    inputType='textArea'
                    label='Adresse'
                    name='address'
                    rows={10}
                    height={158}
                    value={address}
                    error={addressError}
                    placeholder='Adresse'
                    onChange={(e) => handleEventChange(e)}
                  />

                  <InputLabel
                    label="Horaires d'ouvertures"
                    inputType='textArea'
                    rows={10}
                    height={158}
                    name='openingHours'
                    value={openingHours}
                    error={openingHoursError}
                    placeholder="Horaires d'ouvertures"
                    onChange={(e) => handleEventChange(e)}
                  />
                </div>
              </div>
            )}
            {openWalletInfos && (
              <div className='section' style={{ boxShadow: 'none' }}>
                <h3 className='section-title'>
                  Contact supplémentaire (Gérant, Responsable administratif ...)
                </h3>
                {additionalContacts.map((el: any, index: number) => (
                  <div key={index} className='additionalContact'>
                    <div className='additionalContact-title'>
                      <h3>Contact supplémentaire {index + 1}</h3>
                      {index > 0 && (
                        <span
                          onClick={() =>
                            handleDeleteAdditionalContactChange(index)
                          }
                        >
                          <Icon
                            icon={ic_delete}
                            size={18}
                            style={{ color: '#FF5078' }}
                          />{' '}
                          Supprimer
                        </span>
                      )}
                    </div>

                    <InputLabel
                      label='Titre'
                      width={730}
                      name='contactTitle'
                      value={el.title}
                      placeholder='Titre du contact'
                      onChange={(e) =>
                        handleAdditionalContactTitleChange(e, index)
                      }
                    />
                    <div className='select'>
                      <p>Type de champ</p>
                      <SelectInput
                        // open={selectOpen}
                        // onOpen={() => setSelectOpen(true)}
                        label='Type de champ'
                        onChange={(e) =>
                          handleAdditionalContactTypeChnage(e, index)
                        }
                        defaultValue='URL'
                        value={el.kind}
                      >
                        <MenuItem value='URL'>URL</MenuItem>
                        <MenuItem value='PHONE'>Téléphone</MenuItem>
                        <MenuItem value='EMAIL'>Email</MenuItem>
                      </SelectInput>
                    </div>
                    <InputLabel
                      // type={el.type === 'PHONE' ? 'tel' : 'text'}
                      label='Valeur du champ'
                      width={730}
                      name='contactValue'
                      // width={280}
                      rows={20}
                      value={el.value}
                      // error={descriptionError}
                      placeholder='Valeur du champ'
                      onChange={(e) =>
                        handleAdditionalContactValueChange(e, index)
                      }
                    />
                  </div>
                ))}
                <div className='additionalContact-action'>
                  <Button
                    color='#FF5078'
                    border='1px solid #FF5078'
                    background='#fff'
                    onClick={() => handleAddAdditionalContactChange()}
                  >
                    <Icon
                      style={{ color: '#FF5078', marginRight: '10px' }}
                      icon={ic_add_circle_outline}
                      size={26}
                    />
                    Ajouter Contact supplémentaire
                  </Button>
                  <p className='error-message'>{additionalContactError}</p>
                </div>
              </div>
            )}
            {openWalletInfos && (
              <div className='section' style={{ boxShadow: 'none' }}>
                <h3 className='section-title'>Localisation</h3>
                <div className='section-row'>
                  <InputLabel
                    type='number'
                    label='Latitude'
                    name='latitude'
                    placeholder='Latitude...'
                    value={locations[0].latitude}
                    // disabled
                    // error={latitudeError}
                    onChange={(e) => handleLocationEventChange(e, 'lat')}
                  />
                  <InputLabel
                    type='number'
                    label='Longitude'
                    name='longitude'
                    placeholder='Longitude...'
                    value={locations[0].longitude}
                    // disabled
                    // error={latitudeError}
                    onChange={(e) => handleLocationEventChange(e, 'lng')}
                  />
                </div>
                <div className='section-row'>
                  <InputLabel
                    label='Message de notification de la localisation'
                    inputType='textArea'
                    rows={6}
                    height={40}
                    width={730}
                    name='notifMessage'
                    value={locations[0].relevantText}
                    placeholder='Message...'
                    onChange={(e) =>
                      handleLocationChange(
                        e.target.value,
                        'locations',
                        'description'
                      )
                    }
                  />
                </div>
                <div className='map-section'>
                  <MapComponent
                    onChange={handleLocationChange}
                    locations={locations}
                  />
                </div>
                <div className='section-description'>
                  {descriptions.map((el: any, index: number) => (
                    <div key={index} className='description'>
                      <div className='description-title'>
                        <h3>Description {index + 1}*</h3>
                        {index > 0 && (
                          <span
                            onClick={() => handleDeleteDescriptionChange(index)}
                          >
                            <Icon
                              icon={ic_delete}
                              size={18}
                              style={{ color: '#FF5078' }}
                            />{' '}
                            Supprimer
                          </span>
                        )}
                      </div>

                      <InputLabel
                        label=''
                        width={730}
                        name='descriptionTitle'
                        value={el.title}
                        placeholder='Titre de la description'
                        onChange={(e) => handleDescriptionTitleChange(e, index)}
                      />
                      <InputLabel
                        height={158}
                        inputType='textArea'
                        label=''
                        width={730}
                        name='description'
                        // width={280}
                        rows={20}
                        value={el.value}
                        // error={descriptionError}
                        placeholder='Description'
                        onChange={(e) => handleDescriptionValueChange(e, index)}
                      />
                    </div>
                  ))}
                  <div className='description-action'>
                    <Button
                      color='#FF5078'
                      border='1px solid #FF5078'
                      background='#fff'
                      onClick={() => handleAddDescriptionChange()}
                    >
                      <Icon
                        style={{ color: '#FF5078', marginRight: '10px' }}
                        icon={ic_add_circle_outline}
                        size={26}
                      />
                      Ajouter une description
                    </Button>
                    <p className='error-message'>{descriptionError}</p>
                  </div>
                </div>
              </div>
            )}

            {openWalletStyle && (
              <div className='section' style={{ boxShadow: 'none' }}>
                <h3 className='section-title'>Style du wallet</h3>
                <div className='section-row'>
                  <ColorPicker
                    label='Titre (iOS)'
                    color={labelColor}
                    error={labelColorError}
                    onChange={(color: any) => {
                      handleColorChange(color, 'labelColor');
                    }}
                  />

                  <ColorPicker
                    label='Texte (iOS)'
                    color={foregroundColor}
                    error={foregroundColorError}
                    onChange={(color: any) => {
                      handleColorChange(color, 'foregroundColor');
                    }}
                  />
                  <ColorPicker
                    label='Arrière plan (iOS-Android)'
                    color={backgroundColor}
                    error={backgroundColorError}
                    onChange={(color: any) => {
                      handleColorChange(color, 'backgroundColor');
                    }}
                  />
                </div>

                <h3 className='section-title'>Image</h3>
                <div className='section-row'>
                  <InputFile
                    className='btn_parcourir '
                    width={110}
                    label='Ajouter un logo de wallet
                  (200px/200px)'
                    image={logo}
                    defaultImage={defaultLogo}
                    error={logoError}
                    onChange={(e) => {
                      handleFileChange(e, 'logo');
                    }}
                    settingsSection={true}
                  ></InputFile>

                  <InputFile
                    className='btn_parcourir '
                    width={110}
                    label='Ajouter une icône pour les notifications(29px/29px)'
                    image={icon}
                    defaultImage={cover}
                    error={iconError}
                    onChange={(e) => {
                      handleFileChange(e, 'icon');
                    }}
                    settingsSection={true}
                  ></InputFile>
                  <InputFile
                    className='btn_parcourir '
                    label='Ajouter une Image 
                  de bande 
                  (240px*400px)
                  '
                    image={strip}
                    defaultImage={cover}
                    error={stripError}
                    onChange={(e) => {
                      handleFileChange(e, 'strip');
                    }}
                    settingsSection={true}
                  ></InputFile>
                </div>
              </div>
            )}
          </div>
          {openWalletInfos && (
            <div className='section wireframe-section'>
              <RadioGroup
                name='previews'
                value={switchPreview}
                onChange={handlePreviewChange}
                className='radioGroup'
              >
                <FormControlLabel
                  value='android'
                  control={<Radio />}
                  label='Android'
                />
                <FormControlLabel value='iOS' control={<Radio />} label='iOS' />
              </RadioGroup>

              {switchPreview === 'android' && (
                <Wireframe
                  backgroundColor={backgroundColor}
                  logo={logo}
                  name={name}
                  strip={strip}
                  address={address}
                  descriptions={descriptions}
                  openingHours={openingHours}
                  additionalContacts={additionalContacts}
                  gainUnit={gainUnit}
                />
              )}

              {switchPreview === 'iOS' && (
                <WireframeIPhone
                  backgroundColor={backgroundColor}
                  logo={logo}
                  name={name}
                  textColor={foregroundColor}
                  titleColor={labelColor}
                  strip={strip}
                  address={address}
                  descriptions={descriptions}
                  openingHours={openingHours}
                  additionalContacts={additionalContacts}
                  facebook={facebook}
                  linkedin={linkedin}
                  website={website}
                  businessPhone={businessPhone}
                  servicePhone={servicePhone}
                  instagram={instagram}
                  gainUnit={gainUnit}
                />
              )}
            </div>
          )}
          {openWalletStyle && (
            <div className='section wireframe-section'>
              <RadioGroup
                name='previews'
                value={switchPreview}
                onChange={handlePreviewChange}
                className='radioGroup'
              >
                <FormControlLabel
                  value='android'
                  control={<Radio />}
                  label='Android'
                />
                <FormControlLabel value='iOS' control={<Radio />} label='iOS' />
              </RadioGroup>

              {switchPreview === 'android' && (
                <Wireframe
                  backgroundColor={backgroundColor}
                  logo={logo}
                  name={name}
                  strip={strip}
                  address={address}
                  descriptions={descriptions}
                  openingHours={openingHours}
                  additionalContacts={additionalContacts}
                  gainUnit={gainUnit}
                />
              )}

              {switchPreview === 'iOS' && (
                <WireframeIPhone
                  backgroundColor={backgroundColor}
                  logo={logo}
                  name={name}
                  textColor={foregroundColor}
                  titleColor={labelColor}
                  strip={strip}
                  address={address}
                  descriptions={descriptions}
                  openingHours={openingHours}
                  additionalContacts={additionalContacts}
                  facebook={facebook}
                  linkedin={linkedin}
                  website={website}
                  businessPhone={businessPhone}
                  servicePhone={servicePhone}
                  instagram={instagram}
                  gainUnit={gainUnit}
                />
              )}
            </div>
          )}
        </div>
      </Fragment>
    </FormWrapper>
  );
};

const mapStateToProps = ({ walletForm }: RootState) => {
  const {
    id,
    name,
    logo,
    icon,
    strip,
    labelColor,
    backgroundColor,
    foregroundColor,
    businessPhone,
    servicePhone,
    additionalContacts,
    address,
    facebook,
    instagram,
    linkedin,
    locations,
    website,
    openingHours,
    descriptions,
    username,
    email,
    gainUnit,
    login,
    communicationEmail,
    password,
    confirmPassword,
    currencies,

    nameError,
    logoError,
    iconError,
    stripError,
    labelColorError,
    backgroundColorError,
    foregroundColorError,
    addressError,
    openingHoursError,

    businessPhoneError,
    facebookError,
    instagramError,
    linkedinError,
    websiteError,
    usernameError,
    emailError,
    passwordError,
    confirmPasswordError,
    descriptionError,
    //Label
    title,
    //Button
    button,
    //loading
    loading,
    additionalContactError,
  } = walletForm;
  return {
    id,
    name,
    logo,
    icon,
    strip,
    labelColor,
    backgroundColor,
    foregroundColor,
    address,
    facebook,
    instagram,
    linkedin,
    website,
    openingHours,
    businessPhone,
    servicePhone,
    descriptions,
    additionalContacts,
    username,
    email,
    gainUnit,
    locations,
    login,
    communicationEmail,
    password,
    confirmPassword,
    nameError,
    logoError,
    iconError,
    stripError,
    labelColorError,
    backgroundColorError,
    foregroundColorError,
    businessPhoneError,
    addressError,
    openingHoursError,
    facebookError,
    instagramError,
    linkedinError,
    websiteError,
    usernameError,
    emailError,
    passwordError,
    confirmPasswordError,
    descriptionError,
    additionalContactError,
    currencies,
    //Label
    title,
    //Button
    button,
    //loading
    loading,
  };
};
export const connector = connect(mapStateToProps, {
  walletFormPropsChanged,
  walletFormEditWallet,
});

export default connector(WalletForm);
