import { css } from 'styled-components';

export const loginStyles = (props: any) => {
  return css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(141.47deg, #FF5078 26.06%, #9D30BB 53.44%, #8C2CCC 71.62%, #691FDE 94.22%);

    > div {
      /* display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 400px;
      height: 340px;
      padding: 30px; */
       position: relative;
        width: 100%;
       min-height: 100vh;
        overflow: hidden;
    }
    
    .div-inputs {
        display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 380px;
  padding: 0rem 5rem;
  transition: all 0.2s 0.7s;
  overflow: hidden;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  position: absolute;
  right: 100px ;
  top: -90px;
    }
    .Logo {
      transform: rotate(-21.55deg);
    }
    img {
      
      position: absolute;
      height: 340px;
      width: 500px;
      left: 380px;
      top: 290px;
    }
    .login-text {
      font-family: 'Alata', sans-serif;
      font-style: normal;
      font-weight: 900;
      font-size: 24px;
      color: #ff5078;
    }
    input {
      font-size: 16px;
    }
    .login-text-error {
      font-size: 14px;
      margin: 10px 0;
      color: #ed0723;
    }
    .forgot-password-text {
      align-self: flex-end;
      font-size: 14px;
      color: #FFF;
      text-decoration: none;
      margin-top: 4px;
      margin-bottom: 20px;
      cursor: pointer;
      font-weight: 500;
    }
    .form-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.signin{
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 75%;
  width: 50%;
  transition: 1s 0.7s ease-in-out;
  display: grid;
  grid-template-columns: 1fr;
  z-index: 5;
}
.sign-in-form {
  z-index: 2;
}

.title {
  
  color: #FFF;
  margin: 0px 17rem 0px 0px;
 
}
.panels-container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  //background-color: green;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.panel {
 //display: flex;
  z-index: 6;
  background: #FFFFFF;
      box-shadow: 0px 4px 27px rgba(255, 80, 120, 0.2);
      border-radius: 0px 50px 50px 0px;
      transform: rotate(21.55deg);
      height: 200vh;
     width: 80%;
     left: 0;
     bottom: 0;
     top: 0;
     position: fixed;
     margin-left: -30rem;
     margin-top: -14rem;

@media  (max-width: 1200px) {
  .panel {
    display: none;
  }
  img {
    display: none;
  }
  

}
@media  (max-width: 500px) {
   .sign-in-form {
    
  padding-right: 0rem;
    margin-right: -2rem;
    }
    .panels-container {
      display: none;
    }
    
    img {
      display: none;
      
    }
    .title {
  
  color: #FFF;
  margin: 0px 13rem 0px 0px;
 
}
.panel {
    display: none;
  }
    svg {
      visibility: 'hidden',
    }
}
@media (max-width: 570px) {
  form {
    padding: 0 1.5rem;
  }

  .image {
    display: none;
  }
  .panel .content {
    padding: 0.5rem 1rem;
  }
  > div {
    padding: 1.5rem;
  }

  > div:before {
    bottom: 72%;
    left: 50%;
  }

  
}
  `;
};
