import styled from 'styled-components';

export const Wrapper = styled('div')<{
  backgroundColor: any;
  mainContentTextColor: string;
  textColor: string;
  titleColor: string;
}>`
  width: 100%;
  max-width: 309px;
  height: 57vh;
  background-color: #404040;
  border-radius: 10px;
  overflow-y: scroll;
  padding: 10px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;

  .divider {
    flex-grow: 1;
    border-bottom: 1px solid #eeedf1;
    margin: 5px;
    width: 100%;
  }

  .mainSection-header-more-logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 20px;
    i {
      background-color: black;
      border-radius: 50%;
      color: rgb(255, 255, 255);
      padding: 2px 10px;
    }
  }

  .mainSection {
    margin-top: 20px;
    background-color: ${(props) => props.backgroundColor.hex};
    width: 100%;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    height: fit-content;
    .mainSection-header {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    .mainSection-header-logo {
      height: 50px;
      width : 50px;
      overflow: hidden;
      img {
        object-fit: contain;
        height: 50px;
        width: 50px;
      }
    }
    .mainSection-header-logoSection {
      display: flex;
      align-items: center;
      width: 100%;
      max-height: 37px;
      justify-content: space-between;
    }
      img {
        overflow: hidden;

        object-fit: cover;
      }
    }
    .mainSection-content-banner {
      height: 100px;
      margin-top: 20px;
      overflow: hidden;
      img {
        object-fit: fill;
        height: 100px;
        width: 100%;
      }
    }
    .mainSection-content-enseigne {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      gap: 5px;
      flex : 1 !important;
      h4 {
        font-weight: 498;
        text-align: center;
        color: ${(props) => props.titleColor};
        margin: 0;
        margin-left: 1px;
        word-break: break-all;
      }
      h3 {
        font-weight: 400;
        text-align: center;
        color: ${(props) => props.textColor};
        margin: 0;
      }
    }
    .mainSection-content-name {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
      h5 {
        text-align: center;
        color: ${(props) => props.titleColor};
        margin: 0;
        margin-left: 15px;
        word-break: break-all;
      }
    }

    .mainSection-content-datePoints {
      display: flex;
      margin-top: 20px;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      .points {
        text-align: right;
      }
    }
    .mainSection-content-barCode {
      margin-top: 100px;
      margin-bottom: 23px;
      .barCode {
        width: 270px;
        margin: auto;
        background: #ffffff;
        height: 100px;
        display: flex;
        border-radius: 3px;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        img {
          width: 235px;
          height: 58px;
          align-self: center;
          margin-top: 11px;
        }
      }
      .walletId {
        font-size: 12px;
        color: black;
        text-align: center;
        margin: 7px 0px 10px 0px;
      }
    }
    .mainSection-label {
      color: ${(props) => props.titleColor};
      font-weight: 300;
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 0.1em;
      margin: 0px;
    }
    .mainSection-data {
      color: ${(props) => props.textColor};
      font-size: 16px;
      font-weight: 400;
      margin: 0px;
      text-align: left;
    }
  }
  .dataSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 0px 10px 20px 10px;
    margin-top: 17px;
    //background-color: #ffffff;
    .sectionIcon {
      margin-top: 20px;
      gap: 10px;
      align-items: center;
      display: flex;
      .data {
        color: #ffffff;
        font-weight: 500;
        font-size: 14px;
        margin: 0px;
        word-break: break-all;
      }
    }
    .sectionLabel {
      margin-top: 6px;
      margin-bottom: 6px;
      .label {
        color: #ffffff;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 0.075em;
        margin: 0px;
      }
      .data {
        color: #ffffff;
        font-weight: 500;
        font-size: 14px;
        margin: 0px;
        word-break: break-all;
      }
    }
  }
`;
