import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import QRCode from 'qrcode.react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CircularProgress } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SideBar from '../../components/Drawer';
import { settingsStyles, useStyles } from './settings.styles';
import WalletForm from '../../components/walletForm';
import TextEditor from '../../components/TextEditor';
import Radio from '@material-ui/core/Radio';
import InputLabel from '../../commons/Inputs/InputLabel';
import Button from '../../commons/Button';
import Text from '../../commons/Text';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {
  settingsPropsChanged,
  getWalletSettings,
  getWalletDetail,
  setWallet,
  confirmSettings,
  settingsInitialState,
  showSMSAccountError,
} from '../../actions';
import Icon from 'react-icons-kit';
import { ic_warning } from 'react-icons-kit/md/ic_warning';

import { RootState } from '../../reducers';
import { getDataFromCache } from '../../utils/cache';
import SimpleDialog from '../../components/Dialog';
import { makeStyles, Theme } from '@material-ui/core/styles';
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useTabStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginBottom: '5rem',
    borderRadius: '20px',
    padding: '20px',
    background: '#FFFFFF',
    boxShadow: '0px 0px 20px 2px rgba(55, 55, 55, 0.08)',
    '& .MuiAppBar-colorPrimary': {
      backgroundColor: '#FFF',
      color: '#000000',
    },
    '& .MuiTab-textColorInherit.Mui-selected': {
      opacity: 1,
      color: '#ff5078',
      // width: '17rem',
    },
    '& .MuiTab-textColorInherit': {
      width: '17rem',
    },
    '& .MuiTab-root': {
      // border: '1px solid red',
      boxShadow: '0px 0px 20px 2px rgb(55 55 55 / 8%)',
      borderRadius: '20px 20px 0px 0px',
      //minWidth: '422px',
      // maxWidth: '422px',
    },
  },
}));
const Settings: React.FC<any> = (props) => {
  const titleRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();
  const [copy, setCopy] = React.useState(false);
  const [language, setLanguage] = React.useState<string | null>(
    localStorage.getItem('language')
  );

  const [openDialog, setOpenDialog] = React.useState(false);
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const tabClasses = useTabStyles();
  const [value, setValue] = React.useState(-1);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  //TRANSLATION
  const intl = useIntl();
  const {
    wallet,
    walletForm,
    successMessage,
    emailMessage,
    subscriptionMessage,
    newOfferEmailMessage,

    newCouponEmailMessage,
    newOfferNotificationMessage,
    newCouponNotificationMessage,
    expiredOfferNotificationMessage,
    newUpdateNotificationMessage,

    expiredOfferEmailMessage,
    newUpdateEmailMessage,
    enableNewUpdateEmailMessage,
    enableOfferEmailMessage,
    enableOfferSMSMessage,
    enableCouponEmailMessage,
    smsTitle,
    smsTitleError,
    hasSMSAccount,
    smsCredits,
    loading,
    loadingSaveCommunicationChannels,
    showBarcode,
    communicationChannels,
    newAccountSMSMessage,
    newAccountSMSMessageError,
    newEventSMSMessage,
    newOfferSMSMessage,
    newOfferSMSMessageError,
    expiredOfferSMSMessage,
    newCouponSMSMessage,
    newCouponSMSMessageError,
    newUpdateSMSMessage,
    newCouponEmailMessageError,
    newOfferNotificationMessageError,
    newCouponNotificationMessageError,
    expiredOfferNotificationMessageError,
    newUpdateNotificationMessageError,
  } = props;

  const objectUser: any = getDataFromCache('user');
  const [loadingComponent, setLoadingComponent] = useState<boolean>(true);
  const [newCommchannel, setNewCommchannel] = React.useState<string>(
    'emailAdress'
  );

  React.useEffect(() => {
    props.getWalletSettings(objectUser.wallet);
    props.getWalletDetail(objectUser.wallet);
    setLoadingComponent(false);
    /* eslint-disable-next-line */
  }, []);

  const handleBackClick = () => {
    titleRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const setWalletForm = () => {
    if (walletForm) {
      props.setWallet(walletForm);
    }
  };
  const areTextAreasValid = (fieldName: string, value: string): boolean => {
    let maxLength = 200;
    if (fieldName.indexOf('Notification') != -1) maxLength = 30;

    if (value.length > maxLength) {
      props.settingsPropsChanged(
        `${fieldName}Error`,
        'La longueur maximale a été atteinte'
      );
      return false;
    }
    return true;
  };

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const textAreas = [
      'successMessage',
      'subscriptionMessage,newOfferNotificationMessage',
      'newCouponNotificationMessage',
      'expiredOfferNotificationMessage',
      'newUpdateNotificationMessage',
      'newOfferNotificationMessage',
      'subscriptionMessage',
      'newOfferEmailMessage',
      'expiredOfferEmailMessage',
      'newUpdateEmailMessage',
    ];
    const value = event.target.value;
    const fieldName = event.target.name;

    if (textAreas.includes(fieldName))
      if (!areTextAreasValid(fieldName, value)) return;
    props.settingsPropsChanged(fieldName, value);
  };

  const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.settingsPropsChanged(event.target.name, event.target.checked);
  };

  const handleChangeInscriptionFields = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (smsCredits > 0) {
      props.settingsPropsChanged(event.target.name, event.target.value);
      return;
    }
    //setNewCommchannel(event.target.value);

    props.showSMSAccountError(hasSMSAccount, intl);
  };

  const handleLanguageChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const lang = event.target.value as string;
    setLanguage(lang);
  };

  const [emailMessageEditorValue, setemailMessageEditorValue] = useState<
    string
  >('');

  const [newCouponMessageValue, setnewCouponMessageValue] = useState<string>(
    ''
  );

  const handleEmailMessageChange = (value: string) => {
    setemailMessageEditorValue(value);
  };

  const handlenewCouponMessageChange = (value: string) => {
    setnewCouponMessageValue(value);
  };
  const handleClickConfirmCommunication = () => {
    handleClickConfirm(true);
  };

  const handleClickConfirm = (loadingComm: boolean = false) => {
    if (language && language !== localStorage.getItem('language')) {
      localStorage.setItem('language', language);
      props.confirmSettings(
        objectUser.wallet,
        {
          ...props,
          ...(emailMessageEditorValue.length > 0 && {
            emailMessage: emailMessageEditorValue,
          }),
          ...(newCouponMessageValue.length > 0 && {
            newCouponEmailMessage: newCouponMessageValue,
          }),
        },
        loadingComm
      );
    } else {
      props.confirmSettings(
        objectUser.wallet,
        {
          ...props,
          ...(emailMessageEditorValue.length > 0 && {
            emailMessage: emailMessageEditorValue,
          }),
          ...(newCouponMessageValue.length > 0 && {
            newCouponEmailMessage: newCouponMessageValue,
          }),
        },
        loadingComm
      );
    }
  };

  const handleClickCancel = () => {
    props.getWalletSettings(objectUser.wallet);
    props.getWalletDetail(objectUser.wallet);
  };

  const [openMessages, setOpenMessages] = useState<boolean>(false);
  const [openWalletInfo, setOpenWalletInfo] = useState<boolean>(false);
  const [openWalletStyle, setOpenWalletStyle] = useState<boolean>(false);
  const [openWalletInfos, setOpenWalletInfos] = useState<boolean>(false);
  const [openWalletMsg, setOpenWalletMsg] = useState<boolean>(false);
  const [openWalletNotification, setOpenWalletNotification] = useState<boolean>(
    false
  );
  const [clickSaved, setClickSaved] = useState<boolean>(false);
  const identifierQr = walletForm && walletForm.identifierQr;
  const url = `${process.env.REACT_APP_API_QR}/wallet/${identifierQr}`;
  const user: any = JSON.parse(localStorage.getItem('user') || '');

  return (
    <SideBar title={intl.formatMessage({ id: 'drawer.menuList.settings' })}>
      {loadingComponent ? (
        <CircularProgress />
      ) : (
        <Wrapper>
          <div className='settings-header'>
            <Text
              ref={titleRef}
              fontWeight={700}
              fontSize={24}
              lineHeight={36}
              color='#404040'
            >
              {intl.formatMessage({ id: 'settings.label.settingsTitle' })}
            </Text>
            <div className='settings-header-buttons'>
              <Button onClick={handleClickConfirmCommunication}>
                {loadingSaveCommunicationChannels ? (
                  <CircularProgress size={20} color='inherit' />
                ) : (
                  intl.formatMessage({ id: 'settings.button.save' })
                )}
              </Button>
            </div>
          </div>
          <div className='settings-container'>
            <div className='settings'>
              <div className='settings-card-inscription'>
                <Text
                  fontWeight={500}
                  fontSize={18}
                  lineHeight={24}
                  color='#828282'
                  style={{ alignSelf: 'flex-start' }}
                >
                  Paramètre d'inscription
                </Text>

                <TableContainer>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Identifiant</TableCell>
                        <TableCell>Lien</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell component='th' scope='row'>
                          {user?.wallet}
                        </TableCell>
                        <TableCell>{url}</TableCell>
                        <TableCell>
                          <CopyToClipboard
                            text={url}
                            onCopy={() => {
                              setCopy(true);
                            }}
                          >
                            <button className='copy-btn'>
                              {intl.formatMessage({
                                id: 'settings.button.copyLink',
                              })}
                            </button>
                          </CopyToClipboard>
                          {copy ? (
                            <span
                              style={{ margin: '0 10px', color: '#ff5078' }}
                            >
                              {intl.formatMessage({
                                id: 'settings.label.linkCopied',
                              })}
                            </span>
                          ) : null}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <FormGroup row style={{ marginTop: '21px' }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showBarcode}
                        onChange={handleChangeCheckBox}
                        name='showBarcode'
                        size='medium'
                      />
                    }
                    label="Afficher le champ de code à barres lors de l'ajout d'un client"
                  />
                </FormGroup>
                {/*<SimpleDialog
                  title={intl.formatMessage({
                    id: 'gainUnit.dialog.text.title',
                  })}
                  text={intl.formatMessage({
                    id: !hasSMSAccount
                      ? `settings.communicationChannel.noAccountMessage`
                      : 'settings.communicationChannel.noCreditsMessage',
                  })}
                  handleAgree={updateCommunicationChannels}
                  open={openDialog}
                  handleClose={handleCloseDialog}
                ></SimpleDialog>*/}
                <Text
                  fontWeight={500}
                  fontSize={18}
                  lineHeight={24}
                  color='#828282'
                  style={{
                    alignSelf: 'flex-start',
                    marginTop: '20px',
                    marginBottom: '20px',
                  }}
                >
                  Canaux de communication
                </Text>

                <RadioGroup
                  aria-label='subscripition Fields'
                  name='communicationChannels'
                  value={communicationChannels}
                  onChange={handleChangeInscriptionFields}
                  className='radioGroup'
                >
                  <FormControlLabel
                    value='emailAdress'
                    control={<Radio />}
                    label='Email'
                  />
                  <FormControlLabel
                    value='phoneNumber'
                    control={<Radio />}
                    label='Numéro de téléphone'
                  />

                  <FormControlLabel
                    value='all'
                    control={<Radio />}
                    label='Email et Numéro de téléphone'
                  />
                </RadioGroup>

                {/* <div
                  className='settings-header-buttons'
                  style={{ marginTop: '20px' }}
                >
                  <Button onClick={handleClickConfirmCommunication}>
                    {loadingSaveCommunicationChannels ? (
                      <CircularProgress size={20} color='inherit' />
                    ) : (
                      intl.formatMessage({ id: 'settings.button.save' })
                    )}
                  </Button>
                </div> */}
              </div>

              <div className='settings-card-qrcode'>
                <Text
                  fontWeight={500}
                  fontSize={14}
                  lineHeight={24}
                  color='#AEAEAE'
                >
                  {intl.formatMessage({
                    id: 'settings.label.QrCode',
                  })}
                </Text>
                <QRCode includeMargin value={url} />
              </div>
            </div>
            <div className='sectionHeader' style={{ marginBottom: '12px' }}>
              <div className='settings-header-buttons buttons-container'>
                {(smsTitleError ||
                  newAccountSMSMessageError ||
                  newOfferSMSMessageError ||
                  newCouponSMSMessageError) && (
                  <div className='error-icon'>
                    <Icon
                      icon={ic_warning}
                      size={24}
                      style={{ color: '#FF5078' }}
                    />
                  </div>
                )}

                <Button onClick={handleClickConfirm}>
                  {loading ? (
                    <CircularProgress size={20} color='inherit' />
                  ) : (
                    intl.formatMessage({ id: 'settings.button.save' })
                  )}
                </Button>
              </div>
            </div>
            <div className={tabClasses.root}>
              <AppBar position='static'>
                <Tabs
                  centered
                  value={value}
                  onChange={handleChange}
                  aria-label='simple tabs example'
                >
                  <Tab
                    label='Styles'
                    {...a11yProps(0)}
                    onClick={() => {
                      setOpenWalletStyle(true);
                    }}
                  />
                  <Tab
                    label='Informations'
                    {...a11yProps(1)}
                    onClick={() => {
                      setOpenWalletInfos(true);
                    }}
                  />
                  <Tab label='Messages' {...a11yProps(2)} />
                  <Tab label='Notification' {...a11yProps(3)} />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                {setOpenWalletStyle && (
                  <>
                    <WalletForm
                      openWalletStyle={openWalletStyle}
                      setOpenWalletStyle={setOpenWalletStyle}
                    />
                  </>
                )}
              </TabPanel>
              <TabPanel value={value} index={1}>
                {setOpenWalletInfos && (
                  <>
                    <WalletForm
                      openWalletInfos={openWalletInfos}
                      setOpenWalletInfos={setOpenWalletInfos}
                    />
                  </>
                )}
              </TabPanel>
              <TabPanel value={value} index={2}>
                <>
                  <div className='card'>
                    <Text
                      fontWeight={500}
                      fontSize={18}
                      lineHeight={24}
                      color='#404040'
                      style={{ alignSelf: 'flex-start' }}
                    >
                      Inscription
                    </Text>
                    <div className='card-inputs'>
                      <InputLabel
                        inputType='textArea'
                        label={intl.formatMessage({
                          id: 'settings.label.validationMessage',
                        })}
                        name='successMessage'
                        value={successMessage}
                        // width={480}
                        placeholder={intl.formatMessage({
                          id: 'settings.placeholder.validationMessage',
                        })}
                        onChange={(e) => handleEventChange(e)}
                      />
                      <TextEditor
                        label={intl.formatMessage({
                          id: 'settings.label.emailMessage',
                        })}
                        placeholder={intl.formatMessage({
                          id: 'settings.placeholder.emailMessage',
                        })}
                        oldState={emailMessage}
                        changeValue={handleEmailMessageChange}
                      />

                      <InputLabel
                        inputType='textArea'
                        label={intl.formatMessage({
                          id: 'settings.label.newAccountSMSMessage',
                        })}
                        error={newAccountSMSMessageError}
                        name='newAccountSMSMessage'
                        value={newAccountSMSMessage}
                        // width={480}
                        placeholder={intl.formatMessage({
                          id: 'settings.placeholder.newAccountSMSMessage',
                        })}
                        onChange={(e) => handleEventChange(e)}
                      />

                      {/* <InputLabel
                    inputType='textArea'
                    label={intl.formatMessage({
                      id: 'settings.label.emailMessage',
                    })}
                    name='emailMessage'
                    value={emailMessage}
                    // width={480}
                    placeholder={intl.formatMessage({
                      id: 'settings.placeholder.emailMessage',
                    })}
                    onChange={(e) => handleEventChange(e)}
                  /> */}
                    </div>
                  </div>

                  <div className='card'>
                    <Text
                      fontWeight={500}
                      fontSize={18}
                      lineHeight={24}
                      color='#404040'
                      style={{ alignSelf: 'flex-start' }}
                    >
                      Abonnement
                    </Text>
                    <div className='card-inputs'>
                      <InputLabel
                        inputType='textArea'
                        label={intl.formatMessage({
                          id: 'settings.label.subscriptionMessage',
                        })}
                        name='subscriptionMessage'
                        value={subscriptionMessage}
                        placeholder={intl.formatMessage({
                          id: 'settings.placeholder.subscriptionMessage',
                        })}
                        onChange={(e) => handleEventChange(e)}
                      />
                    </div>
                  </div>

                  <div className='card'>
                    <Text
                      fontWeight={500}
                      fontSize={18}
                      lineHeight={24}
                      color='#404040'
                      style={{ alignSelf: 'flex-start' }}
                    >
                      Nouvelle offre
                    </Text>
                    <div className='card-inputs'>
                      <InputLabel
                        inputType='textArea'
                        label={intl.formatMessage({
                          id: 'settings.label.offerEmailMessage',
                        })}
                        name='newOfferEmailMessage'
                        value={newOfferEmailMessage}
                        // width={480}

                        placeholder={intl.formatMessage({
                          id: 'settings.placeholder.offerEmailMessage',
                        })}
                        onChange={(e) => handleEventChange(e)}
                      />
                      <InputLabel
                        inputType='textArea'
                        label={intl.formatMessage({
                          id: 'settings.label.newOfferSMSMessage',
                        })}
                        name='newOfferSMSMessage'
                        value={newOfferSMSMessage}
                        error={newOfferSMSMessageError}
                        // width={480}
                        placeholder={intl.formatMessage({
                          id: 'settings.placeholder.newOfferSMSMessage',
                        })}
                        onChange={(e) => handleEventChange(e)}
                      />

                      <InputLabel
                        inputType='textArea'
                        label={intl.formatMessage({
                          id: 'settings.label.offerNotificationMessage',
                        })}
                        error={newOfferNotificationMessageError}
                        name='newOfferNotificationMessage'
                        value={newOfferNotificationMessage}
                        // width={480}

                        placeholder={intl.formatMessage({
                          id: 'settings.placeholder.offerNotificationMessage',
                        })}
                        onChange={(e) => handleEventChange(e)}
                      />
                    </div>
                  </div>

                  <div className='card'>
                    <Text
                      fontWeight={500}
                      fontSize={18}
                      lineHeight={24}
                      color='#404040'
                      style={{ alignSelf: 'flex-start' }}
                    >
                      Nouveau coupon
                    </Text>
                    <div className='card-inputs'>
                      <TextEditor
                        label={intl.formatMessage({
                          id: 'settings.label.couponEmailMessage',
                        })}
                        placeholder={intl.formatMessage({
                          id: 'settings.placeholder.couponEmailMessage',
                        })}
                        error={newCouponEmailMessageError}
                        oldState={newCouponEmailMessage}
                        changeValue={handlenewCouponMessageChange}
                      />
                      {/* <InputLabel
                      inputType='textArea'
                      label={intl.formatMessage({
                        id: 'settings.label.couponEmailMessage',
                      })}
                      name='newCouponEmailMessage'
                      value={newCouponEmailMessage}
                      // width={360}
                      placeholder={intl.formatMessage({
                        id: 'settings.placeholder.couponEmailMessage',
                      })}
                      onChange={(e) => handleEventChange(e)}
                    /> */}

                      <InputLabel
                        inputType='textArea'
                        label={intl.formatMessage({
                          id: 'settings.label.couponSMSMessage',
                        })}
                        name='newCouponSMSMessage'
                        value={newCouponSMSMessage}
                        // width={480}
                        error={newCouponSMSMessageError}
                        placeholder={intl.formatMessage({
                          id: 'settings.placeholder.couponSMSMessage',
                        })}
                        onChange={(e) => handleEventChange(e)}
                      />

                      <InputLabel
                        inputType='textArea'
                        label={intl.formatMessage({
                          id: 'settings.label.couponNotificationMessage',
                        })}
                        error={newCouponNotificationMessageError}
                        name='newCouponNotificationMessage'
                        value={newCouponNotificationMessage}
                        // width={480}
                        placeholder={intl.formatMessage({
                          id: 'settings.placeholder.couponNotificationMessage',
                        })}
                        onChange={(e) => handleEventChange(e)}
                      />
                    </div>
                  </div>

                  <div className='card'>
                    <Text
                      fontWeight={500}
                      fontSize={18}
                      lineHeight={24}
                      color='#404040'
                      style={{ alignSelf: 'flex-start' }}
                    >
                      Offre expirée
                    </Text>
                    <div className='card-inputs'>
                      <InputLabel
                        inputType='textArea'
                        // label={intl.formatMessage({
                        //   id: 'settings.label.couponEmailMessage',
                        // })}
                        label="Texte de l'email (offre expirée)"
                        name='expiredOfferEmailMessage'
                        value={expiredOfferEmailMessage}
                        // width={360}
                        // placeholder={intl.formatMessage({
                        //   id: 'settings.placeholder.couponEmailMessage',
                        // })}
                        placeholder="Texte de l'email (offre expirée)"
                        onChange={(e) => handleEventChange(e)}
                      />
                      <InputLabel
                        inputType='textArea'
                        // label={intl.formatMessage({
                        //   id: 'settings.label.couponNotificationMessage',
                        // })}
                        label='Texte de la notification (offre expirée)'
                        name='expiredOfferNotificationMessage'
                        value={expiredOfferNotificationMessage}
                        error={expiredOfferNotificationMessageError}
                        // width={480}
                        // placeholder={intl.formatMessage({
                        //   id: 'settings.placeholder.couponNotificationMessage',
                        // })}
                        placeholder='Texte de la notification (offre expirée)'
                        onChange={(e) => handleEventChange(e)}
                      />
                    </div>
                  </div>

                  <div className='card'>
                    <Text
                      fontWeight={500}
                      fontSize={18}
                      lineHeight={24}
                      color='#404040'
                      style={{ alignSelf: 'flex-start' }}
                    >
                      Mise à jour
                    </Text>
                    <div className='card-inputs'>
                      <InputLabel
                        inputType='textArea'
                        // label={intl.formatMessage({
                        //   id: 'settings.label.couponEmailMessage',
                        // })}
                        label="Texte de l'email (mise à jour)"
                        name='newUpdateEmailMessage'
                        value={newUpdateEmailMessage}
                        // width={360}
                        // placeholder={intl.formatMessage({
                        //   id: 'settings.placeholder.couponEmailMessage',
                        // })}
                        placeholder="Texte de l'email (mise à jour)"
                        onChange={(e) => handleEventChange(e)}
                      />
                      <InputLabel
                        inputType='textArea'
                        // label={intl.formatMessage({
                        //   id: 'settings.label.couponNotificationMessage',
                        // })}
                        label='Texte de la notification (mise à jour)'
                        name='newUpdateNotificationMessage'
                        error={newUpdateNotificationMessageError}
                        value={newUpdateNotificationMessage}
                        // width={480}
                        // placeholder={intl.formatMessage({
                        //   id: 'settings.placeholder.couponNotificationMessage',
                        // })}
                        placeholder='Texte de la notification (mise à jour)'
                        onChange={(e) => handleEventChange(e)}
                      />
                    </div>
                  </div>
                  <div className='card'>
                    <Text
                      fontWeight={500}
                      fontSize={18}
                      lineHeight={24}
                      color='#404040'
                      style={{ alignSelf: 'flex-start' }}
                    >
                      SMS
                    </Text>
                    <div className='card-inputs'>
                      <InputLabel
                        label={intl.formatMessage({
                          id: 'settings.label.smsTitle',
                        })}
                        name='smsTitle'
                        value={smsTitle}
                        error={smsTitleError}
                        placeholder={walletForm.name}
                        onChange={(e) => handleEventChange(e)}
                      />
                    </div>
                  </div>
                </>
              </TabPanel>
              <TabPanel value={value} index={3}>
                <>
                  <div className='card'>
                    <Text
                      fontWeight={500}
                      fontSize={16}
                      lineHeight={24}
                      color='#828282'
                      style={{ alignSelf: 'flex-start', marginBottom: '12px' }}
                    >
                      Activation de la notification par email
                    </Text>

                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={enableNewUpdateEmailMessage}
                            onChange={handleChangeCheckBox}
                            name='enableNewUpdateEmailMessage'
                          />
                        }
                        label='Mise à jour du wallet'
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            checked={enableOfferEmailMessage}
                            onChange={handleChangeCheckBox}
                            name='enableOfferEmailMessage'
                          />
                        }
                        label='Nouvelles offres'
                      />

                      <FormControlLabel
                        control={
                          <Switch
                            disabled
                            checked={enableCouponEmailMessage}
                            onChange={handleChangeCheckBox}
                            name='enableCouponEmailMessage'
                          />
                        }
                        label='Nouveaux coupons'
                      />
                    </FormGroup>
                    <Text
                      fontWeight={500}
                      fontSize={16}
                      lineHeight={24}
                      color='#828282'
                      style={{
                        alignSelf: 'flex-start',
                        marginBottom: '12px',
                        marginTop: '16px',
                      }}
                    >
                      Activation de la notification par SMS
                    </Text>

                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={enableOfferSMSMessage}
                            onChange={handleChangeCheckBox}
                            name='enableOfferSMSMessage'
                          />
                        }
                        label='Nouvelles offres'
                      />
                    </FormGroup>
                  </div>
                </>
              </TabPanel>
            </div>
          </div>
        </Wrapper>
      )}
    </SideBar>
  );
};

const Wrapper = styled('div')`
  ${(props) => settingsStyles(props)}
`;

const mapStateToProps = ({ settings }: RootState) => {
  const {
    wallet,
    walletForm,
    successMessage,
    emailMessage,
    subscriptionMessage,
    newOfferEmailMessage,
    newOfferNotificationMessage,
    newCouponEmailMessage,
    newCouponNotificationMessage,
    expiredOfferEmailMessage,
    expiredOfferNotificationMessage,
    newUpdateEmailMessage,
    newUpdateNotificationMessage,
    enableNewUpdateEmailMessage,
    enableOfferEmailMessage,
    enableOfferSMSMessage,
    enableCouponEmailMessage,
    communicationChannels,
    smsTitle,
    smsTitleError,
    loading,
    newAccountSMSMessage,
    newEventSMSMessage,
    newOfferSMSMessage,
    expiredOfferSMSMessage,
    newCouponSMSMessage,
    newUpdateSMSMessage,
    newCouponSMSMessageError,
    showBarcode,
    newAccountSMSMessageError,
    newOfferSMSMessageError,
    hasSMSAccount,
    smsCredits,
    newCouponEmailMessageError,
    newUpdateNotificationMessageError,
    newOfferNotificationMessageError,
    newCouponNotificationMessageError,
    expiredOfferNotificationMessageError,
    loadingSaveCommunicationChannels,
  } = settings;

  return {
    wallet,
    walletForm,
    successMessage,
    emailMessage,
    subscriptionMessage,
    newOfferEmailMessage,
    showBarcode,
    enableOfferSMSMessage,
    newOfferNotificationMessage,
    newCouponEmailMessage,
    newCouponNotificationMessage,
    expiredOfferEmailMessage,
    expiredOfferNotificationMessage,
    newUpdateEmailMessage,
    newUpdateNotificationMessage,
    enableNewUpdateEmailMessage,
    enableOfferEmailMessage,
    enableCouponEmailMessage,
    communicationChannels,
    smsTitle,
    hasSMSAccount,
    smsCredits,
    smsTitleError,
    newAccountSMSMessage,
    newEventSMSMessage,
    newOfferSMSMessage,
    expiredOfferSMSMessage,
    newCouponSMSMessage,
    newCouponSMSMessageError,
    newUpdateNotificationMessageError,
    newAccountSMSMessageError,
    newOfferSMSMessageError,
    newUpdateSMSMessage,
    newCouponEmailMessageError,
    newOfferNotificationMessageError,
    newCouponNotificationMessageError,
    expiredOfferNotificationMessageError,
    loadingSaveCommunicationChannels,
    //loading
    loading,
  };
};

export const connector = connect(mapStateToProps, {
  settingsPropsChanged,
  getWalletSettings,
  getWalletDetail,
  confirmSettings,
  setWallet,
  showSMSAccountError,
  settingsInitialState,
});

export default connector(Settings);
