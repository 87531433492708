import React from 'react';
import { useIntl } from 'react-intl';
import { InputFileWrapper } from './inputFile.styles';

export type InputFileProps = {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  defaultImage: string;
  image: string | null;
  label: string;
  width?: number;
  error?: string;
  child?: boolean;
  settingsSection?: boolean;
  className?: any;
};

const InputFile: React.FC<InputFileProps> = (props) => {
  const intl = useIntl();

  const onClear = (event: any) => {
    event.target.value = null;
    // (event.target as HTMLInputElement).value = null;
  };

  const {
    onChange,
    defaultImage,
    image,
    label,
    width,
    error,
    child,
    settingsSection,
    className,
  } = props;

  let picture = defaultImage;
  if (typeof image === 'string') {
    picture = image;
  } else if (image) {
    picture = URL.createObjectURL(image);
  }
  return (
    <InputFileWrapper
      width={width}
      style={{ alignItems: settingsSection == true ? 'center' : 'start' }}
    >
      <span
        className='logo-text'
        style={{ textAlign: settingsSection == true ? 'center' : 'start' }}
      >
        {label}
      </span>
      <div className='img-content'>
        <img src={picture} alt={label} />
      </div>
      {!child && (
        <label className={className}>
          {intl.formatMessage({ id: 'inputFile.button.select' })}
          <input
            id='inputFile'
            type='file'
            hidden
            onChange={onChange}
            onClick={(e) => onClear(e)}
            accept='image/gif, image/jpeg, image/png'
          />
        </label>
      )}
      <span className='text-error'>{error}</span>
    </InputFileWrapper>
  );
};

export default InputFile;
