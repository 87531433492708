import 'date-fns';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { EventsStyles, EventsStylesProps } from './events.styles';
import EventCard from '../../components/Cards/EventCard';
import { useHistory } from 'react-router-dom';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
//Material
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';

import { KeyboardDatePicker } from '@material-ui/pickers';

import Button from '../../commons/Button';
import Input from '../../commons/Inputs/Input';
import Text from '../../commons/Text';

import EventModal from '../../components/Modal';

import emptyState from '../../assets/images/emptyState.svg';
import EmptyData from '../../components/EmptyData';
import { EventsApiResponse } from '../../apis/ApiTypes';

import {
  eventsGetAllEvents,
  eventsInitialState,
  eventsPropsChanged,
  eventsFilterEvents,
  deleteEvent,
} from '../../actions';

import { RootState } from '../../reducers';
import CreateEvent from '../CreateEvent';
import clsx from 'clsx';
import { getDataFromCache } from '../../utils/cache';
import { createEventStyles } from '../CreateEvent/createEvent.styles';

import { StyledCheckbox } from '../../commons/CheckBox/index';
import InputAdornment from '@material-ui/core/InputAdornment';
import Calendar from '../../icons/Calendar';
import Divider from '@material-ui/core/Divider';
const Events: React.FC<any> = (props) => {
  const intl = useIntl();
  const [selectOpener, setSelectOpener] = useState<boolean>(false);
  const {
    eventsList,
    eventsFilterList,
    startDate,
    endDate,
    allTime,
    loading,
    status,
  } = props;
  const [open, setOpen] = useState<boolean>(false);
  const history = useHistory();

  const objectUser: any = getDataFromCache('user');

  useEffect(() => {
    props.eventsGetAllEvents(objectUser.wallet);
    /* eslint-disable-next-line */
  }, []);

  // useEffect(() => {
  //   return () => {
  //     props.eventsInitialState();
  //   };
  // }, []);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.eventsPropsChanged(event.target.name, event.target.checked);
  };

  const handleDateChange = (prop: string) => (date: Date | null) => {
    props.eventsPropsChanged(prop, date);
  };

  const HandleClickSearch = () => {
    props.eventsFilterEvents(
      allTime,
      startDate,
      endDate,
      eventsFilterList,
      status
    );
  };

  const RedirectToCustomers = (url: string) => {
    history.push(url);
  };
  const handleSelectChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {};

  const handleClickDeleteEvent = (eventId: string) => {
    props.deleteEvent(eventId, objectUser.wallet);
  };
  // function StyledCheckbox(props: CheckboxProps) {
  //   const classes = useStyles();

  //   return (
  //     <Checkbox
  //       className={classes.root}
  //       disableRipple
  //       color='default'
  //       checkedIcon={
  //         <span className={clsx(classes.icon, classes.checkedIcon)} />
  //       }
  //       icon={<span className={classes.icon} />}
  //       inputProps={{ 'aria-label': 'decorative checkbox' }}
  //       {...props}
  //     />
  //   );
  // }

  return (
    <Wrapper loading={loading.toString()} eventsLength={eventsList.length}>
      <div className='div-search'>
        <div className='div-search-inputs'>
          <div className='div-input-filter'>
            <FormControl className='filter-container' fullWidth>
              <Input
                value={
                  status === 'ALL'
                    ? intl.formatMessage({ id: 'offers.select.all' })
                    : status === 'OPENED'
                    ? intl.formatMessage({ id: 'offers.select.opened' })
                    : status === 'ACTIF'
                    ? intl.formatMessage({ id: 'offers.select.actif' })
                    : status === 'INACTIF'
                    ? intl.formatMessage({ id: 'offers.select.inactif' })
                    : intl.formatMessage({ id: 'offers.select.expired' })
                }
                type='text'
                disabled
              />
              <div
                className='filter-button'
                onClick={() => setSelectOpener(!selectOpener)}
              >
                <h3>
                  {intl.formatMessage({
                    id: 'wallet.label.filter',
                  })}
                </h3>
                <ExpandMoreRoundedIcon
                  style={{ color: 'white' }}
                  className={selectOpener ? 'filter-opened' : ''}
                />
              </div>
              <Select
                className='select-input'
                value={status}
                onChange={handleSelectChange}
                placeholder={intl.formatMessage({
                  id: 'wallet.label.filter',
                })}
                id='filter'
                inputProps={{ className: 'input' }}
                open={selectOpener}
                onClose={() => setSelectOpener(false)}
              >
                <MenuItem
                  value='ACTIF'
                  onMouseEnter={(e) =>
                    ((e.target as HTMLInputElement).style.color = '#ff5078')
                  }
                  onMouseLeave={(e) =>
                    ((e.target as HTMLInputElement).style.color = '#000000')
                  }
                >
                  <span>
                    {intl.formatMessage({ id: 'offers.select.actif' })}
                  </span>
                </MenuItem>
                <MenuItem
                  value='INACTIF'
                  onMouseEnter={(e) =>
                    ((e.target as HTMLInputElement).style.color = '#ff5078')
                  }
                  onMouseLeave={(e) =>
                    ((e.target as HTMLInputElement).style.color = '#000000')
                  }
                >
                  <span>
                    {intl.formatMessage({ id: 'offers.select.inactif' })}
                  </span>
                </MenuItem>
                <MenuItem
                  value='EXPIRED'
                  onMouseEnter={(e) =>
                    ((e.target as HTMLInputElement).style.color = '#ff5078')
                  }
                  onMouseLeave={(e) =>
                    ((e.target as HTMLInputElement).style.color = '#000000')
                  }
                >
                  <span>
                    {intl.formatMessage({ id: 'offers.select.expired' })}
                  </span>
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          {!allTime ? (
            <div className='div-search-date'>
              <KeyboardDatePicker
                // required
                // margin='normal'
                id='start-date'
                label={intl.formatMessage({ id: 'offers.label.startDate' })}
                format='DD/MM/yyyy'
                value={startDate}
                onChange={handleDateChange('startDate')}
                // error={startDateError !== ''}
                // helperText={getFormatedMessage('startDateError')}
                // disablePast
                inputVariant='outlined'
                style={{ width: '250px' }}
                // KeyboardButtonProps={{
                //   'aria-label': 'change date',
                // }}

                keyboardIcon={
                  <>
                    <Divider
                      className='divider'
                      orientation='vertical'
                      variant='inset'
                      light
                    />
                    <Calendar />
                  </>
                }
              />

              {/* <span style={{ width: 20 }}></span> */}
              <KeyboardDatePicker
                // required
                // margin='normal'
                id='end-date'
                label={intl.formatMessage({ id: 'offers.label.endDate' })}
                format='DD/MM/yyyy'
                inputVariant='outlined'
                value={endDate}
                onChange={handleDateChange('endDate')}
                //   error={endDateError !== ''}
                //   helperText={getFormatedMessage('endDateError')}
                // disablePast
                style={{ width: '250px' }}
                disabled={startDate === null ? true : false}
                keyboardIcon={
                  <>
                    <Divider
                      className='divider'
                      orientation='vertical'
                      variant='inset'
                      light
                    />
                    <Calendar className='icon' />
                  </>
                }
              />
            </div>
          ) : (
            <div className='div-search-date'></div>
          )}
          <FormControlLabel
            control={
              // <Checkbox
              //   checked={allTime}
              //   onChange={handleCheckboxChange}
              //   name='allTime'
              // />
              <StyledCheckbox
                checked={allTime}
                onChange={handleCheckboxChange}
                name='allTime'
              />
            }
            label={intl.formatMessage({ id: 'offers.label.allTime' })}
          />
        </div>

        <Button background='#FF5078' onClick={HandleClickSearch}>
          {intl.formatMessage({ id: 'offers.button.search' })}
        </Button>
      </div>

      <div className='div-header'>
        <Text color='#404040' fontSize={24} lineHeight={36} fontWeight={600}>
          {intl.formatMessage({ id: 'events.label.title' })}
        </Text>
        <Button onClick={() => history.push('event/create')}>
          {intl.formatMessage({ id: 'events.button.create' })}
        </Button>
      </div>

      <EventModal height={700} open={open} onClose={() => setOpen(!open)}>
        <CreateEvent
          walletId={objectUser.wallet}
          onSaveChanged={() => setOpen(!open)}
        ></CreateEvent>
      </EventModal>

      <div className='div-list'>
        <EmptyData
          data={eventsList}
          loading={loading}
          message={intl.formatMessage({ id: 'coupons.label.emptyList' })}
          image={emptyState}
        >
          {eventsList.map((event: EventsApiResponse) => (
            <EventCard
              key={event._id}
              event={event}
              onClickEdit={() =>
                RedirectToCustomers('/event/edit/' + event._id)
              }
              onClickRedirect={() => RedirectToCustomers('/event/' + event._id)}
              onClickDelete={handleClickDeleteEvent}
            ></EventCard>
          ))}
        </EmptyData>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled('div')<EventsStylesProps>`
  ${(props) => EventsStyles(props)}
`;

const mapStateToProps = ({ events }: RootState) => {
  const {
    eventsList,
    loading,
    eventsFilterList,
    startDate,
    endDate,
    allTime,
  } = events;
  return {
    eventsList,
    loading,
    eventsFilterList,
    startDate,
    endDate,
    allTime,
  };
};

export const connector = connect(mapStateToProps, {
  eventsGetAllEvents,
  eventsInitialState,
  eventsPropsChanged,
  eventsFilterEvents,
  deleteEvent,
});

export default connector(Events);
