import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
//Style
import styled from 'styled-components';
import { addCustomerStyles } from './addCustomer.styles';
//Material
import { CircularProgress } from '@material-ui/core';
//Commomns
import InputLabel from '../../commons/Inputs/InputLabel';
import Button from '../../commons/Button';

//Actions
import {
  addCustomerPropsChanged,
  addCustomer,
  addCustomerInitialState,
  getWalletSettings,
} from '../../actions';
//Reducers
import { RootState } from '../../reducers';
//Type
import {
  AddCustomerFormProps,
  AddCustomerStateError,
} from './addCustomer.types';
import Text from '../../commons/Text';

const AddCustomer: React.FC<AddCustomerFormProps> = (props) => {
  const intl = useIntl();
  const {
    walletId,
    firstName,
    lastName,
    email,
    barcode,
    showBarcode,
    phoneNumber,
    firstNameError,
    lastNameError,
    emailError,
    barcodeError,
    emailBarcodeError,
    phoneNumberError,
    customers,
    loading,

    onSaveChanged,
  } = props;
  React.useEffect(() => {
    props.addCustomerInitialState();
    props.getWalletSettings(walletId);
    /* eslint-disable-next-line */
  }, []);
  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.addCustomerPropsChanged(event.target.name, event.target.value);
    props.addCustomerPropsChanged('firstNameError', '');
    props.addCustomerPropsChanged('lastNameError', '');
    props.addCustomerPropsChanged('emailError', '');
    props.addCustomerPropsChanged('phoneNumberError', '');
    props.addCustomerPropsChanged('barcodeError', '');
    props.addCustomerPropsChanged('emailBarcodeError', '');
  };

  const handleClickAdd = () => {
    props.addCustomer(walletId, props, () => onSaveChanged());
  };

  const getFormatedMessage = (prop: AddCustomerStateError) => {
    return props[prop] !== ''
      ? intl.formatMessage({ id: props[prop] })
      : props[prop];
  };

  function phoneInput() {
    return (
      <InputLabel
        label={intl.formatMessage({ id: 'addCustomer.label.phoneNumber' })}
        name='phoneNumber'
        value={phoneNumber}
        error={getFormatedMessage('phoneNumberError')}
        placeholder={intl.formatMessage({
          id: 'addCustomer.placeholder.phoneNumber',
        })}
        onChange={(e) => handleEventChange(e)}
      />
    );
  }

  function emailInput() {
    return (
      <InputLabel
        label={intl.formatMessage({ id: 'addCustomer.label.email' })}
        name='email'
        value={email}
        error={getFormatedMessage('emailError')}
        placeholder={intl.formatMessage({
          id: 'addCustomer.placeholder.email',
        })}
        onChange={(e) => handleEventChange(e)}
      />
    );
  }

  return (
    <Wrapper>
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <Text color='#404040' fontWeight={600} fontSize={16} lineHeight={24}>
          Ajouter Client
        </Text>
        <Button
          onClick={handleClickAdd}
          width={150}
          style={{ marginLeft: '7rem' }}
        >
          {loading ? (
            <CircularProgress size={20} color='inherit' />
          ) : (
            intl.formatMessage({ id: 'addCustomer.button.add' })
          )}
        </Button>
      </div>

      <div className='div-container'>
        <InputLabel
          label={intl.formatMessage({ id: 'addCustomer.label.firstName' })}
          name='firstName'
          value={firstName}
          error={getFormatedMessage('firstNameError')}
          placeholder={intl.formatMessage({
            id: 'addCustomer.placeholder.firstName',
          })}
          onChange={(e) => handleEventChange(e)}
        />
        <InputLabel
          label={intl.formatMessage({ id: 'addCustomer.label.lastName' })}
          name='lastName'
          value={lastName}
          error={getFormatedMessage('lastNameError')}
          placeholder={intl.formatMessage({
            id: 'addCustomer.placeholder.lastName',
          })}
          onChange={(e) => handleEventChange(e)}
        />

        {[emailInput(), phoneInput()]}
        {showBarcode && (
          <InputLabel
            label={intl.formatMessage({ id: 'addCustomer.label.barcode' })}
            name='barcode'
            value={barcode}
            error={getFormatedMessage('barcodeError')}
            placeholder={intl.formatMessage({
              id: 'addCustomer.placeholder.barcode',
            })}
            onChange={(e) => handleEventChange(e)}
          />
        )}
      </div>
      <div className='error'>{getFormatedMessage('emailBarcodeError')}</div>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  ${(props) => addCustomerStyles(props)}
`;

const mapStateToProps = ({ customer, walletDetail, settings }: RootState) => {
  const {
    firstName,
    lastName,
    email,
    barcode,
    phoneNumber,
    //errors
    firstNameError,
    lastNameError,
    emailError,
    phoneNumberError,
    barcodeError,
    emailBarcodeError,
    //loading
    loading,
  } = customer;
  const showBarcode = settings.showBarcode;
  const { customers, wallet } = walletDetail;
  return {
    firstName,
    lastName,
    email,
    phoneNumber,
    barcode,
    //errors
    firstNameError,
    lastNameError,
    emailError,
    barcodeError,
    phoneNumberError,
    emailBarcodeError,
    customers,
    wallet,
    //loading
    loading,
    showBarcode,
  };
};

export const connector = connect(mapStateToProps, {
  addCustomerPropsChanged,
  addCustomer,
  addCustomerInitialState,
  getWalletSettings,
});

export default connector(AddCustomer);
