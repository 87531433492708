import styled from 'styled-components';

export const Wrapper = styled('div')<{
  backgroundColor: any;
  mainContentTextColor: string;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-family: 'Google Sans',Roboto,Arial,sans-serif !important;
  background-color: #201f24;
  border-radius: 10px;
  //overflow-y: scroll;
  padding: 10px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  .mainSection {
    background-color: ${(props) => props.backgroundColor.hex};
    width: 100%;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    height: fit-content;
    align-items: center;
  }
  .firstSection{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
    gap: 30px;
    
  }
  .mainSection-header-logo {
      height: 50px;
      width: 50px;
      overflow: hidden;
      img {
        object-fit: contain;
        height: 50px;
        width: 50px;
      }
    }
    .mainSection-header {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    .firstSection-label {
      color: ${(props) => props.mainContentTextColor};
      font-weight: 600;
      text-transform: uppercase;
      font-size: 10px;
      letter-spacing: 0.1em;
      margin: 0px;
    }
    .firstSection-data {
      color: ${(props) => props.mainContentTextColor};
      font-size: 12px;
      font-weight: 400;
      margin: 0px;
    }
    .firstSection-Number {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .contentSection {
      display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
    gap: 30px;
    }
    .contentSection-Number {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .secondSection {
      display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
    gap: 30px;
    }
    .mainSection-label {
      color: ${(props) => props.mainContentTextColor};
      font-weight: 500;
      text-transform: uppercase;
      font-size: 18px;
      letter-spacing: 0.1em;
      margin: 0px;
    }
    .secondSection-label{
      color: ${(props) => props.mainContentTextColor};
      font-weight: 500;
      text-transform: uppercase;
      font-size: 18px;
      letter-spacing: 0.1em;
      margin: 0px;
    
    margin-left: 30px;
    
    }
    .secondSection-data {
      color: ${(props) => props.mainContentTextColor};
      font-size: 40px;
      font-weight: 300;
      margin: 0px;
    }
    .mainSection-content-barCode {
      margin-top: 133px;
      margin-bottom: 23px;
      .barCode {
        width: 270px;
        margin: auto;
        background: #ffffff;
        height: 100px;
        display: flex;
        border-radius: 3px;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        img {
          width: 235px;
          height: 58px;
          align-self: center;
          margin-top: 11px;
        }
      }
      .walletId {
        font-size: 12px;
        color: black;
        text-align: center;
        margin: 7px 0px 10px 0px;
      }
    }
    .dataSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 0px 10px 20px 10px;
    margin-top: 17px;
    background-color: #ffffff;
    .sectionIcon {
      margin-top: 20px;
      gap: 10px;
      align-items: center;
      display: flex;
      .data {
        color: #9b9b9e;
        font-weight: 500;
        font-size: 14px;
        margin: 0px;
        word-break: break-all;
      }
    }
    .sectionLabel {
      margin-top: 6px;
      margin-bottom: 6px;
      .label {
        color: #9b9b9e;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 0.075em;
        margin: 0px;
      }
      .data {
        color: #9b9b9e;
        font-weight: 500;
        font-size: 14px;
        margin: 0px;
        word-break: break-all;
      }
    }
  }
`;
