export const setInStorage = (key: any, data: any) =>
  localStorage.setItem(key, JSON.stringify(data));

export const removeFromCache = (key: any) => localStorage.removeItem(key);

export const getDataFromCache = (key: any) => {
  const data = localStorage.getItem(key);
  if (data) {
    return JSON.parse(data);
  }
  return null;
};

