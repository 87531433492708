import { Moment } from 'moment';
import { getOffersList, updateOffer } from '../apis/offersApi';

import {
  OFFERS_GET_LIST_OFFERS,
  OFFERS_PROPS_CHANGED,
  OFFERS_FILTER_OFFERS,
  OFFERS_INITIAL_STATE,
  AppThunk,
  OFFERS_UPDATE_OFFER,
  OFFERS_DESACTIVATE_OFFER,
  OFFERS_DELETE_OFFER,
  SNACKBAR_SUCCESS,
  SNACKBAR_ERROR,
} from './types';
import { OfferApiResponse } from '../apis/ApiTypes';
import {
  OffersInitialStateAction,
  OffersChangedAction,
  StatusOffersProps,
} from '../modules/Offers/offers.types';
import moment from 'moment';

export const offersPropsChanged = (
  prop: string,
  value: any
): OffersChangedAction => {
  return { type: OFFERS_PROPS_CHANGED, prop, value };
};

export const offersGetAllOffers = (walletId: string | null): AppThunk => (
  dispatch
) => {
  getOffersList(walletId).then((res: Array<OfferApiResponse>) => {
    dispatch({
      type: OFFERS_GET_LIST_OFFERS,
      payload: res,
    });
  });
};

export const offersFilterOffers = (
  status: string,
  allTime: boolean,
  startDate: Moment | null,
  endDate: Moment | null,
  offers: Array<OfferApiResponse>
): AppThunk => (dispatch) => {
  let newOffers: Array<OfferApiResponse> = Object.assign([], offers);
  const result: Array<OfferApiResponse> = [];
  let dateStart = `${moment(startDate).format('YYYY-MM-DD')}T00:00:00.000Z`;
  let dateEnd = `${moment(endDate).format('YYYY-MM-DD')}T00:00:00.000Z`;
  // let offersFilterByStatus = newOffers.filter(
  //   (item: OfferApiResponse) => item.status === status
  //   // &&moment(item.startDate).isSameOrAfter(dateStart)
  //   // &&moment(item.endDate).isSameOrBefore(dateEnd);
  // );

  if (startDate && endDate && startDate.isAfter(endDate, 'day')) {
    dispatch({
      type: OFFERS_PROPS_CHANGED,
      prop: 'endDateError',
      value: 'offer.error.endDateBeforeStartDate',
    });
  } else {
    dispatch({
      type: OFFERS_PROPS_CHANGED,
      prop: 'endDateError',
      value: '',
    });

    if (allTime) {
      if (status === 'ALL') {
        dispatch({
          type: OFFERS_FILTER_OFFERS,
          allTime,
          payload: newOffers,
        });
      } else {
        newOffers.forEach((item) => {
          if (item.status === status) {
            result.push(item);
          }
        });
        dispatch({
          type: OFFERS_FILTER_OFFERS,
          allTime,
          payload: result,
        });
      }
    } else if (startDate && endDate) {
      if (status === 'ALL') {
        newOffers.forEach((item) => {
          if (
            moment(item.startDate).isSameOrAfter(dateStart) &&
            moment(item.endDate).isSameOrBefore(dateEnd)
          ) {
            result.push(item);
          }
        });
        dispatch({
          type: OFFERS_FILTER_OFFERS,
          allTime,
          payload: result,
        });
      } else {
        newOffers.forEach((item) => {
          if (
            item.status === status &&
            moment(item.startDate).isSameOrAfter(dateStart) &&
            moment(item.endDate).isSameOrBefore(dateEnd)
          ) {
            result.push(item);
          }
        });

        dispatch({
          type: OFFERS_FILTER_OFFERS,
          allTime,
          payload: result,
        });
      }
    } else {
      if (status === 'ALL') {
        dispatch({
          type: OFFERS_FILTER_OFFERS,
          allTime,
          payload: newOffers,
        });
      } else {
        newOffers.forEach((item) => {
          if (item.status === status) {
            result.push(item);
          }
        });
        dispatch({
          type: OFFERS_FILTER_OFFERS,
          allTime,
          payload: result,
        });
      }
    }
  }
};

export const offerUpdateOffer = (
  offerId: string,
  walletId: string,
  action: string
): AppThunk => (dispatch) => {
  action = action.toUpperCase();
  updateOffer(offerId, walletId, action).then((res) => {
    if (res.status === 200) {
      if (action == 'inactif') {
        dispatch({
          type: OFFERS_DESACTIVATE_OFFER,
          status: res.data.status,
          offerId,
        });
      } else {
        dispatch({
          type: OFFERS_DELETE_OFFER,
          status: res.data.status,
          offerId,
        });
        dispatch({
          type: SNACKBAR_SUCCESS,
          value: "L'offre a été supprimé avec succès",
        });
      }
    } else {
      dispatch({
        type: SNACKBAR_ERROR,
        value: 'Une erreur est survenue',
      });
    }
  });
};

export const offersInitialState = (): OffersInitialStateAction => {
  return { type: OFFERS_INITIAL_STATE };
};
