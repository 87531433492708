import React, { Fragment, useMemo } from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
//Style
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { eventCustomersDetailStyles, useStyles } from './eventCustomers.styles';
//Material
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';

import CircularProgress from '@material-ui/core/CircularProgress';
import { TextField } from '@material-ui/core';
//Components
import UncollapsibleTable from '../../components/UncollapsibleTable';
import EmptyData from '../../components/EmptyData';
import WalletModal from '../../components/Modal';
//Actions
import {
  eventsCustomersGetAllCustomers,
  eventCustomersPropsChanged,
  eventCustomersFilterCustomers,
} from '../../actions';
//Api
import { URL_IMAGE } from '../../apis/config';

import emptyState from '../../assets/images/emptyState.svg';
import { EventsCustomersProps } from './eventCustomers.types';
import { RootState } from '../../reducers';
import Button from '../../commons/Button';
import AddCustomer from '../AddCustomer';
import Text from '../../commons/Text';
import { getDataFromCache } from '../../utils/cache';
import Input from '../../commons/Inputs/Input';

const EventCustomers: React.FC<EventsCustomersProps> = (props) => {
  const intl = useIntl();
  const classes = useStyles();
  const [selectOpener, setSelectOpener] = React.useState<boolean>(false);
  const { select, search, eventsCustomersList, loading } = props;

  const filteredList = useMemo<Array<any>>(() => {
    if (eventsCustomersList && eventsCustomersList[0]) {
      return (eventsCustomersList[0] as any).customers.filter(
        (customer: any) => {
          if (typeof customer[select] === 'string') {
            return customer[select]
              .toLocaleLowerCase()
              .match((search as string).toLocaleLowerCase());
          }
        }
      );
    } else {
      return [];
    }
  }, [search, eventsCustomersList]);
  const objectUser: any = getDataFromCache('user');

  const { eventID } = useParams<{ eventID: string }>();

  React.useEffect(() => {
    props.eventsCustomersGetAllCustomers(objectUser.wallet, eventID);
    // eslint-disable-next-line
  }, []);

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    props.eventCustomersPropsChanged('select', event.target.value);
  };

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.eventCustomersPropsChanged('search', event.target.value);
  };

  // const handleClickSearch = () => {
  //   props.eventCustomersFilterCustomers(select, search, eventsCustomersList);
  // };

  return (
    <Wrapper>
      {loading ? (
        <div className={classes.root}>
          <CircularProgress className={classes.circularProgress} />
        </div>
      ) : (
        <Fragment>
          <div className='div-header'>
            <div className='div-input-filter'>
              <FormControl className='filter-container' fullWidth>
                <Input
                  value={
                    select === 'firstName'
                      ? intl.formatMessage({ id: 'wallet.select.firstName' })
                      : select === 'lastName'
                      ? intl.formatMessage({ id: 'wallet.select.lastName' })
                      : intl.formatMessage({ id: 'wallet.select.email' })
                  }
                  type='text'
                  disabled
                />
                <div
                  className='filter-button'
                  onClick={() => setSelectOpener(!selectOpener)}
                >
                  <h3>
                    {intl.formatMessage({
                      id: 'wallet.label.filter',
                    })}
                  </h3>
                  <ExpandMoreRoundedIcon
                    style={{ color: 'white' }}
                    className={selectOpener ? 'filter-opened' : ''}
                  />
                </div>
                <Select
                  className='select-input'
                  value={select}
                  onChange={handleSelectChange}
                  placeholder={intl.formatMessage({
                    id: 'wallet.label.filter',
                  })}
                  id='filter'
                  inputProps={{ className: 'input' }}
                  open={selectOpener}
                  onClose={() => setSelectOpener(false)}
                >
                  <MenuItem value='firstName'>
                    <span>
                      {intl.formatMessage({ id: 'wallet.select.firstName' })}
                    </span>
                  </MenuItem>
                  <MenuItem value='lastName'>
                    <span>
                      {intl.formatMessage({ id: 'wallet.select.lastName' })}
                    </span>
                  </MenuItem>
                  <MenuItem value='email'>
                    <span>
                      {intl.formatMessage({ id: 'wallet.select.email' })}
                    </span>
                  </MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className='div-input-search'>
              <Input
                value={search}
                background='#fff'
                type='text'
                name='search'
                placeholder='Rechercher'
                // width={690}
                margin='10px 0'
                className='input-search'
                onChange={handleEventChange}
              />
              <Button
                onClick={() => console.log('search')}
                width={120}
                background='#FF5078'
                className='button-search'
              >
                Rechercher
              </Button>
            </div>
          </div>
          <div className='div-container'>
            <div className='table-header'>
              <Text
                color='#404040'
                fontSize={24}
                lineHeight={36}
                fontWeight={600}
              >
                Liste des invités
              </Text>
            </div>

            <EmptyData
              data={filteredList}
              loading={loading}
              message='La liste des invités est vide'
              image={emptyState}
            >
              <UncollapsibleTable
                // walletId={props.match.params.walletId}
                data={filteredList}
              ></UncollapsibleTable>
            </EmptyData>
          </div>
        </Fragment>
      )}
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  ${(props) => eventCustomersDetailStyles(props)}
`;

const mapStateToProps = ({ eventsCustomers }: RootState) => {
  const { eventsCustomersList, loading, search, select } = eventsCustomers;
  return {
    eventsCustomersList,
    search,
    select,
    loading,
  };
};

export const connector = connect(mapStateToProps, {
  eventsCustomersGetAllCustomers,
  eventCustomersPropsChanged,
  eventCustomersFilterCustomers,
});

export default connector(EventCustomers);
