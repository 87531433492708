import { combineReducers } from 'redux';
import LoginReducer from './loginReducer';
import WalletReducer from './walletReducer';
import AddCustomerReducer from './addCustomerReducer';
import OffersReducer from './offersReducer';
import OfferReducer from './offerReducer';
import UpdateGainReducer from './updateGainReducer';
import SettingsReducer from './settingsReducer';
import CouponReducer from './couponReducer';
import CouponsReducer from './couponsReducer';
import ProfileReducer from './profileReducer';
import ForgotPasswordReducer from './forgotPasswordReducer';
import ResetPasswordReducer from './resetPasswordReducer';
import eventsReducer from './eventsReducer';
import eventReducer from './eventReducer';
import eventsCustomerReducer from './eventsCustomerReducer';
import walletFormReducer from './walletFormReducer';
import statsReducer from './statsReducer';
import snackbarReducer from './snackbarReducer';
import statsDetailReducer from './statsDetailReducer';
import ShipsReducer from './shipsReducer';
import ShipReducer from './shipReducer';

const reducers = combineReducers({
  login: LoginReducer,
  walletDetail: WalletReducer,
  walletForm: walletFormReducer,
  customer: AddCustomerReducer,
  offers: OffersReducer,
  offer: OfferReducer,
  updateGain: UpdateGainReducer,
  settings: SettingsReducer,
  coupon: CouponReducer,
  coupons: CouponsReducer,
  profile: ProfileReducer,
  forgotPassword: ForgotPasswordReducer,
  resetPassword: ResetPasswordReducer,
  events: eventsReducer,
  event: eventReducer,
  eventsCustomers: eventsCustomerReducer,
  stats: statsReducer,
  snackbar: snackbarReducer,
  statsDetails: statsDetailReducer,
  ferries: ShipsReducer,
  ferry: ShipReducer,
});

export type RootState = ReturnType<typeof reducers>;

export default reducers;
