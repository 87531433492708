import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers';
import './App.css';
import { createMuiTheme } from '@material-ui/core/styles';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import MomentUtils from '@date-io/moment';
import 'moment/locale/fr';
//COMPONENTS

// MODULES
import Login from './modules/Login';
import Statistics from './modules/Statistics';
import Settings from './modules/Settings';
import Profile from './modules/Profile';
import NotFound from './modules/NotFound';
import ResetPassword from './modules/ResetPassword';
import EventInscription from './modules/EventInscription';
//ROUTES
import WalletRouter from './routes/WalletRouter';
import OffersRouter from './routes/OffersRouter';
import CouponsRouter from './routes/CouponsRouter';
import EventsRouter from './routes/EventsRouter';

//Utils
import { PrivateRoute } from './utils/Navigation';
import { removeFromCache } from './utils/cache';
import SnackBar from './modules/SnackBar';
import ShipsRouter from './routes/ShipsRouter';

export const theme = {
  primary: '#ff5078',
  secondary: '#4c15f1',
  bluePrimary: '#1A2D35',
  white: '#ffffff',
};

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const App: React.FC = (props) => {
  // removeFromCache('token');
  // removeFromCache('user');
  // removeFromCache('wallet');
  return (
    <Provider
      store={createStore(reducers, composeEnhancers(applyMiddleware(thunk)))}
    >
      <MuiPickersUtilsProvider locale='fr' utils={MomentUtils}>
        <ThemeProvider theme={theme}>
          <SnackBar />
          <Router>
            <Switch>
              <Redirect exact from='/' to='/login' />
              <Route path='/login' component={Login} exact />
              <Route
                path='/reset_password/:token'
                component={ResetPassword}
                exact
              />
              <Route
                path='/inscription/event/:token'
                component={EventInscription}
                exact
              />
              <PrivateRoute path='/statistics' component={Statistics} />
              <PrivateRoute path='/wallet/:walletId' component={WalletRouter} />
              <PrivateRoute path='/offers' component={OffersRouter} />
              <PrivateRoute path='/coupons' component={CouponsRouter} />
              <PrivateRoute path='/event' component={EventsRouter} />
              <PrivateRoute path='/Settings' component={Settings} />
              <PrivateRoute path='/profile' component={Profile} />
              <PrivateRoute path='/ships' component={ShipsRouter} />
              <Route path='/*' component={NotFound} />
            </Switch>
          </Router>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </Provider>
  );
};

export default App;
