import { css } from 'styled-components';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

export const settingsStyles = (props: any) => {
  return css`
    display: flex;
    flex-direction: column;
    /* padding: 10px; */
    .settings-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 0;
      /* padding:10px; */
    }
    .settings-header-buttons {
      display: flex;
      /* button {
        padding: 6px 12px;
        height: auto;
      } */
      justify-content: flex-end;
    }
    .sectionHeader {
      display: flex;
      justify-content: flex-end;
     // background: #ffffff;
      //box-shadow: 0px 0px 20px 2px rgb(55 55 55 / 8%);
      margin-right: 20px;
      border-radius: 20px;
    }
    .section-Header {
      background: #ffffff;
      box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.08);
      border-radius: 20px 20px 0px 0px;

      display: flex;
      align-items: center;
    }
    .settings-container {
      display: flex;
      flex-direction: column;
      
      /* padding: 10px; */
      /* margin-top: 14px; */
      .form-title {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 12px;
      }
    }
    .dropDownIcon {
      fill: #4914f4;
    }
    .fliped {
      transform: rotate(180deg);
    }
    .sectionTitle {
      display: flex;
      align-items: baseline;
      > * {
        margin-right: 15px;
      }
      .sectionButton {
        display: flex;
        align-items: center;
        > * {
          margin-right: 3px;
        }
        &:hover {
          cursor: pointer;
          > span {
            text-decoration: underline;
          }
        }
      }
    }
    .section-Title {
      display: flex;
      min-width: 340px;

      box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.08);
      border-radius: 20px 20px 0px 0px;
      justify-content: center;
      &:hover {
        cursor: pointer;

        > span {
          text-decoration: underline;
          color: #ff5078;
        }
      }
    }
    .buttons-container {
      display: flex;
      justify-content: flex-end;

      align-items: center;
      grid-gap: 10px;
    }
    .settings {
      display: flex;
      margin: 15px 0px;
      .settings-card-inscription {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: 20px;
        background: #ffffff;
        box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.08);
        margin-right: 20px;
        border-radius: 20px;
      }
      .settings-card-qrcode {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        background: #ffffff;
        box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.08);
        min-width: 160px;
        border-radius: 20px;
        justify-content: center;
      }
    }
    .div-link {
      margin: 5px;

      .label {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        color: #1a2d35;
        a {
          margin-right: 12px;
          margin-left: 6px;
        }
      }
    }
    .qrcode-cont {
      display: flex;
      align-items: center;
    }
    .copy-btn {
      background-color: #ff5078;
      border: 0;
      padding: 10px 12px;
      color: white;
      border-radius: 25px;
      font-size: 12px;
      font-weight: 500;
      cursor: pointer;
    }

    .form-control {
      /* margin: theme.spacing(1), */
      width: 300px;
      background-color: #fff;
    }
    .mail-ctnr {
      outline: groove;
      padding: 10px;
    }

    .card {
      display: flex;
      flex-direction: column;
      padding: 20px;
      background: #ffffff;
      //box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.08);
      /* align-items: center; */
      margin: 15px 0px;
      border-radius: 20px;
      /* justify-content: center; */

      .card-inputs {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        gap: 20px;
      }
      * {
        border-radius: 5px;
      }
      .rdw-editor-main {
        border: 1px solid #f1f1f1;
        height: 200px;
        padding: 0 10px;
      }
      .public-DraftStyleDefault-block {
        margin: 5px 0;
      }
      .rdw-option-wrapper {
        * {
          border-radius: unset;
        }
      }
      .editor-wrapper {
        margin-top: 15px;
      }
    }
    .radioGroup {
      display: flex;
      flex-direction: row !important;
      //margin-left: '15px';
      .MuiTypography-root {
        font-weight: 300 !important;
        font-size: 14px !important;
      }
    }
    .MuiFormControlLabel-root {
      margin-left: 11px !important;
    }
  `;
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#FFFFFF',
      boxShadow: '0px 0px 20px 2px rgba(55, 55, 55, 0.08)',
      borderRadius: '20px',
      padding: '20px',
      marginRight: '12px',
      marginBottom: '10px',
      marginTop: '10px',
    },

    table: {
      minWidth: 650,
      marginTop: '15px',
    },
  })
);
