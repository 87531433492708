import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Icon } from 'react-icons-kit';
import { ic_keyboard_arrow_down } from 'react-icons-kit/md/ic_keyboard_arrow_down';

import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import logo from '../../assets/images/logo-bestwallet-symbole.png';
import logoText from '../../assets/images/logo-bestwallet-nom.png';
import StatisticsIcon from '@material-ui/icons/EqualizerOutlined';
import {
  //wallet,
  images,
  cog,
  credit,
  ticket,
  graph,
} from 'react-icons-kit/entypo';
import walletIcon from '../../assets/images/walletIcon';
import { ic_account_circle } from 'react-icons-kit/md/ic_account_circle';
import cover from '../../assets/images/cover.png';
//styles
import { useStyles, Root } from './drawer.styles';
import { getDataFromCache, removeFromCache } from '../../utils/cache';
import { URL_IMAGE } from '../../apis/config';
import Text from '../../commons/Text';
import user from '../../assets/images/user.svg';
import WalletIcon from '../../assets/images/walletIcon';
import Bateau from '../../icons/Bateau';
import Wallet from '../../icons/Wallet';
import Ticket from '../../icons/Ticket';
import Coupon from '../../icons/Coupon';
import Event from '../../icons/Event';
import Settings from '../../icons/Settings';
import Metro from '../../icons/Metro';
import Train from '../../icons/Train';
import Bus from '../../icons/Bus';
import Avion from '../../icons/Avion';
import Hotel from '../../icons/Hotel';
import Cinema from '../../icons/Cinema';
import Stats from '../../icons/Stats';
import '../../icons/iconStyles.css';
interface DrawerProps extends RouteComponentProps {
  title?: string;
  handleClick?: () => void;
  handleClose?: () => void;
  handleLogout?: () => void;
  onClickMenu?: (path: string) => void;
  children?: React.ReactNode;
}

const SideBar: React.FC<DrawerProps> = (props) => {
  const objectUser: any = getDataFromCache('user');
  const intl = useIntl();
  const MenuList = [
    // {
    //   text: 'Statistiques',
    //   path: 'statistics',
    //   icon: <StatisticsIcon />,
    // },
    {
      text: intl.formatMessage({ id: 'drawer.menuList.stats' }),
      path: 'statistics',
      icon: <Stats />,
    },
    {
      text: intl.formatMessage({ id: 'drawer.menuList.wallet' }),
      path: `wallet/${objectUser.wallet}`,
      // icon: <Icon icon={wallet} size='24' style={{ color: '#C7C7C7' }} />,
      icon: <Wallet />,
    },
    {
      text: intl.formatMessage({ id: 'drawer.menuList.offers' }),
      path: 'offers',
      icon: <Ticket />,
    },
    {
      text: intl.formatMessage({ id: 'drawer.menuList.coupons' }),
      path: 'coupons',
      icon: <Coupon />,
    },
    {
      text: intl.formatMessage({ id: 'drawer.menuList.event' }),
      path: 'event',
      icon: <Event />,
    },
    {
      text: intl.formatMessage({ id: 'drawer.menuList.ships' }),
      path: 'ships',
      icon: <Bateau className='icon' />,
    },
    {
      text: intl.formatMessage({ id: 'drawer.menuList.bus' }),
      path: 'bus',
      icon: <Bus />,
    },
    {
      text: intl.formatMessage({ id: 'drawer.menuList.metro' }),
      path: 'metro',
      icon: <Metro />,
    },
    {
      text: intl.formatMessage({ id: 'drawer.menuList.train' }),
      path: 'train',
      icon: <Train />,
    },
    {
      text: intl.formatMessage({ id: 'drawer.menuList.cinema' }),
      path: 'cinema',
      icon: <Cinema />,
    },
    {
      text: intl.formatMessage({ id: 'drawer.menuList.avion' }),
      path: 'avion',
      icon: <Avion />,
    },
    {
      text: intl.formatMessage({ id: 'drawer.menuList.hotels' }),
      path: 'hotels',
      icon: <Hotel />,
    },
    {
      text: intl.formatMessage({ id: 'drawer.menuList.settings' }),
      path: 'settings',
      icon: <Settings />,
    },
  ];
  const currentPath = window.location.pathname;
  const currentUser = getDataFromCache('user');
  const currentWallet = getDataFromCache('wallet');
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickProfile = () => {
    props.history.push(`/profile`);
  };

  const handleLogout = () => {
    removeFromCache('token');
    removeFromCache('user');
    removeFromCache('wallet');
    props.history.push('/login');
  };

  const onClickMenu = (path: string) => {
    props.history.push(`/${path}`);
  };
  const useStylesBootstrap = makeStyles((theme: Theme) => ({
    arrow: {
      color: 'rgba(255, 80, 120, 0.5)',
    },
    tooltip: {
      backgroundColor: 'rgba(255, 80, 120, 0.5)',
      color: '#da1a47',

      maxWidth: '65px',
      height: '22px',
      marginRight: '3rem',
    },
  }));

  function BootstrapTooltip(props: TooltipProps) {
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} />;
  }

  return (
    <Root>
      <div className={classes.root}>
        <Drawer
          variant='permanent'
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <div className={classes.logoContainer}>
              <img className={classes.logoSymbole} src={logo} alt='logo' />
              <img className={classes.logoText} src={logoText} alt='logo' />
            </div>
          </div>
          <List>
            {MenuList.map((item, index) => {
              const selected = item.path
                ? currentPath.includes(item.path)
                : false;

              return (
                // eslint-disable-next-line react/jsx-key
                <BootstrapTooltip placement='right' title={item.text} arrow>
                  <ListItem
                    key={index}
                    // selected={selected}
                    button
                    onClick={() => onClickMenu(item.path)}
                    className={classes.listItem}
                    // style={{
                    //   height: '57px',
                    //   background:
                    //     'linear-gradient(60deg,rgb(72 20 244) -30%,rgb(247 90 160) 50%,rgb(254 174 110) 130%)',
                    // }}
                  >
                    <ListItemIcon
                      className={selected ? classes.svg : classes.listItemIcon}
                    >
                      {item.icon}
                    </ListItemIcon>
                  </ListItem>
                </BootstrapTooltip>
              );
            })}
          </List>
        </Drawer>
        <main className={classes.content}>
          <div>
            <div className={classes.navToolbar}>
              <div className={classes.headerSettings}>
                <div className={classes.walletCont}>
                  <div className={classes.walletLogoCont}>
                    <img
                      className={classes.walletLogo}
                      src={`${URL_IMAGE}/${currentWallet.logoPictureUrl}`}
                      alt='logo'
                    />
                  </div>

                  <Text
                    color='#404040'
                    fontSize={16}
                    fontWeight={600}
                    style={{ margin: '0px 18px' }}
                  >
                    {currentWallet.name}
                  </Text>
                </div>
                <div className={classes.pageTitle}>
                  {/* {MenuList.map((item, index) => {
                  if (currentPath.includes(item.path)) {
                    return item.text;
                  }
                })} */}
                  {props.title}
                </div>
                <div
                  className={classes.profile + ' ' + 'hoverable'}
                  onClick={handleClick}
                >
                  <div className={classes.profilePicture}>
                    {/* <Icon
                      size={36}
                      icon={ic_account_circle}
                      style={{ color: '#ffffff' }}
                    /> */}
                    <img src={user} alt={user} />
                  </div>
                  <div className={classes.accountData}>
                    {currentUser.username}
                  </div>
                  <Icon
                    size={32}
                    icon={ic_keyboard_arrow_down}
                    style={{ color: '#4914F4' }}
                  />
                </div>
                <Menu
                  id='simple-menu'
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  className={classes.menu}
                >
                  <MenuItem onClick={handleClickProfile}>
                    {intl.formatMessage({ id: 'drawer.menuItem.profile' })}
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>
                    {intl.formatMessage({ id: 'drawer.menuItem.logout' })}
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </div>
          <div style={{ padding: '0px 55px' }}>{props.children}</div>
        </main>
      </div>
    </Root>
  );
};

export default withRouter(SideBar);
