import 'date-fns';
import React from 'react';

import { useIntl } from 'react-intl';
import styled from 'styled-components';
//Material
import FormControl from '@material-ui/core/FormControl';
import emptyState from '../../assets/images/emptyState.svg';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Text from '../../commons/Text';
import Button from '../../commons/Button';
import Input from '../../commons/Inputs/Input';
import { Divider } from '@material-ui/core';
import Calendar from '../../icons/Calendar';
import { StyledCheckbox } from '../../commons/CheckBox/index';
import ShipModal from '../../components/Modal';
import { ShipsStyles, ShipsStylesProps } from './ships.styles';
import { QueuePlayNext } from '@material-ui/icons';
import EmptyData from '../../components/EmptyData';
import ShipCard from '../../components/Cards/ShipCard';
import { FerriesApiResponse } from '../../apis/ApiTypes';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import { getDataFromCache } from '../../utils/cache';
import {
  ferriesGetAllFerries,
  ferriesInitialState,
  ferriesPropsChanged,
  ferriesFilterFerries,
  editFerryConfirm,
  deleteFerry,
} from '../../actions';
import CreateShip from '../Ship';
import SimpleDialog from '../../components/Dialog';
import { FerriesStateError } from './ships.types';
import { useHistory } from 'react-router-dom';
import EditFerry from '../EditFerry';
const Ships: React.FC<any> = (props) => {
  const intl = useIntl();
  const [open, setOpen] = React.useState<boolean>(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectOpener, setSelectOpener] = React.useState<boolean>(false);
  const [ferryId, setFerryId] = React.useState('');
  const [ferryIdConfirmed, setFerryIdConfirmed] = React.useState('');
  const [EditDialogOpen, setEditDialogOpen] = React.useState(false);

  const {
    ferriesList,
    ferriesFilterList,
    status,
    startDate,
    endDate,
    allTime,
    loading,
    loadingSend,
  } = props;
  const objectUser: any = getDataFromCache('user');
  const history = useHistory();
  React.useEffect(() => {
    props.ferriesGetAllFerries(objectUser.wallet);
    /* eslint-disable-next-line */
  }, []);

  // React.useEffect(() => {
  //   return () => {
  //     props.ferriesInitialState();
  //   };
  // }, []);
  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    props.ferriesPropsChanged('status', event.target.value);
  };
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.ferriesPropsChanged(event.target.name, event.target.checked);
  };
  const handleDateChange = (prop: string) => (date: Date | null) => {
    props.ferriesPropsChanged(prop, date);
  };

  const HandleClickSearch = () => {
    props.ferriesFilterFerries(
      status,
      allTime,
      startDate,
      endDate,
      ferriesFilterList
    );
  };
  // const getFormatedMessage = (prop: FerriesStateError) => {};
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setFerryIdConfirmed('');
  };
  const handleClickSend = () => {
    props.ferriesSendFerry(objectUser.wallet, ferryId);
    setFerryIdConfirmed(ferryId);
    setOpenDialog(false);
  };
  const handleClickDeleteferry = (ferryId: string) => {
    props.deleteFerry(ferryId, objectUser.wallet);
  };
  const RedirectToCustomers = (url: string) => {
    history.push(url);
  };
  return (
    <Wrapper>
      <div className='div-search'>
        <div className='div-search-inputs'>
          <div className='div-input-filter'>
            <FormControl className='filter-container' fullWidth>
              <Input
                value={
                  status === 'originName'
                    ? intl.formatMessage({ id: 'ships.select.originName' })
                    : status === 'destinationName'
                    ? intl.formatMessage({ id: 'ships.select.destinationName' })
                    : status === 'departureTime'
                    ? intl.formatMessage({ id: 'ships.select.departureTime' })
                    : intl.formatMessage({ id: 'ships.select.arrivalTime' })
                }
                type='text'
                disabled
              />
              <div
                className='filter-button'
                onClick={() => setSelectOpener(!selectOpener)}
              >
                <h3>
                  {intl.formatMessage({
                    id: 'wallet.label.filter',
                  })}
                </h3>
                <ExpandMoreRoundedIcon
                  style={{ color: 'white' }}
                  className={selectOpener ? 'filter-opened' : ''}
                />
              </div>
              <Select
                className='select-input '
                value={status}
                onChange={handleSelectChange}
                placeholder={intl.formatMessage({
                  id: 'wallet.label.filter',
                })}
                id='filter'
                inputProps={{ className: 'input' }}
                open={selectOpener}
                onClose={() => setSelectOpener(false)}
              >
                <MenuItem
                  value='originName'
                  onMouseEnter={(e) =>
                    ((e.target as HTMLInputElement).style.color = '#ff5078')
                  }
                  onMouseLeave={(e) =>
                    ((e.target as HTMLInputElement).style.color = '#000000')
                  }
                >
                  <span>
                    {intl.formatMessage({ id: 'ships.select.originName' })}
                  </span>
                </MenuItem>
                <MenuItem
                  value='destinationName'
                  onMouseEnter={(e) =>
                    ((e.target as HTMLInputElement).style.color = '#ff5078')
                  }
                  onMouseLeave={(e) =>
                    ((e.target as HTMLInputElement).style.color = '#000000')
                  }
                >
                  <span>
                    {intl.formatMessage({ id: 'ships.select.destinationName' })}
                  </span>
                </MenuItem>
                <MenuItem
                  value='departureTime'
                  onMouseEnter={(e) =>
                    ((e.target as HTMLInputElement).style.color = '#ff5078')
                  }
                  onMouseLeave={(e) =>
                    ((e.target as HTMLInputElement).style.color = '#000000')
                  }
                >
                  <span>
                    {intl.formatMessage({ id: 'ships.select.departureTime' })}
                  </span>
                </MenuItem>
                <MenuItem
                  value='arrivalTime'
                  onMouseEnter={(e) =>
                    ((e.target as HTMLInputElement).style.color = '#ff5078')
                  }
                  onMouseLeave={(e) =>
                    ((e.target as HTMLInputElement).style.color = '#000000')
                  }
                >
                  <span>
                    {intl.formatMessage({ id: 'ships.select.arrivalTime' })}
                  </span>
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          {!allTime ? (
            <div className='div-search-date'>
              <KeyboardDatePicker
                // required
                // margin='normal'
                id='start-date'
                label={intl.formatMessage({ id: 'ships.label.startDate' })}
                format='DD/MM/yyyy'
                value={startDate}
                onChange={handleDateChange('startDate')}
                // error={startDateError !== ''}
                // helperText={getFormatedMessage('startDateError')}
                // disablePast
                inputVariant='outlined'
                style={{ width: '250px' }}
                // KeyboardButtonProps={{
                //   'aria-label': 'change date',
                // }}

                keyboardIcon={
                  <>
                    <Divider
                      className='divider'
                      orientation='vertical'
                      variant='inset'
                      light
                    />
                    <Calendar />
                  </>
                }
              />

              {/* <span style={{ width: 20 }}></span> */}
              <KeyboardDatePicker
                // required
                // margin='normal'
                id='end-date'
                label={intl.formatMessage({ id: 'ships.label.endDate' })}
                format='DD/MM/yyyy'
                inputVariant='outlined'
                value={endDate}
                onChange={handleDateChange('endDate')}
                //   error={endDateError !== ''}
                //   helperText={getFormatedMessage('endDateError')}
                // disablePast
                style={{ width: '250px' }}
                disabled={startDate === null ? true : false}
                keyboardIcon={
                  <>
                    <Divider
                      className='divider'
                      orientation='vertical'
                      variant='inset'
                      light
                    />
                    <Calendar className='icon' />
                  </>
                }
              />
            </div>
          ) : (
            <div className='div-search-date'></div>
          )}
          <FormControlLabel
            control={
              // <Checkbox
              //   checked={allTime}
              //   onChange={handleCheckboxChange}
              //   name='allTime'
              // />
              <StyledCheckbox
                checked={allTime}
                onChange={handleCheckboxChange}
                name='allTime'
              />
            }
            label={intl.formatMessage({ id: 'ships.label.allTime' })}
          />
        </div>

        <Button background='#FF5078' onClick={HandleClickSearch}>
          {intl.formatMessage({ id: 'ships.button.search' })}
        </Button>
      </div>
      <div className='div-header'>
        <Text color='#404040' fontSize={24} lineHeight={36} fontWeight={600}>
          {intl.formatMessage({ id: 'ships.label.title' })}
        </Text>
        <Button
          onClick={() => {
            setOpen(!open);
          }}
        >
          {intl.formatMessage({ id: 'ships.button.create' })}
        </Button>
      </div>
      <ShipModal open={open} onClose={() => setOpen(!open)}>
        <CreateShip
          walletId={objectUser.wallet}
          onSaveChanged={() => setOpen(!open)}
        ></CreateShip>
      </ShipModal>
      <ShipModal open={EditDialogOpen} onClose={() => setEditDialogOpen(false)}>
        <EditFerry
          walletId={objectUser.wallet}
          ferryId={ferryId}
          onCancel={() => setEditDialogOpen(false)}
          onSaveChanged={() => setEditDialogOpen(!EditDialogOpen)}
        ></EditFerry>
      </ShipModal>
      <div className='div-list'>
        <SimpleDialog
          title={intl.formatMessage({ id: 'coupons.dialog.title' })}
          text={intl.formatMessage({ id: 'coupons.dialog.text' })}
          handleAgree={handleClickSend}
          open={openDialog}
          handleClose={handleCloseDialog}
        ></SimpleDialog>
        <EmptyData
          data={ferriesList}
          loading={loading}
          message={intl.formatMessage({ id: 'ships.label.emptyList' })}
          image={emptyState}
        >
          {ferriesList.map((ferry: FerriesApiResponse) => (
            <ShipCard
              loading={loadingSend}
              key={ferry._id}
              onClickDelete={handleClickDeleteferry}
              ferry={ferry}
              ferryId={ferryIdConfirmed}
              onClickRedirect={() => history.push('/ships/' + ferry._id)}
              onClickSend={() => {
                setOpenDialog(true);
                setFerryId(ferry._id);
              }}
              onClickEdit={(id) => {
                props.editFerryConfirm(ferry);
                setEditDialogOpen(true);
                setFerryId(ferry._id);
              }}
            ></ShipCard>
          ))}
        </EmptyData>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled('div')<ShipsStylesProps>`
  ${(props) => ShipsStyles(props)}
`;
const mapStateToProps = ({ ferries }: RootState) => {
  const {
    ferriesList,
    ferriesFilterList,
    startDate,
    endDate,
    allTime,
    loading,
  } = ferries;

  return {
    ferriesList,
    ferriesFilterList,
    startDate,
    endDate,
    allTime,
    loading,
  };
};
export const connector = connect(mapStateToProps, {
  ferriesGetAllFerries,
  ferriesInitialState,
  ferriesPropsChanged,
  ferriesFilterFerries,
  editFerryConfirm,
  deleteFerry,
});
export default connector(Ships);
