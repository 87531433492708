import moment from 'moment';
import { Moment } from 'moment';
import { StatsEmailresponse, StatsSMSresponse } from '../apis/ApiTypes';
import {
  getClientStatsDetails,
  getEmailStats,
  getSMSstats,
} from '../apis/statsApi';
import { validateFieldsStats } from '../modules/Statistics/stats.utils';
import { StatsChangedAction } from '../modules/Statistics/stats.types';
import {
  AppThunk,
  STATISTICS_FORM_PROPS,
  STATISTICS_GET_CLIENTS_COUNT,
  STATISTICS_GET_EMAIL_COUNT,
  STATISTICS_GET_SMS_COUNT,
  STATISTICS_PROPS_CHANGED,
} from './types';

export const getSMSstatistics = (walletId: string): AppThunk => (dispatch) => {
  getSMSstats(walletId, null, null).then((res: StatsSMSresponse) => {
    dispatch({
      type: STATISTICS_GET_SMS_COUNT,
      walletId: walletId,
      payload: res,
    });
  });
};

export const getEmailStatistics = (walletId: string): AppThunk => (
  dispatch
) => {
  getEmailStats(walletId, null, null).then((res: StatsEmailresponse) => {
    dispatch({
      type: STATISTICS_GET_EMAIL_COUNT,
      walletId: walletId,
      payload: res,
    });
  });
};

export const statsPropsChanged = (
  prop: string,
  value: any
): StatsChangedAction => {
  return { type: STATISTICS_PROPS_CHANGED, prop, value };
};

export const StatsFilterSMS = (
  walletId: string,
  allTime: boolean,
  startDate: Moment | null,
  endDate: Moment | null
): AppThunk => (dispatch) => {
  if (!endDate && !startDate && !allTime) {
    return;
  }

  if (allTime) {
    getSMSstats(walletId, null, null).then((res: StatsSMSresponse) => {
      dispatch({
        type: STATISTICS_GET_SMS_COUNT,
        walletId: walletId,
        payload: res,
      });
    });
    getEmailStats(walletId, null, null).then((res: StatsEmailresponse) => {
      dispatch({
        type: STATISTICS_GET_EMAIL_COUNT,
        walletId: walletId,
        payload: res,
      });
    });

    getClientStatsDetails(walletId, null, null).then(
      (res: StatsSMSresponse) => {
        dispatch({
          type: STATISTICS_GET_CLIENTS_COUNT,
          walletId: walletId,
          payload: res.data,
        });
      }
    );
    return;
  }

  if (startDate && endDate) {
    let dateStart = '';
    let dateEnd = '';
    if (startDate && endDate) {
      dateStart = `${moment
        .utc(startDate.toLocaleString())
        .format('YYYY-MM-DD')}T00:00:00.000`;
      dateEnd = `${moment
        .utc(endDate.toLocaleString())
        .format('YYYY-MM-DD')}T23:59:59.000`;
    }

    const props = { endDate, startDate };
    const { newState, valid } = validateFieldsStats(props);
    dispatch({ type: STATISTICS_FORM_PROPS, newState });

    if (valid) {
      if (startDate && endDate && startDate.isAfter(endDate, 'day')) {
        dispatch({
          type: STATISTICS_PROPS_CHANGED,
          prop: 'endDateError',
          value: 'offer.error.endDateBeforeStartDate',
        });
        return;
      } else {
        dispatch({
          type: STATISTICS_PROPS_CHANGED,
          prop: 'endDateError',
          value: '',
        });
      }
      dispatch({
        type: STATISTICS_PROPS_CHANGED,
        prop: 'loadingClientsSMSStats',
        value: true,
      });
      dispatch({
        type: STATISTICS_PROPS_CHANGED,
        prop: 'loadingClientsStats',
        value: true,
      });
      dispatch({
        type: STATISTICS_PROPS_CHANGED,
        prop: 'loadingEmailStats',
        value: true,
      });
      getSMSstats(walletId, dateStart, dateEnd).then(
        (res: StatsSMSresponse) => {
          dispatch({
            type: STATISTICS_PROPS_CHANGED,
            prop: 'loadingClientsSMSStats',
            value: false,
          });
          if (res.status === 'Success') {
            dispatch({
              type: STATISTICS_GET_SMS_COUNT,
              walletId: walletId,
              payload: res,
            });
          } else if (res.status === 500 && res.data.includes('Invalid time')) {
            dispatch({
              type: STATISTICS_PROPS_CHANGED,
              prop: 'endDateError',
              value: 'Invalid time',
            });
          } else {
            console.log(res);
          }
        }
      );

      getEmailStats(walletId, dateStart, dateEnd).then(
        (res: StatsEmailresponse) => {
          dispatch({
            type: STATISTICS_PROPS_CHANGED,
            prop: 'loadingEmailStats',
            value: false,
          });
          dispatch({
            type: STATISTICS_GET_EMAIL_COUNT,
            walletId: walletId,
            payload: res,
          });
        }
      );

      getClientStatsDetails(walletId, dateStart, dateEnd).then(
        (res: StatsSMSresponse) => {
          dispatch({
            type: STATISTICS_PROPS_CHANGED,
            prop: 'loadingClientsStats',
            value: false,
          });
          dispatch({
            type: STATISTICS_GET_CLIENTS_COUNT,
            walletId: walletId,
            payload: res.data,
          });
        }
      );
    }
  }
};

export const getClientstatistics = (walletId: string): AppThunk => (
  dispatch
) => {
  dispatch({
    type: STATISTICS_PROPS_CHANGED,
    prop: 'loadingClientsStats',
    value: true,
  });
  getClientStatsDetails(walletId).then((res: StatsSMSresponse) => {
    dispatch({
      type: STATISTICS_PROPS_CHANGED,
      prop: 'loadingClientsStats',
      value: false,
    });
    dispatch({
      type: STATISTICS_GET_CLIENTS_COUNT,
      walletId: walletId,
      payload: res.data,
    });
  });
};
