import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      width: 280,
      height: 455,
      marginRight: '15px',
      background: '#FFFFFF',
      boxShadow: '0px 0px 20px 2px rgba(55, 55, 55, 0.10)',
      borderRadius: '20px',
    },
    cardContent: {
      marginTop: '5px',
    },
    media: {
      height: 185,
      borderRadius: '20px',
      border: '1px solid #CDCDCD',
      boxSizing: 'border-box',
    },
    label: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '8px',
    },

    description: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    cardDescription: {
      padding: '8px',
      //border: '1px solid #C7C7C7',
      borderRadius: '10px',
      marginTop: '8px',
      backgroundColor: '#FFCBD733',
    },
    cardMenu: {
      position: 'relative',
      height: '0px',
      left: '240px',
      top: '15px',
    },
    menuIcon: {
      fill: '#ffffff',
    },
    iconButton: {},
    activateCoupon: {
      display: 'flex',
      alignItems: 'center',
    },
  })
);
