import { createOffer, editOffer } from '../apis/offerApi';
import {
  OFFER_PROPS_CHANGED,
  OFFER_FORM_PROPS,
  OFFER_INITIAL_STATE,
  OFFERS_ADD_OFFER_TO_LIST,
  AppThunk,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
  EDIT_OFFER_INITIAL_STATE,
  OFFERS_UPDATE_OFFER,
  OFFERS_GET_LIST_OFFERS,
} from './types';
import {
  OfferPropsChangedAction,
  OfferState,
  OfferInitialStateAction,
  editOfferInitialStateAction,
} from '../modules/Offer/offer.types';
import {
  validateFieldsOffer,
  newOfferObject,
} from '../modules/Offer/offer.utils';
import moment from 'moment';
import { OfferApiResponse } from '../apis/ApiTypes';
import { URL_IMAGE } from '../apis/config';
import { offersGetAllOffers } from './offersAction';
import { getOffersList } from '../apis/offersApi';

export const offerPropsChanged = (
  prop: string,
  value: any
): OfferPropsChangedAction => {
  return { type: OFFER_PROPS_CHANGED, prop, value };
};

export const editOfferConfirm = (
  walletId: string | null,
  offerId: string | null,
  props: OfferState,
  unlimited: boolean,
  callback: () => void
): AppThunk => (dispatch) => {
  const newProps = { ...props };
  if (unlimited)
    newProps.endDate = moment('2099-01-01T20:00:00.000', 'YYYY-MM-DD');

  const { newState, valid } = validateFieldsOffer(newProps);

  dispatch({ type: OFFER_FORM_PROPS, newState });
  const newOffer = newOfferObject(newState);

  if (valid) {
    dispatch({
      type: OFFER_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });

    editOffer(walletId, offerId, newOffer)
      .then((res: any) => {
        if (res.status === 200) {
          dispatch({
            type: SNACKBAR_SUCCESS,
            value: "L'offre a été modifié avec succès",
          });

          callback();
        } else if (res.status === 400) {
          dispatch({
            type: OFFER_PROPS_CHANGED,
            prop: 'messageError',
            value: 'offer.error.datesUnavailable',
          });
        } else {
          dispatch({
            type: SNACKBAR_ERROR,
            value: 'Une erreur est survenue',
          });
        }
        dispatch({
          type: OFFER_PROPS_CHANGED,
          prop: 'loading',
          value: false,
        });
      })
      .finally(() => {
        getOffersList(walletId).then((res: Array<OfferApiResponse>) => {
          dispatch({
            type: OFFERS_GET_LIST_OFFERS,
            payload: res,
          });
        });
      });
  }
};

export const offerConfirm = (
  walletId: string | null,
  props: OfferState,
  unlimited: boolean,
  callback: () => void
): AppThunk => (dispatch) => {
  const newProps = { ...props };
  if (unlimited)
    newProps.endDate = moment('2099-01-01T20:00:00.000', 'YYYY-MM-DD');

  const { newState, valid } = validateFieldsOffer(newProps);
  dispatch({ type: OFFER_FORM_PROPS, newState });
  const newOffer = newOfferObject(newState);

  if (valid) {
    dispatch({
      type: OFFER_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });

    createOffer(walletId, newOffer).then((res: any) => {
      if (res.status === 201) {
        dispatch({
          type: OFFERS_ADD_OFFER_TO_LIST,
          payload: res.data,
        });
        dispatch({
          type: SNACKBAR_SUCCESS,
          value: "L'offre a été ajouté avec succès",
        });
        dispatch({ type: OFFER_INITIAL_STATE });
        callback();
      } else if (res.status === 400) {
        dispatch({
          type: OFFER_PROPS_CHANGED,
          prop: 'messageError',
          value: 'offer.error.datesUnavailable',
        });
        dispatch({
          type: SNACKBAR_ERROR,
          value: 'Une erreur est survenue',
        });
      }
      dispatch({
        type: OFFER_PROPS_CHANGED,
        prop: 'loading',
        value: false,
      });
    });
  }
};

export const offerInitialState = (): OfferInitialStateAction => {
  return { type: OFFER_INITIAL_STATE };
};
export const editOfferInitialState = (
  offer: OfferApiResponse
): editOfferInitialStateAction => {
  return {
    type: EDIT_OFFER_INITIAL_STATE,
    prop: 'payload',
    value: {
      ...offer,
      unlimited:
        new Date(offer.endDate).getFullYear() ===
        new Date('2099-01-01T01:00:00').getFullYear(),
      image: URL_IMAGE + '/' + offer.offerPictureUrl,
      startDate: moment(offer.startDate),
      endDate: moment(offer.endDate),
    },
  };
};
