import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='45'
      height='45'
      fill='none'
      viewBox='0 0 54 45'
    >
      <path
        fill='#691FDE'
        fillRule='evenodd'
        d='M18.011.039C11.551.861 6.53 5.469 5.157 11.831c-.295 1.369-.295 4.39 0 5.759.624 2.896 2.01 5.47 4.027 7.48l.975.97-.768.391C3.79 29.285.105 35.083.005 41.202c-.018 1.133 0 1.244.3 1.754a2.429 2.429 0 003.825.499c.533-.533.75-1.128.75-2.05 0-.348.08-1.094.176-1.656.451-2.623 1.58-4.813 3.444-6.676 1.854-1.855 4.065-2.995 6.675-3.444 1.447-.248 7.45-.248 8.897 0 2.625.452 4.828 1.588 6.672 3.442 1.884 1.895 2.984 4.04 3.454 6.73.093.534.17 1.262.17 1.618 0 .962.186 1.474.731 2.019a2.428 2.428 0 003.843-.482c.3-.51.319-.621.3-1.754-.08-4.948-2.552-9.828-6.576-12.983-.986-.772-3.182-2.106-3.469-2.106-.09 0 .327-.505.926-1.123 2.285-2.356 3.613-5.028 4.08-8.206C35.316 9.183 30.343 2.036 22.733.3c-.96-.22-3.813-.378-4.722-.262zm27.515 7.622c-.448.233-.71.474-.962.88-.332.538-.347.619-.389 2.155l-.044 1.596h-1.523c-1.373 0-1.575.026-2.032.268-.28.147-.663.488-.853.758-.312.443-.346.577-.346 1.383 0 .786.038.947.315 1.344.173.248.506.58.74.739.395.268.538.29 2.062.325l1.638.037.043 1.576c.04 1.474.064 1.612.361 2.12a2.428 2.428 0 003.824.498c.614-.614.75-1.125.75-2.805v-1.389l1.66-.037c1.639-.037 1.668-.042 2.16-.388 1.588-1.116 1.367-3.517-.391-4.251-.311-.13-.833-.178-1.928-.178h-1.5v-1.398c0-1.686-.137-2.198-.747-2.809-.798-.798-1.829-.952-2.838-.424z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}

export default Icon;
