import moment, { Moment } from 'moment';
import { getFerriesList } from '../apis/ferriesApi';
import { removeFerry } from '../apis/ferryApi';

import {
  FERRIES_GET_LIST_FERRIES,
  FERRIES_INITIAL_STATE,
  AppThunk,
  FERRIES_PROPS_CHANGED,
  FERRIES_FILTER_FERRIES,
  FERRIES_DELETE_FERRY_LOADING,
  FERRIES_DELETE_FERRY,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
} from './types';
import { FerriesApiResponse } from '../apis/ApiTypes';
import {
  FerriesChangedAction,
  FerriesInitialStateAction,
} from '../modules/Ships/ships.types';

export const ferriesGetAllFerries = (walletId: string): AppThunk => (
  dispatch
) => {
  getFerriesList(walletId).then((res: Array<FerriesApiResponse>) => {
    dispatch({
      type: FERRIES_GET_LIST_FERRIES,
      payload: res,
    });
  });
};

export const ferriesPropsChanged = (
  prop: string,
  value: any
): FerriesChangedAction => {
  return { type: FERRIES_PROPS_CHANGED, prop, value };
};

export const ferriesFilterFerries = (
  status: string,
  allTime: boolean,
  startDate: Moment | null,
  endDate: Moment | null,
  ferries: Array<FerriesApiResponse>
): AppThunk => (dispatch) => {
  let newFerries: Array<FerriesApiResponse> = Object.assign([], ferries);
  const result: Array<FerriesApiResponse> = [];
  let dateStart = `${moment(startDate).format('YYYY-MM-DD')}T00:00:00.000Z`;
  let dateEnd = `${moment(endDate).format('YYYY-MM-DD')}T00:00:00.000Z`;
  // let offersFilterByStatus = newFerries.filter(
  //   (item: OfferApiResponse) => item.status === status
  //   // &&moment(item.startDate).isSameOrAfter(dateStart)
  //   // &&moment(item.endDate).isSameOrBefore(dateEnd);
  // );

  if (startDate && endDate && startDate.isAfter(endDate, 'day')) {
    dispatch({
      type: FERRIES_PROPS_CHANGED,
      prop: 'endDateError',
      value: 'offer.error.endDateBeforeStartDate',
    });
  } else {
    dispatch({
      type: FERRIES_PROPS_CHANGED,
      prop: 'endDateError',
      value: '',
    });

    if (allTime) {
      if (status === 'ALL') {
        dispatch({
          type: FERRIES_FILTER_FERRIES,
          allTime,
          payload: newFerries,
        });
      } else {
        newFerries.forEach((item) => {
          if (item.status === status) {
            result.push(item);
          }
        });
        dispatch({
          type: FERRIES_FILTER_FERRIES,
          allTime,
          payload: result,
        });
      }
    } else if (startDate && endDate) {
      if (status === 'ALL') {
        newFerries.forEach((item) => {
          if (
            moment(item.departureTime).isSameOrAfter(dateStart) &&
            moment(item.arrivalTime).isSameOrBefore(dateEnd)
          ) {
            result.push(item);
          }
        });
        dispatch({
          type: FERRIES_FILTER_FERRIES,
          allTime,
          payload: result,
        });
      } else {
        newFerries.forEach((item) => {
          if (
            item.status === status &&
            moment(item.departureTime).isSameOrAfter(dateStart) &&
            moment(item.arrivalTime).isSameOrBefore(dateEnd)
          ) {
            result.push(item);
          }
        });

        dispatch({
          type: FERRIES_FILTER_FERRIES,
          allTime,
          payload: result,
        });
      }
    } else {
      if (status === 'ALL') {
        dispatch({
          type: FERRIES_FILTER_FERRIES,
          allTime,
          payload: newFerries,
        });
      } else {
        newFerries.forEach((item) => {
          if (item.status === status) {
            result.push(item);
          }
        });
        dispatch({
          type: FERRIES_FILTER_FERRIES,
          allTime,
          payload: result,
        });
      }
    }
  }
};

export const deleteFerry = (ferryId: string, walletId: string): AppThunk => (
  dispatch
) => {
  dispatch({
    type: FERRIES_DELETE_FERRY_LOADING,
    payload: true,
  });
  removeFerry(ferryId, walletId).then((res) => {
    if (res.data.status === 'success') {
      dispatch({
        type: FERRIES_DELETE_FERRY,
        payload: ferryId,
      });
      dispatch({
        type: SNACKBAR_SUCCESS,
        value: 'Le Transit a été supprimé avec succès',
      });
    } else
      dispatch({
        type: SNACKBAR_ERROR,
        value: 'Une erreur est survenue',
      });
  });
};

export const ferriesInitialState = (): FerriesInitialStateAction => {
  return { type: FERRIES_INITIAL_STATE };
};
