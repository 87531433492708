import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import * as serviceWorker from './serviceWorker';
import * as dotenv from 'dotenv';

//Transaltion
import { IntlProvider } from 'react-intl';
import enMessages from '../src/translation/messages.en';
import frMessages from '../src/translation/messages.fr';
import deMessages from '../src/translation/messages.de';
dotenv.config();
// localStorage.removeItem('language');
// localStorage.removeItem('token');
// localStorage.removeItem('user');
const locale = localStorage.getItem('language') || 'fr';

const messages: any = {
  en: enMessages,
  fr: frMessages,
  de: deMessages,
};

ReactDOM.render(
  <IntlProvider locale={locale} messages={messages[`${locale}`]}>
    <App />
  </IntlProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// import messages_en from './messages.en';
// import messages_fr from './messages.fr';
// import messages_de from './messages.de';

// export default {
//   en: messages_en,
//   fr: messages_fr,
//   de: messages_de,
// };
