import { css } from 'styled-components';

export type ShipsStylesProps = {
  // loading: string;
  //shipsLength: number;
};

export const ShipsStyles = (props: any) => {
  let flex = 0;
  let justifyContent = 'flex-start';
  if (props.loading === 'true') {
    flex = 1;
    justifyContent = 'center';
  } else if (props.shipsLength === 0 && props.loading === 'false') {
    flex = 1;
    justifyContent = 'center';
  }
  return css`
    display: flex;
    flex-direction: column;
    .div-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 15px;
    }

    .div-input-filter {
      min-width: 400px;
      margin-right: 15px;
    }

    .filter-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      > input {
        background-color: #fff;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #404040;
        box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.08);
        border-radius: 15px;
      }
    }
    .MuiOutlinedInput-adornedEnd {
      background-color: #fff;
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #404040;
      box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.08);
      border-radius: 15px;
    }
    .MuiIconButton-root.Mui-disabled {
      color: rgba(0, 0, 0, 0.38) !important;
    }
    .MuiCheckbox-root {
      color: #ff5078;
    }
    .MuiIconButton-root {
      color: #ff5078;
    }

    .filter-button {
      display: flex;
      position: absolute;
      left: 290px;
      width: 100px;
      align-items: center;
      justify-content: space-between;
      background: #691fde;
      height: 46px;
      box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.08);
      border-radius: 15px;
      padding: 0 10px;
      transition: all 0.3s ease;
      :hover {
        cursor: pointer;
        background: #6438f5;
      }
      > h3 {
        /* font-family: Poppins; */
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: white;
        width: 80px;
      }
    }
    .filter-opened {
      transform: rotate(180deg);
    }

    .select-input {
      width: 150px;
      visibility: hidden;
      position: absolute;
      left: 260px;
      top: 70px;
    }
    .div-search {
      display: flex;
      align-items: center;
      margin-top: 30px;
      margin-bottom: 15px;
      /* width: 100%; */
      .div-search-inputs {
        display: flex;
        flex: 1;
      }

      .div-search-date {
        display: flex;
        margin: 0 30px;
        gap: 15px;
        /* position: relative; */
        /* .div-checkbox {
          position: absolute;
          bottom: -35px; */
      }
    }
    .div-input-search {
      flex: 1;
      position: relative;
      margin-left: 15px;
      min-width: 450px;
      .input-search {
        box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.08);
        border-radius: 15px;
      }
      .button-search {
        position: absolute;
        top: 12px;
        right: 4px;
        margin: 0;
        height: 42px;
        border-radius: 15px;
      }
    }

    .divider {
      height: 28px;
      width: 2px;
      margin: 4px;
      margin-right: 15px;
      background: #404040;
    }
    .MuiDivider-root {
      background-color: #c7c7c7 !important;
    }
    .icon {
      svg path {
        fill: #ff5078;
      }
    }
    .div-list {
      margin: 15px 0;
      display: flex;
      flex: ${flex};
      justify-content: ${justifyContent};
      flex-wrap: wrap;
      /* min-height: 500px; */
      align-items: center;
      gap: 10px;
    }
  `;
};
