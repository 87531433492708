import { ThunkAction } from 'redux-thunk';
import { RootState } from '../reducers';
import { Action } from 'redux';

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

//LOGIN
export const LOGIN_FORM_PROPS_CHANGED = 'login_form_props_changed';
export const LOGINING_SUCCESS = 'logining_success';
export const LOGINING_FAILED = 'logining_failed';
export const LOGINING_INITIAL_STATE = 'logining_initial_state';

//WALLET FORM
export const WALLET_FORM_PROPS_CHANGED = 'wallet_form_props_changed';
export const WALLET_FORM_WALLET = 'wallet_form_wallet';
export const WALLETS_ADD_WALLET_TO_LIST = 'wallets_add_wallet_to_list';
export const WALLET_FORM_INITIAL_STATE = 'wallet_form_initial_state';
export const WALLETS_UPDATE_WALLET_TO_LIST = 'wallets_update_wallet_to_list';
export const WALLETS_SET_WALLET = 'wallets_set_wallet';

//WALLET DETAIL
export const WALLET_GET_WALLET_DETAIL = 'wallet_get_wallet_detail';
export const WALLET_INITIAL_STATE = 'wallet_initial_state';
export const WALLET_GET_WALLET_DETAIL_FAILED =
  'wallet_get_wallet_detail_failed';
export const WALLET_GET_WALLET_CUSTOMER_STATS =
  'wallet_get_wallet_customer_stats';

export const WALLET_GET_CUSTOMER_HISTORY = 'wallet_get_customer_history';
export const WALLET_LOADING_HISTORY = 'wallet_loading_history';
export const WALLET_PROPS_CHANGED = 'wallet_props_changed';
export const WALLET_FILTER_CUSTOMERS = 'wallet_filter_customers';
export const WALLET_DELETE_CUSTOMER = 'wallet_delete_customer';

//ADD CUSTOMER

export const ADD_CUSTOMER_PROPS_CHANGED = 'add_customer_props_changed';
export const ADD_CUSTOMER_FORM_CUSTOMER = 'add_customer_form_customer';
export const ADD_CUSTOMER_TO_CUSTOMERS_LIST = 'add_customer_to_customers_list';
export const ADD_CUSTOMER_INITIAL_STATE = 'add_customer_initial_state';

//OFFERS
export const OFFERS_GET_LIST_OFFERS = 'offers_get_list_offers';
export const OFFERS_PROPS_CHANGED = 'offers_props_changed';
export const OFFERS_ADD_OFFER_TO_LIST = 'offers_add_offer_to_list';
export const OFFERS_FILTER_OFFERS = 'offers_filter_offers';
export const OFFERS_INITIAL_STATE = 'offers_initial_state';
export const OFFERS_UPDATE_OFFER_LOADING = 'offers_update_offer_loading';
export const OFFERS_UPDATE_OFFER = 'offers_update_offer';
export const OFFERS_DELETE_OFFER = 'offers_delete_offer';

export const OFFERS_DESACTIVATE_OFFER = 'offers_desactivate_offer';

//OFFER
export const OFFER_PROPS_CHANGED = 'offer_props_changed';
export const OFFER_FORM_PROPS = 'offer_form_props';
export const OFFER_INITIAL_STATE = 'offer_initial_state';
export const EDIT_OFFER_INITIAL_STATE = 'edit_offer_initial_state';

//UPDATE GAIN
export const UPDATE_GAIN_PROPS_CHANGED = 'update_gain_props_changed';
export const UPDATE_GAIN_TO_CUSTOMERS_LIST = 'update_gain_to_customers_list';
export const UPDATE_GAIN_INITIAL_STATE = 'update_gain_initial_state';

//SETTINGS
export const SETTINGS_PROPS_CHANGED = 'settings_props_changed';
export const SETTINGS_GET_WALLET_SETTINGS = 'settings_get_wallet_settings';
export const SETTINGS_GET_WALLET_DETAIL_FAILED =
  'settings_get_wallet_detail_failed';
export const SETTINGS_GET_WALLET_DETAIL = 'settings_get_wallet_detail';
export const SETTINGS_INITIAL_STATE = 'settings_initial_state';
export const SETTINGS_GET_WALLET_FORM = 'settings_get_wallet_form';
//COUPONS
export const COUPONS_GET_SENT_COUPONS = 'coupons_get_sent_coupons';
export const COUPONS_GET_LIST_COUPONS = 'coupons_get_list_coupons';
export const COUPONS_ADD_COUPON_TO_LIST = 'coupons_add_coupon_to_list';
export const COUPONS_INITIAL_STATE = 'coupons_initial_state';
export const COUPONS_DELETE_COUPON_LOADING = 'coupons_delete_coupon_loading';
export const COUPONS_PROPS_CHANGED = 'coupons_props_changed';
export const UPDATE_SENT_COUPONS_LIST = 'update_sent_coupons_list';
export const COUPONS_DELETE_COUPON = 'coupons_delete_coupon';
export const COUPONS_ERROR = 'coupons_error';
export const COUPONS_UPDATE_DIALOG_MESSAGE = 'coupons_update_dialog_message';
export const COUPONS_UPDATE_COUPON_STATUS = 'coupons_update_coupon_message';
export const COUPONS_UPDATE_PROPS = 'coupons_update_props';
//EVENTS
export const EVENTS_GET_LIST_EVENTS = 'events_get_list_events';
export const EVENTS_INITIAL_STATE = 'events_initial_state';
export const EVENTS_PROPS_CHANGED = 'events_props_changed';
export const EVENTS_FILTER_EVENTS = 'events_filter_events';
export const EVENT_FORM_PROPS_CHANGED = 'event_form_props_changed';
export const EVENT_FORM_PROPS = 'event_form_props';
export const EVENTS_ADD_EVENT_TO_LIST = 'events_add_event_to_list';
export const EVENT_INITIAL_STATE = 'event_initial_state';
export const EVENTS_CUSTOMERS_INITIAL_STATE = 'events_customers_initial_state';
export const EVENTS_GET_CUSTOMERS_LIST = 'events_get_customers_list';
export const EVENT_CUSTOMERS_PROPS_CHANGED = 'event_customers_props_changed';
export const EVENT_FILTER_CUSTOMERS = 'event_filter_customers';
export const EVENTS_DELETE_EVENT_LOADING = 'events_delete_event_loading';
export const EVENTS_DELETE_EVENT = 'events_delete_event';
export const EVENT_GET_EVENT_PROPS = 'event_customers_props_changed';

//COUPON
export const COUPON_PROPS_CHANGED = 'coupon_props_changed';
export const COUPON_FORM_PROPS = 'coupon_form_props';
export const COUPON_INITIAL_STATE = 'coupon_initial_state';
export const EDIT_COUPON_INITIAL_STATE = 'edit_coupon_initial_state';
//PROFILE
export const PROFILE_PROPS_CHANGED = 'profile_props_changed';
export const PROFILE_VALIDATION_PROPS = 'profile_validation_props';
export const PROFILE_INITIAL_STATE = 'profile_initial_state';
export const PROFILE_GET_WALLET_EMAIL_SETTINGS =
  'profile_get_wallet_email_settings';
export const PROFILE_EMAIL_SETTINGS = 'profile_email_settings';

//FORGOT PASSWORD
export const FORGOT_PASSWORD_PROPS_CHANGED = 'forgot_password_props_changed';
export const FORGOT_PASSWORD_INITIAL_STATE = 'forgot_password_initial_state';

//RESET PASSWORD
export const RESET_PASSWORD_PROPS_CHANGED = 'reset_password_props_changed';
export const RESET_PASSWORD_VALIDATION_PROPS =
  'reset_password_validation_props';
export const RESET_PASSWORD_INITIAL_STATE = 'reset_password_initial_state';
export const RESET_PASSWORD_VERIFICATION_TOKEN_FAILED =
  'reset_password_verification_token_failed';
export const RESET_PASSWORD_VERIFICATION_TOKEN_SUCCESS =
  'reset_password_verification_token_success';

//STATISTICS
export const STATISTICS_INITIAL_STATE = 'statistics_initial_state';
export const STATISTICS_GET_SMS_COUNT = 'statistics_get_sms_count';
export const STATISTICS_GET_EMAIL_COUNT = 'statistics_get_email_count';

export const STATISTICS_GET_CLIENTS_COUNT = 'statistics_get_clients_count';

export const STATISTICS_PROPS_CHANGED = 'statistics_props_changed';
export const STATISTICS_FILTER_SMS_STATS = 'statistics_filter_sms_stats';
export const STATISTICS_FORM_PROPS = 'statistics_form_props';

//STATISTICS_DETAILS
export const STATISTICS_GET_STATS_DETAIL = 'statistics_get_stats_detail';
export const STATISTICS_DETAILS_PROPS_CHANGED =
  'statistics_details_props_changed';

//Ferry
//Ferries Transits
export const FERRIES_GET_LIST_FERRIES = 'ferries_get_list_ferries';
export const FERRIES_INITIAL_STATE = 'ferries_initial_state';
export const FERRIES_PROPS_CHANGED = 'ferries_props_changed';
export const FERRIES_FILTER_FERRIES = 'ferries_filter_ferries';
export const FERRY_FORM_PROPS_CHANGED = 'ferry_form_props_changed';
export const FERRY_FORM_PROPS = 'ferry_form_props';
export const FERRIES_ADD_FERRY_TO_LIST = 'ferries_add_ferry_to_list';
export const FERRY_INITIAL_STATE = 'ferry_initial_state';
export const FERRIES_CUSTOMERS_INITIAL_STATE =
  'ferries_customers_initial_state';
export const FERRIES_GET_CUSTOMERS_LIST = 'ferries_get_customers_list';
export const FERRY_CUSTOMERS_PROPS_CHANGED = 'ferry_customers_props_changed';
export const FERRY_FILTER_CUSTOMERS = 'ferry_filter_customers';
export const FERRIES_DELETE_FERRY_LOADING = 'ferries_delete_ferry_loading';
export const FERRIES_DELETE_FERRY = 'ferries_delete_ferry';
export const FERRY_GET_FERRY_PROPS = 'ferry_customers_props_changed';
//SNACKBAR
export const SNACKBAR_SUCCESS = 'snackbar_success';
export const SNACKBAR_ERROR = 'snackbar_error';
export const SNACKBAR_INFO = 'snackbar_info';
export const SNACKBAR_CLEAR = 'snackbar_clear';
