import React from 'react';
import { Wrapper } from './wireframe.style';

import { ellipses } from 'react-icons-kit/iconic/ellipses';
import { ratio } from 'wcag-color';
import barcode from '../../assets/images/barcode.png';

import defaultLogo from '../../assets/images/sm-thumbnail.png';
import cover from '../../assets/images/lg-thumbnail.png';

import Icon from 'react-icons-kit';

import moment from 'moment';

import {
  additionalContacts,
  description,
} from '../walletForm/walletForm.types';

type WireframeProps = {
  backgroundColor: any;
  name: any;
  logo: any;
  strip: any;
  address: any;
  descriptions: any;
  openingHours: any;
  additionalContacts: any;
  titleColor: any;
  textColor: any;
  facebook: any;
  linkedin: any;
  website: any;
  businessPhone: any;
  servicePhone: any;
  instagram: any;
  gainUnit: string;
};

const WireframeIPhone: React.FC<WireframeProps> = (props) => {
  const {
    backgroundColor,
    logo,
    name,
    strip,
    address,
    descriptions,
    openingHours,
    titleColor,
    textColor,
    additionalContacts,
    facebook,
    linkedin,
    website,
    businessPhone,
    servicePhone,
    instagram,
    gainUnit,
  } = props;

  return (
    <Wrapper
      backgroundColor={backgroundColor}
      mainContentTextColor={
        ratio('#ffffff', backgroundColor.hex) > 3.0 ? '#ffffff' : '#000000'
      }
      titleColor={titleColor.hex}
      textColor={textColor.hex}
    >
      {' '}
      <div className='mainSection-header-more-logo'>
        <h2>Wallet</h2>
        <Icon size={18} icon={ellipses} style={{ color: '#ffffff' }} />
      </div>
      <div className='mainSection'>
        <div className='mainSection-header'>
          <div className='mainSection-header-logoSection'>
            <img
              className='mainSection-header-logo'
              src={
                !logo
                  ? defaultLogo
                  : typeof logo === 'string'
                  ? logo
                  : URL.createObjectURL(logo)
              }
              alt='wallet Logo'
            />
            <div className='mainSection-content-enseigne'>
              <h4>{!name ? '"Enseigne"' : name}</h4>
            </div>
            <div>
              <h3 className='mainSection-label'>
                0.00 {gainUnit !== 'points' ? gainUnit : ''}
              </h3>
              <h3 className='mainSection-data'>Silver</h3>
            </div>
          </div>
        </div>

        <div className='mainSection-content-banner'>
          <img
            src={
              !strip
                ? cover
                : typeof strip === 'string'
                ? strip
                : URL.createObjectURL(strip)
            }
            alt='banner'
          />
        </div>
        <div className='mainSection-content-datePoints'>
          <div className='date'>
            <h3 className='mainSection-label'>Date</h3>
            <h3 className='mainSection-data'>{moment().format('L')}</h3>
          </div>
          <div className='points'>
            <h3 className='mainSection-label'>Gains</h3>
            <h3 className='mainSection-data'>
              0.00 {gainUnit !== 'points' ? gainUnit : ''}
            </h3>
          </div>
        </div>
        <div className='mainSection-content-barCode'>
          <div className='barCode'>
            <img src={barcode} alt='barcode' />
            <p className='walletId'>5g1hdvb82154df853fca14835</p>
          </div>
        </div>
      </div>
      <div className='dataSection'>
        {descriptions.map((elm: description, index: number) => (
          <div className='sectionLabel' key={index}>
            <h3 className='label'>
              {elm.title ? elm.title : '"Description Title"'}
            </h3>
            <h3 className='data'>{elm.value ? elm.value : '"Value"'}</h3>
          </div>
        ))}
        <span className='divider'></span>
        <div className='sectionLabel'>
          <h3 className='label'>Nom du client</h3>
          <h3 className='data'>Prenom du client</h3>
        </div>
        <span className='divider'></span>
        <div className='sectionLabel'>
          <h3 className='label'>type</h3>
          <h3 className='data'>Silver</h3>
        </div>
        <span className='divider'></span>
        <div className='sectionLabel'>
          <h3 className='label'>Date</h3>
          <h3 className='data'>{moment().format('L HH:mm')}</h3>
        </div>{' '}
        <span className='divider'></span>
        <div className='sectionLabel'>
          <h3 className='label'>Adresse</h3>
          <h3 className='data'>{address ? address : '"Adresse"'}</h3>
        </div>{' '}
        <span className='divider'></span>
        <div className='sectionLabel'>
          <h3 className='label'>Horaires</h3>
          <h3 className='data'>{openingHours ? openingHours : '"Horaires"'}</h3>
        </div>{' '}
        <span className='divider'></span>
        <div className='sectionLabel'>
          <h3 className='label'>Code a barres</h3>
          <h3 className='data'>6066e4315abbcc1f9526d392</h3>
        </div>{' '}
        <span className='divider'></span>
        <div className='sectionLabel'>
          <h3 className='label'>Offres</h3>
          <h3 className='data'>Pas d&apos;offres</h3>
        </div>{' '}
        <span className='divider'></span>
        <div className='sectionLabel'>
          <h3 className='label'>Coupons</h3>
          <h3 className='data'>Pas de coupons</h3>
        </div>{' '}
        <span className='divider'></span>
        <div className='sectionLabel'>
          <h3 className='label'> {name} vous répond a :</h3>
          <h3 className='data'>Téléphone Commercial : {businessPhone}</h3>
          <h3 className='data'>Téléphone Serivce: {servicePhone}</h3>
        </div>
        {website && <span className='divider'></span>}
        {website && (
          <div className='sectionLabel'>
            <h3 className='label'>Site web</h3>
            <h3 className='data'>{website}</h3>
          </div>
        )}
        {(linkedin || facebook || instagram) && (
          <span className='divider'></span>
        )}
        {(linkedin || facebook || instagram) && (
          <div className='sectionLabel'>
            <h3 className='label'>Réseau sociaux</h3>
            {facebook && <h3 className='data'>Facebook : {facebook}</h3>}
            {linkedin && <h3 className='data'>Linkedin : {linkedin}</h3>}
            {instagram && <h3 className='data'>Instagram : {instagram}</h3>}
          </div>
        )}
        <span className='divider'></span>
        {additionalContacts.length >= 1 && additionalContacts[0].title !== '' && (
          <div className='sectionLabel'>
            {additionalContacts.map(
              (elm: additionalContacts, index: number) => (
                <h3 className='label'>
                  {elm.title} : {elm.value}
                </h3>
              )
            )}
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default WireframeIPhone;
