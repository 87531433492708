import React, { Dispatch, SetStateAction } from 'react';
import { useIntl } from 'react-intl';
import Card from '@material-ui/core/Card';

import CardContent from '@material-ui/core/CardContent';

import Typography from '@material-ui/core/Typography';

//Styles
import {
  childStyles,
  crmStyles,
  externesStyles,
  internesStyles,
  useStyles,
} from './statsCard.styles';
import styled from 'styled-components';

import CircularProgress from '@material-ui/core/CircularProgress';
import CountUp from 'react-countup';
import { StatsDetailsFilterSMS } from '../../../actions';
import { connect } from 'react-redux';
import { CardActionArea } from '@material-ui/core';
import clsx from 'clsx';
interface IProps {
  isTypeSms?: boolean;
  setIsTypeSms?: Dispatch<SetStateAction<boolean>>;
  isTypeClient?: boolean;
  setIsTypeClient?: Dispatch<SetStateAction<boolean>>;
  isTypeEmail?: boolean;
  setIsTypeEmail?: Dispatch<SetStateAction<boolean>>;
  title?: any;
  count?: any;
  walletId?: any;
  startDate?: any;
  endDate?: any;
  allTime?: any;
  type?: any;
  icon?: any;
  style?: any;
  className?: any;
  StatsDetailsFilterSMS?: any;
}
const StatsCard: React.FC<any> = (props: IProps) => {
  const intl = useIntl();
  const classes = useStyles();
  const reclasses = childStyles();
  const internes = internesStyles();
  const externes = externesStyles();
  const crm = crmStyles();
  const {
    title,
    count,
    walletId,
    startDate,
    endDate,
    allTime,
    type,
    icon,
    style,
    className,
    isTypeSms,
    isTypeEmail,
    isTypeClient,
  } = props;
  const [is_loading, setIsLoading] = React.useState<any>(null);

  const HandleClickExpand = () => {
    if (type == 'client' || type == 'email') return;
    props.StatsDetailsFilterSMS(walletId, allTime, startDate, endDate, type);
  };
  const renderEmailCard = () => {
    return (
      <>
        <div
          className={`${
            ['total'].includes(title) ? `${reclasses.totalCard}` : ''
          }`}
        >
          <div
            className={`${
              ['total'].includes(title)
                ? `${reclasses.emailContainer}`
                : `${classes.emailContainer}`
            }`}
          >
            <div
              className={`${
                ['total'].includes(title)
                  ? `${reclasses.emailCircle}`
                  : `${classes.emailCircle}`
              }`}
            >
              <CountUp end={count} duration={1} />
            </div>
            <div
              onClick={HandleClickExpand}
              className={`${
                ['total'].includes(title)
                  ? `${reclasses.Container}`
                  : `${classes.Container}`
              }`}
            >
              <Typography
                variant='subtitle2'
                //component='p'
                align='center'
                gutterBottom
              >
                {title}
              </Typography>
            </div>
          </div>
        </div>
      </>
    );
  };
  const renderClientCard = () => {
    return (
      <>
        <div
          className={`${
            ['Total des clients'].includes(title) ? classes.clientTotalCard : ''
          }`}
          style={style}
        >
          <div
            className={`${
              ['Total des clients'].includes(title)
                ? `${reclasses.clientContainer}`
                : `${classes.clientContainer}`
            }`}
          >
            <div
              className={clsx(
                `${
                  ['Total des clients'].includes(title)
                    ? `${reclasses.clientRect}`
                    : `${classes.clientRect}`
                }`,
                `${
                  ['Internes'].includes(title)
                    ? `${externes.clientRect}`
                    : `${classes.clientRect}`
                }`,
                `${
                  ['Externes'].includes(title)
                    ? `${internes.clientRect}`
                    : `${classes.clientRect}`
                }`,
                `${
                  ['CRM'].includes(title)
                    ? `${crm.clientRect}`
                    : `${classes.clientRect}`
                }`
              )}
            >
              <CountUp end={count} duration={1} />
            </div>
            <div
              onClick={HandleClickExpand}
              className={clsx(
                `${
                  ['Total des clients'].includes(title)
                    ? `${reclasses.clientContent}`
                    : `${classes.clientContent}`
                }`,
                `${
                  ['Internes'].includes(title)
                    ? `${externes.clientContent}`
                    : `${classes.clientContent}`
                }`,
                `${
                  ['Externes'].includes(title)
                    ? `${internes.clientContent}`
                    : `${classes.clientContent}`
                }`,
                `${
                  ['CRM'].includes(title)
                    ? `${crm.clientContent}`
                    : `${classes.clientContent}`
                }`
              )}
            >
              <Typography
                variant='subtitle2'
                //component='p'
                align='center'
                gutterBottom
              >
                {title}
              </Typography>
              <div style={{ marginLeft: '5px' }}>{icon}</div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {isTypeSms && (
        <div className={className}>
          <Card
            // className={`${
            //   ['Total des clients', 'Le total'].includes(title)
            //     ? classes.totalCard
            //     : ''
            // } , ${classes.root}`}
            className={classes.root}
            onClick={HandleClickExpand}
            style={style}
          >
            {' '}
            <CardActionArea>
              <CardContent>
                <div className={classes.container}>
                  <div style={{ marginTop: '1rem', marginLeft: '-0.7rem' }}>
                    {icon}{' '}
                  </div>
                  <div>
                    {' '}
                    <Typography
                      variant='subtitle1'
                      //component='p'
                      align='center'
                      gutterBottom
                    >
                      {title}
                    </Typography>
                    <Typography
                      variant='body2'
                      component='p'
                      align='center'
                      className='MuiTypography-h4'
                    >
                      <CountUp end={count} duration={1} />
                    </Typography>
                  </div>
                </div>
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
      )}

      {isTypeEmail && renderEmailCard()}
      {isTypeClient && renderClientCard()}
    </>
  );
};

export const connector = connect(null, {
  StatsDetailsFilterSMS,
});

export default connector(StatsCard);
