import React from 'react';
import { Wrapper } from './ferryWireframe.style';

import { ic_close } from 'react-icons-kit/md/ic_close';
import { ic_call } from 'react-icons-kit/md/ic_call';
import { ic_email } from 'react-icons-kit/md/ic_email';
import { ic_location_on } from 'react-icons-kit/md/ic_location_on';

import { ratio } from 'wcag-color';
import barcode from '../../assets/images/barcode.png';

import cover from '../../assets/images/lg-thumbnail.png';

import { ic_more_vert } from 'react-icons-kit/md/ic_more_vert';
import Icon from 'react-icons-kit';

import moment from 'moment';

import { getDataFromCache } from '../../utils/cache';
import { URL_IMAGE } from '../../apis/config';
import QRCode from 'qrcode.react';
import Ferry from '../../icons/Ferry';

type WireframeProps = {
  backgroundColor: any;
  strip: any;
  number: any;
  originStationCode: any;
  originName: any;
  destinationName: any;
  destinationStationCode: any;
  departureTime: any;
  arrivalTime: any;
  tarif: any;
  customerServiceNumber: any;
  details: any;
  terms: any;
  QrCode: any;
};

const FerryWireframeAndroid: React.FC<WireframeProps> = (props) => {
  const {
    backgroundColor,
    strip,
    number,
    originStationCode,
    originName,
    destinationName,
    destinationStationCode,
    departureTime,
    arrivalTime,
    tarif,
    customerServiceNumber,
    details,
    terms,
    QrCode,
  } = props;
  const currentWallet = getDataFromCache('wallet');

  return (
    <Wrapper
      backgroundColor={backgroundColor}
      mainContentTextColor={
        ratio('#ffffff', backgroundColor.hex) > 3.0 ? '#ffffff' : '#000000'
      }
    >
      <div className='mainSection'>
        <div className='mainSection-header'>
          <Icon
            size={18}
            icon={ic_close}
            style={
              ratio('#ffffff', backgroundColor.hex) > 3.0
                ? { color: '#ffffff' }
                : { color: '#000000' }
            }
          />
          <Icon
            size={18}
            icon={ic_more_vert}
            style={
              ratio('#ffffff', backgroundColor.hex) > 3.0
                ? { color: '#ffffff' }
                : { color: '#000000' }
            }
          />
        </div>
        <div className='mainSection'>
          <div className='firstSection'>
            <div>
              <img
                className='mainSection-header-logo'
                src={
                  !strip
                    ? cover
                    : typeof strip === 'string'
                    ? strip
                    : URL.createObjectURL(strip)
                }
                alt='strip'
              />
            </div>
            <div>
              <img
                className='mainSection-header-logo'
                src={`${URL_IMAGE}/${currentWallet.logoPictureUrl}`}
                alt='wallet Logo'
              />
            </div>
            <div className='firstSection-Number '>
              <div className='date'>
                <h3 className='firstSection-label'>Numéro</h3>
                <h3 className='firstSection-data'>{number || 'N de place'}</h3>
              </div>
            </div>
          </div>
        </div>
        <div className='secondSection'>
          <div className='contentSection-Number'>
            <div className='date'>
              <h3 className='mainSection-label'>{originName || ''}</h3>
              <h3 className='secondSection-data '>
                {originStationCode || 'P15'}
              </h3>
            </div>
          </div>
          <div style={{ marginLeft: '25px' }}>
            <Ferry />
          </div>
          <div className='contentSection-Number'>
            <div className='date'>
              <h3 className='secondSection-label'>{destinationName || ' '}</h3>
              <h3 className='secondSection-data '>
                {destinationStationCode || 'P13'}
              </h3>
            </div>
          </div>
        </div>
        <div className='contentSection'>
          <div className='contentSection-Number'>
            <div className='date'>
              <h3 className='firstSection-label'>Départ</h3>
              <h3 className='firstSection-data'>
                {' '}
                {moment(departureTime).format('MMM DD, YYYY, h:mm a') || 'Date'}
              </h3>
            </div>
          </div>
          <div className='contentSection-Number'>
            <div className='date'>
              <h3 className='firstSection-label'>Arrivé</h3>
              <h3 className='firstSection-data'>
                {moment(arrivalTime).format('MMM DD, YYYY, h:mm a') || 'Date'}
              </h3>
            </div>
          </div>
          <div className='contentSection-Number'>
            <div className='date'>
              <h3 className='firstSection-label'>Tarif</h3>
              <h3 className='firstSection-data'> {`${tarif} €` || ''}</h3>
            </div>
          </div>
        </div>

        <div className='contentSection' style={{ marginTop: '15px' }}>
          <div className='date'>
            <h3 className='firstSection-label'>PASSAGER</h3>
            <h3 className='firstSection-data'>{currentWallet.name || 'nom'}</h3>
          </div>
        </div>
        <div className='mainSection-content-barCode'>
          {QrCode ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '270px',
              }}
            >
              <QRCode includeMargin value={QrCode.value} />
            </div>
          ) : (
            <div className='barCode'>
              <img src={barcode} alt='barcode' />
              <p className='walletId'>6066e4315abbcc1f9526d392</p>
            </div>
          )}
        </div>
      </div>
      <div className='dataSection'>
        <div className='sectionLabel' style={{ marginTop: '20px' }}>
          <h3 className='label'>Description</h3>
          <h3 className='data'>{`${details}` || ''}</h3>
        </div>
        <span className='divider'></span>
        <div className='sectionLabel'>
          <h3 className='label'>Date de départ</h3>
          <h3 className='data'>
            {moment(departureTime).format('MMM DD, YYYY, h:mm a') || 'Date'}
          </h3>
        </div>
        <span className='divider'></span>
        <div className='sectionLabel'>
          <h3 className='label'>Date d'arrivé</h3>
          <h3 className='data'>
            {moment(arrivalTime).format('MMM DD, YYYY, h:mm a') || 'Date'}
          </h3>
        </div>

        <span className='divider'></span>
        <div className='sectionLabel'>
          <h3 className='label'>Numero du billet</h3>
          <h3 className='data'>6066e4315abbcc1f9526d392</h3>
        </div>
        <span className='divider'></span>
        <div className='sectionLabel'>
          <h3 className='label'>valeur</h3>
          <h3 className='data'>{`${tarif} €` || ''}</h3>
        </div>
        <span className='divider'></span>
        <div className='sectionLabel'>
          <h3 className='label'>Terms & conditions</h3>
          <h3 className='data'>{terms || ''}</h3>
        </div>

        <div className='sectionLabel'>
          <h3 className='label'>Info Line</h3>
          <h3 className='data'>{customerServiceNumber || ''}</h3>
        </div>
      </div>
    </Wrapper>
  );
};

export default FerryWireframeAndroid;
