import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { useStyles } from './table.styles';
import moment from 'moment';
import { CircularProgress, createStyles, Theme } from '@material-ui/core';
import { getDataFromCache } from '../../utils/cache';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import Close from '../../icons/Close';
import Icon from 'react-icons-kit';
// import { ic_delete } from 'react-icons-kit/md';

import Modal from '../Modal';
//Images
import emptyState from '../../assets/images/emptyState.svg';
import Button from '../../commons/Button';
import WalletModal from '../Modal';
import UpdateGain from '../../modules/UpdateGain';
import Text from '../../commons/Text';

type RowProps = {
  row: any;
  // walletId: string;
  handleClickHistory: (id: string) => void;
  onClickDelete: (rowId: string) => void;
};

const Row: React.FC<RowProps> = (props) => {
  const intl = useIntl();
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  const { row, handleClickHistory, onClickDelete } = props;

  const [open, setOpen] = React.useState<boolean>(false);
  const classes = useStyles();
  const [delete_is_loading, setIsLoading] = React.useState<any>(null);

  const renderHistory = () => {
    const { history, loading }: { history: Array<any>; loading: boolean } = row;
    if (loading) {
      return (
        <div className={classes.historyContainer}>
          <CircularProgress
            size={30}
            className={classes.circularProgressStyle}
          />
        </div>
      );
    } else if (history?.length === 0 && !loading) {
      return (
        <div className={classes.historyContainer}>
          <div className={classes.imgContainer}>
            <img
              src={emptyState}
              className={classes.imgStyle}
              alt='empty-state'
            />
          </div>
          <p className={classes.textEmpty}>
            {intl.formatMessage({ id: 'customersTable.historical.emptyList' })}
          </p>
        </div>
      );
    }

    return (
      <Table
        size='small'
        aria-label='purchases'
        className={classes.tableHistory}
      >
        <TableHead>
          <TableRow>
            <TableCell>
              {intl.formatMessage({ id: 'customersTable.historical.date' })}
            </TableCell>
            <TableCell>
              {intl.formatMessage({ id: 'customersTable.historical.gain' })}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {history.map((historyRow) => (
            <TableRow key={historyRow._id}>
              <TableCell component='th' scope='row'>
                {moment(historyRow.createdAt).format('MMMM DD YYYY, h:mm:ss ')}
              </TableCell>
              <TableCell>{historyRow.gain}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };
  let source = null;
  switch (row.source) {
    case 'internal':
      source = intl.formatMessage({ id: 'customersTable.source.internal' });
      break;
    case 'external':
      source = intl.formatMessage({ id: 'customersTable.source.external' });
      break;
    case 'crm':
      source = intl.formatMessage({ id: 'customersTable.source.crm' });
      break;
    case 'imported':
      source = intl.formatMessage({ id: 'customersTable.source.internal' });
      break;
    default:
      source = 'externe';
  }

  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [historyOpen, setHistoryOpen] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const opens = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handlora = () => {
    setHistoryOpen(!historyOpen);
    handleClickHistory(row._id);
  };
  // const classes = useStyles();
  // const styles = (theme: Theme) =>
  //   createStyles({
  //     root: {
  //       margin: 0,
  //       padding: theme.spacing(2),
  //     },
  //     closeButton: {
  //       position: 'absolute',
  //       right: theme.spacing(1),
  //       top: theme.spacing(1),
  //       color: theme.palette.grey[500],
  //     },
  //   });
  return (
    <React.Fragment>
      <WalletModal
        width={380}
        height={300}
        top='25%'
        open={openModal}
        onClose={() => setOpenModal(!openModal)}
      >
        <UpdateGain
          customerId={row._id}
          walletId={row.wallet}
          onSaveChanged={() => setOpenModal(!openModal)}
        ></UpdateGain>
      </WalletModal>
      <Modal open={deleteOpen} onClose={() => setDeleteOpen(!deleteOpen)}>
        <div className={classes.modalDelete}>
          <Text
            lineHeight={24}
            fontSize={16}
            color='#404040'
            style={{ textAlign: 'center' }}
          >
            Voulez vous Supprimer le client
            <br />
            <b>{row.firstName + ' ' + row.lastName}?</b>
          </Text>
          {deleteOpen ? (
            <div
              style={{
                display: 'flex',
                gap: '25px',
                marginTop: '30px',
              }}
            >
              <Button
                background='#fff'
                color='#FF5078'
                border='1px solid #FF5078'
                onClick={() => {
                  setDeleteOpen(false);
                }}
              >
                Annuler
              </Button>

              <Button
                onClick={() => {
                  onClickDelete(row._id);
                }}
              >
                {delete_is_loading ? (
                  <CircularProgress size={20} color='inherit' />
                ) : (
                  ''
                )}
                Supprimer
              </Button>
            </div>
          ) : (
            ''
          )}
        </div>
      </Modal>
      <Modal
        open={historyOpen}
        onClose={() => {
          if (!open) {
            // handleClickHistory(row._id);
            // setHistoryOpen(!historyOpen);
            handleClickHistory(row._id);
          }
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant='h6'>Historique</Typography>
          <IconButton
            aria-label='close'
            onClick={() => setHistoryOpen(!historyOpen)}
          >
            <Close />
          </IconButton>
        </div>

        {renderHistory()}
      </Modal>

      <TableRow className={classes.root}>
        <TableCell>
          {/* <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => {
              if (!open) {
                handleClickHistory(row._id);
              }

              setOpen(!open);
            }}
          >
            {open ? (
              <KeyboardArrowUpIcon
                style={{ color: '#FF5078', fontSize: '24' }}
              />
            ) : (
              <KeyboardArrowDownIcon
                style={{ color: '#FF5078', fontSize: '24' }}
              />
            )}
          </IconButton> */}
        </TableCell>

        <TableCell component='th' scope='row'>
          {row._id}
        </TableCell>
        <TableCell align='center'>{row.barcode}</TableCell>
        <TableCell align='center'>{row.firstName}</TableCell>
        <TableCell align='center'>{row.lastName}</TableCell>
        <TableCell align='center'>
          {moment(row.createdAt).format('LL')}
        </TableCell>
        <TableCell align='center'>{row.gain}</TableCell>
        <TableCell align='center'>{row.email}</TableCell>
        <TableCell align='center'>{row.phoneNumber}</TableCell>
        <TableCell align='center'>{source}</TableCell>
        <TableCell align='center'>{row.status}</TableCell>
        <TableCell align='center'>
          {row.isDeleted
            ? intl.formatMessage({ id: 'customerTable.isDeleted' })
            : intl.formatMessage({ id: 'customerTable.isNotDeleted' })}
        </TableCell>
        <TableCell align='center'>
          {row.devices !== null ? row.devices : '-'}
        </TableCell>
        <TableCell align='right'>
          <div>
            <IconButton
              aria-controls='fade-menu'
              aria-haspopup='true'
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id='fade-menu'
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'bottom', horizontal: -50 }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              keepMounted
              open={opens}
              onClose={handleClose}
              TransitionComponent={Fade}
            >
              <MenuItem
                onClick={() => {
                  // if (!open) {
                  //   handleClickHistory(row._id);
                  //   setAnchorEl(null);
                  // }

                  // setOpen(!open);
                  setHistoryOpen(!historyOpen);
                  setAnchorEl(null);
                }}
                onMouseEnter={(e) =>
                  ((e.target as HTMLInputElement).style.color = '#ff5078')
                }
                onMouseLeave={(e) =>
                  ((e.target as HTMLInputElement).style.color = '#000000')
                }
              >
                Historique
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setOpenModal(!openModal);
                  setAnchorEl(null);
                }}
                onMouseEnter={(e) =>
                  ((e.target as HTMLInputElement).style.color = '#ff5078')
                }
                onMouseLeave={(e) =>
                  ((e.target as HTMLInputElement).style.color = '#000000')
                }
              >
                {intl.formatMessage({ id: 'customersTable.updateGain' })}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setDeleteOpen(!deleteOpen);
                  setAnchorEl(null);
                }}
                onMouseEnter={(e) =>
                  ((e.target as HTMLInputElement).style.color = '#ff5078')
                }
                onMouseLeave={(e) =>
                  ((e.target as HTMLInputElement).style.color = '#000000')
                }
              >
                Supprimé
              </MenuItem>
            </Menu>
          </div>
          {/* <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <Button
              background='#FF5078'
              fontSize={11}
              onClick={() => {
                setOpenModal(!openModal);
              }}
            >
              {intl.formatMessage({ id: 'customersTable.updateGain' })}
            </Button>
            <div
              className={classes.delete_user}
              onClick={() => {
                setDeleteOpen(!deleteOpen);
              }}
            >
              <Icon icon={ic_delete} size='24' style={{ color: '#ff6d6d' }} />
              <DeleteOutlinedIcon
                style={{ color: '#FF5078', fontSize: '24' }}
              />
            </div>
          </div> */}
        </TableCell>
      </TableRow>
      {/* <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box margin={1}>
              <Text
                color='#FF5078'
                fontWeight={500}
                fontSize={16}
                lineHeight={21}
              >
                {intl.formatMessage({ id: 'customersTable.historical.title' })}
              </Text>
              {renderHistory()}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow> */}
    </React.Fragment>
  );
};

type CollapsibleTableProps = {
  data: Array<any>;
  // walletId: string;
  handleClickHistory: (id: string) => void;
  onClickDelete: (id: string) => void;
};

const CollapsibleTable: React.FC<CollapsibleTableProps> = (props) => {
  const classes = useStyles();
  const intl = useIntl();
  const { data, handleClickHistory, onClickDelete } = props;
  const currentWallet = getDataFromCache('wallet');

  return (
    <TableContainer
      className={classes.tableContainer}
      component={Paper}
      elevation={0}

      // style={{ border: '1px solid #e0e0e0', borderBottom: '0' }}
    >
      <Table stickyHeader aria-label='sticky table'>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>
              {intl.formatMessage({ id: 'customersTable.id' })}
            </TableCell>
            <TableCell align='center'>
              {intl.formatMessage({ id: 'customersTable.Barcode' })}
            </TableCell>
            <TableCell align='center'>
              {intl.formatMessage({ id: 'customersTable.firstName' })}
            </TableCell>
            <TableCell align='center'>
              {intl.formatMessage({ id: 'customersTable.lastName' })}
            </TableCell>
            <TableCell align='center'>
              {intl.formatMessage({ id: 'customersTable.createdAt' })}
            </TableCell>
            <TableCell align='center'>
              {`${intl.formatMessage({ id: 'customersTable.gains' })} (${
                currentWallet.gainUnit
              })`}
            </TableCell>
            <TableCell align='center'>
              {intl.formatMessage({ id: 'customersTable.email' })}
            </TableCell>
            <TableCell align='center'>
              {intl.formatMessage({ id: 'customersTable.phoneNumber' })}
            </TableCell>
            <TableCell align='center'>
              {intl.formatMessage({ id: 'customersTable.source' })}
            </TableCell>
            <TableCell align='center'>
              {intl.formatMessage({ id: 'customersTable.status' })}
            </TableCell>
            <TableCell align='center'>
              {intl.formatMessage({ id: 'customersTable.suprimer' })}
            </TableCell>
            <TableCell align='center'>
              {intl.formatMessage({ id: 'customersTable.system' })}
            </TableCell>
            <TableCell align='center'></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <Row
              key={row._id}
              row={row}
              handleClickHistory={handleClickHistory}
              onClickDelete={onClickDelete}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CollapsibleTable;
