export default {
  //**************************************Input File****************************//
  //Buttons
  'inputFile.button.select': 'Select image',
  //**************************************Dialog****************************//
  //Buttons
  'dialog.button.agree': 'Yes',
  'dialog.button.desagree': 'No',
  //**************************************DRAWER****************************//
  //Menu List
  'drawer.menuList.wallet': 'Wallet',
  'drawer.menuList.offers': 'Offer/Communication',
  'drawer.menuList.coupons': 'Coupons',
  'drawer.menuList.event': 'Event',
  'drawer.menuList.settings': 'Settings',
  'drawer.menuList.stats': 'Statistics',

  //Menu Item
  'drawer.menuItem.profile': 'Profile',
  'drawer.menuItem.logout': 'Logout',
  //**************************************Login****************************//
  //Labels
  'login.label.forgotPassword': 'Forgot Password ?',
  //Placeholder
  'login.placeholder.username': 'Username',
  'login.placeholder.password': 'Password',
  //Buttons
  'login.button.login': 'Login',
  //Error
  'login.error.fieldsReqiured': 'username and password are required',
  'login.error.incorrectUsernameOrPassword':
    'Username or password is incorrect',
  'login.error.userNotAuthorized': 'User is not authorized',
  //**************************************Settings****************************//
  //Settings Languages
  'settings.langauges.english': 'English',
  'settings.langauges.french': 'French',
  'settings.langauges.german': 'German',
  //Settings Labels
  'settings.label.settingsTitle': 'Settings',
  'settings.label.registrationParameterTitle': 'Registration parameter :',
  'settings.label.link': 'Link:',
  'settings.label.QrCode': 'QR Code:',
  'settings.label.validationMessage': 'Registration validation message:',
  'settings.label.emailMessage': 'Registration email message:',
  'settings.label.offerEmailMessage': 'Offer / communication email message:',
  'settings.label.couponEmailMessage': 'Coupon email message:',
  'settings.label.changeLanguageTitle': 'Change language :',
  'settings.label.linkCopied': 'Link copied',
  'settings.label.subscriptionMessage': 'Subscription message',
  'settings.label.smsTitle': 'SMS title',

  //Placeholder
  'settings.placeholder.validationMessage': 'Registration validation message',
  'settings.placeholder.emailMessage': 'Registration email message',
  'settings.placeholder.offerEmailMessage':
    'Offer / communication email message',
  'settings.placeholder.couponEmailMessage': 'Coupon email message',
  'settings.placeholder.subscriptionMessage': 'your subscrition message here',
  //Settings Buttons
  'settings.button.save': 'Save',
  'settings.button.cancel': 'Cancel',
  'settings.button.copyLink': 'Copy Link',
  'settings.button.verifyEmail': 'Verify Email',

  //**************************************WALLET****************************//
  //Labels
  'wallet.label.searchCustomer': 'Search for a customer',
  'wallet.label.filter': 'Filter by',
  'wallet.label.customersList': 'List of customers',
  'wallet.label.customersEmptyList': 'The list of customers is empty',
  //Select
  'wallet.select.firstName': 'First name',
  'wallet.select.lastName': 'Last name',
  'wallet.select.email': 'Email',
  //Placeholder
  'wallet.placeholder.searchCustomer': 'Search',
  //Buttons
  'wallet.add': 'Add customer',
  //**************************************Customers Table****************************//
  //Labels
  'customersTable.Barcode': 'Barcode',
  'customersTable.id': 'ID',
  'customersTable.firstName': 'First name',
  'customersTable.lastName': 'Last name',
  'customersTable.createdAt': 'Created At',
  'customersTable.gains': 'Gains',
  'customersTable.email': 'Email',
  'customersTable.phoneNumber': 'Phone number',
  'customersTable.source': 'Source',
  'customersTable.source.internal': 'Internal',
  'customersTable.source.external': 'External',
  'customersTable.source.crm': 'CRM',

  'customersTable.status': 'Status',
  'customersTable.suprimer': 'Deleted',
  'customersTable.system': 'Device',
  'customerTable.isDeleted': 'Yes',
  'customerTable.isNotDeleted': 'No',
  //Buttons
  'customersTable.updateGain': 'Update gain',
  //Historical
  'customersTable.historical.title': 'History',
  'customersTable.historical.emptyList': 'History list is empty',
  'customersTable.historical.date': 'Date',
  'customersTable.historical.gain': 'Gain',
  //**************************************Update Gain****************************//
  //Labels
  'updateGain.label.gain': 'Update gain :',
  //Placeholder
  'updateGain.placehoder.gain': 'Gain',
  //Buttons
  'updateGain.button.confirm': 'Update',
  //Error
  'updateGain.error.gainNotNumber': 'The gain must be a number',
  //**************************************Add Customer****************************//
  //Labels
  'addCustomer.label.firstName': 'First name',
  'addCustomer.label.lastName': 'Last name',
  'addCustomer.label.email': 'Email',
  'addCustomer.label.barcode': 'Barcode',
  'addCustomer.label.phoneNumber': 'Phone number',
  //Placeholder
  'addCustomer.placeholder.firstName': 'First name',
  'addCustomer.placeholder.lastName': 'Last name',
  'addCustomer.placeholder.email': 'Email',
  'addCustomer.placeholder.barcode': 'Barcode',
  'addCustomer.placeholder.phoneNumber': '+33757130710',
  //Buttons
  'addCustomer.button.add': 'Add',
  //Error
  'addCustomer.error.firstNameRequired': 'First name is required',
  'addCustomer.error.lastNameRequired': 'Last name is required',
  'addCustomer.error.emailRequired': 'Email is required',
  'addCustomer.error.phoneRequired': 'Phone number is required',
  'addCustomer.error.emailInvalid': 'Invalid Email',
  'addCustomer.error.emailExist': 'Email or barcode already exists',
  'addCustomer.error.phoneNumberInvalid': 'Invalid phone number',
  'addCustomer.error.barcodeInvalid':
    'the bar code must be at least 8 characters',

  //**************************************Offers List****************************//
  //Labels
  'offers.label.title': 'List of offers',
  'offers.label.emptyList': 'The list of offers is empty',
  'offers.label.filter': 'Filter by',
  'offers.label.startDate': 'Start date',
  'offers.label.endDate': 'End date',
  'offers.label.allTime': 'All time',
  //Select
  'offers.select.all': 'ALL',
  'offers.select.opened': 'OPENED',
  'offers.select.actif': 'ACTIF',
  'offers.select.inactif': 'INACTIF',
  'offers.select.expired': 'EXPIRED',
  'offers.select.deleted': 'DELETED',

  //Dialog
  'offers.dialog.title': 'Information',
  'offers.dialog.text.inactif':
    'Are you sure you want to desactivate this offer ?',
  'offers.dialog.text.deleted': 'Are you sure you want to delete this offer ?',
  //Buttons
  'offers.button.search': 'Search',
  'offers.button.create': 'New Offer',
  //**************************************Offer Card****************************//
  //Labels
  'offerCard.label.status': 'Status',
  'offerCard.label.startDate': 'Start date',
  'offerCard.label.endDate': 'End date',
  'offerCard.label.description': 'Description',
  //Buttons
  'offerCard.button.desactivate': 'Deactivate',
  'offerCard.button.delete': 'Delete',
  //**************************************Statistics****************************//
  //Labels
  'statsSMS.label.title': 'SMS-Bericht senden',
  'statsSMS.label.newAccount': 'New account',
  'statsSMS.label.offers': 'Offers',
  'statsSMS.label.coupons': 'Coupons',
  'statsSMS.label.events': 'Events',
  //**************************************Offer****************************//
  //Labels
  'offer.label.title': 'Create an offer',
  'offer.label.image': 'Image to insert:',
  'offer.label.description': 'Description',
  'offer.label.startDate': 'Start date',
  'offer.label.endDate': 'End date',
  //Placeholder
  'offer.placeholder.description': 'Description ...',
  //Buttons
  'offer.button.add': 'Add',
  //Error
  'offer.error.imageRequired': 'Picture is required',
  'offer.error.startDateRequired': 'Start Date is required',
  'offer.error.startDateInvalid': 'Invalid Start Date',
  'offer.error.endDateRequired': 'End Date is required',
  'offer.error.endDateInvalid': 'Invalid End Date',
  'offer.error.endDateBeforeStartDate':
    'The end date is earlier than the start date',
  'offer.error.descriptionRequired': 'Description is required',
  'offer.error.datesUnavailable': 'Dates are unavailable',
  //**************************************Coupons List****************************//
  //Labels
  'coupons.label.title': 'List of coupons',
  'coupons.label.emptyList': 'The list of coupons is empty',
  //Dialog
  'coupons.dialog.title': 'Information',
  'coupons.dialog.text': 'Do you want to send this coupon to all customers ?',
  //Buttons
  'coupons.button.create': 'New Coupon ',
  //**************************************Coupon Card****************************//
  //Labels
  'couponCard.label.expirationDate': 'Expiration date:',
  'couponCard.label.percent': 'Percent reduction:',
  'couponCard.label.promoCode': 'Promo code:',
  'couponCard.label.description': 'Description',
  'couponCard.label.status': 'Status',
  'couponCard.label.source': 'Source',
  //Buttons
  'couponCard.button.send': 'send',
  'couponCard.switch.activate': 'Activate',
  //**************************************Coupon****************************//
  //Labels
  'coupon.label.expirationDate': 'Expiration date',
  'coupon.label.textColor': 'Text Color:',
  'coupon.label.backgroundColor': 'Background color:',
  'coupon.label.foregroundColor': 'Foreground color:',
  'coupon.label.image': 'Image to insert:',
  'coupon.label.percent': 'Percentage or Value:',
  'coupon.label.description': 'Description',
  'coupon.label.promoCode': 'Promo Code',
  'coupon.label.All': 'ALL',
  'coupon.label.Internal': 'INTERNAL',
  'coupon.label.External': 'EXTERNAL',
  'coupon.label.crm': 'CRM',

  'coupon.label.Inactif': 'INACTIVE',
  'coupon.label.deleted': 'DELETED',

  'coupon.label.Actif': 'ACTIVE',
  'coupon.label.Expire': 'EXPIRED',
  //Placeholder
  'coupon.placeholder.description': 'Description ...',
  //Buttons
  'coupon.button.add': 'Add',
  //Error
  'coupon.error.expirationDateRequired': 'Expiration date is required',
  'coupon.error.expirationDateInvalid': 'Invalid expiration date',
  'coupon.error.labelColorRequired': 'Text color is required',
  'coupon.error.backgroundColorRequired': 'Background color is required',
  'coupon.error.foregroundColorRequired': 'Foreground color is required',
  'coupon.error.imageRequired': 'Picture is required',
  'coupon.error.percentReductionRequired': 'Percentage reduction is required',
  'coupon.error.descriptionRequired': 'Description is required',
  'coupon.error.promoCodeRequired': 'Promo Code is Required',

  //**************************************Events List****************************//
  //Labels
  'events.label.title': 'Events list',
  'events.button.create': 'new event',
  'event.label.name': 'Name',
  'event.label.openingDate': 'Opening date',
  'event.label.startingDate': 'Start date',
  'event.label.closingDate': 'Closing date',
  'event.label.eventLocation': 'Location of the event',
  'event.label.title': 'Create an event',
  'event.label.image': 'Image to insert',
  'event.label.openingHour': 'Start date and time of the event',
  'event.label.closingHour': 'End date and time of the event',
  'event.label.doorOpeningHour': 'Date and time of door opening',
  'event.label.price': 'Event price',
  'event.label.locationName': 'Location name',
  'event.label.address': 'Location address',
  'event.label.contactPhone': 'Contact phone',
  'event.label.email': 'Communication email',
  'event.label.website': 'Website',
  'event.label.terms': 'Terms',
  'event.label.labelColor': 'Text color',
  'EventsCustomersTable.Id': 'ID (Ticket)',
  'EventsCustomersTable.firstName': 'First name',
  'EventsCustomersTable.lastName': 'Last name',
  'EventsCustomersTable.email': 'Email',
  'EventsCustomersTable.enregistre': 'Checked in',
  'EventsCustomersTable.systeme': 'System',
  'EventsCustomersTable.enregistre.true': 'Yes',
  'EventsCustomersTable.enregistre.false': 'No',
  'event.label.section.style': 'Event style',
  'event.label.section.timeLocation': 'Time and place of the event',
  'event.label.selectLocation': 'Select the exact location',
  'event.label.section.contact': 'Contact information',
  'event.label.subMessage': 'Subscription message',
  'event.label.section.emailMessage': 'Email message',
  'event.label.emailMessage': 'Email message',
  'event.label.section.info': 'Event information',
  'email.label.emailMessage': 'Event message',
  'mapComponent.label.search': 'Search map',

  //Errors
  'event.error.nameRequired': 'Name of the event is required',
  'event.error.priceRequired': 'Event price is required',
  'event.error.locationNameRequired': 'Location name is required',
  'event.error.addressRequired': 'Event address is required',
  'event.error.contactPhoneRequired': 'Contact phone is required',
  'event.error.emailRequired': 'Communication email is required',
  'event.error.websiteError': 'Website is required',
  'event.error.termsRequired': 'Terms are required',
  'event.error.imageRequiredRequired': 'Strip image is required',
  'event.error.subscriptionMessageRequired': 'Subscription message is required',

  //**************************************Profile****************************//
  //Labels
  'profile.label.emailSettings': 'Emailing parameters :',
  'profile.label.managerEmail': 'Main Email :',
  'profile.label.communicationEmail': 'Communication Email  :',
  'profile.label.communicationEmailHost': 'Host Server :',
  'profile.label.communicationEmailPort': 'Port Number:',
  'profile.label.communicationEmailUser': 'Username :',
  'profile.label.communicationEmailPass': 'Password :',
  'profile.label.emailSettingsConfirmation': 'Enter your password to confirm:',

  'profile.label.updatePasswordTitle': 'Change password :',
  'profile.label.oldPassword': 'Old password',
  'profile.label.newPassword': 'New password',
  'profile.label.confirmPassword': 'Confirm password',
  //Placeholder
  'profile.placeholder.managerEmail': 'Your Email ...',
  'profile.placeholder.communicationEmailHost': 'xx.xxx.xx ',
  'profile.placeholder.communicationEmailPort': '0000',
  'profile.placeholder.communicationEmailUser': 'username ....',
  'profile.placeholder.communicationEmailPass': 'password ...',
  'profile.placeholder.oldPassword': 'Old password ...',
  'profile.placeholder.newPassword': 'New Password ...',
  'profile.placeholder.confirmPassword': 'Confirm password ...',
  //Buttons
  'profile.button.confirmPassword': 'Confirm Password',
  'profile.button.updateEmailSettings': 'Update Email Settings',
  'profile.button.updatePassword': 'Update Password',
  //Success
  'profile.success.successMessage': 'Updated with success',
  //Error
  'profile.error.oldPasswordRequired': 'Old password is required. ',
  'profile.error.oldPasswordIncorrect': 'Your current password is wrong.',
  'profile.error.newPasswordRequired': 'New password is required.',
  'profile.error.newPasswordRequiredLength':
    'The password must contain at least 4 characters.',
  'profile.error.confirmPasswordRequired': 'Confirm password is required.',
  'profile.error.confirmPasswordNotMatch':
    'The confirmation does not match the new password.',
  'profile.error.emailSettingsError':
    'Configuration error, please check the parameters entered.',

  'profile.error.emailCommunication': 'invalid communication email parameters',
  'profile.error.userPasswordError': 'Incorrect password',
  'profile.error.managerEmail': 'Principal Email is required',
  'profile.error.userPrincipalEamilExistsError':
    'Already existing email address, try another',
  //**************************************Forgot Password****************************//
  //Labels
  'forgotPassword.label.email': 'Enter your user name.',
  //Placeholder
  'forgotPassword.placeholder.email': 'User name',
  //Buttons
  'forgotPassword.button.confirm': 'Confirm',
  //Error
  'forgotPassword.error.emailRequired': 'User name is required',
  'forgotPassword.error.invalidEmail': 'Invalid user name',
  //**************************************Reset Password****************************//
  //Labels
  'resetPassword.label.password': 'New Password:',
  'resetPassword.label.passwordConfirm': 'Confirm the password:',
  //Placeholder
  'resetPassword.placeholder.password': 'Enter your new password',
  'resetPassword.placeholder.passwordConfirm': 'Confirm your password',
  //Buttons
  'resetPassword.button.confirm': 'Confirm',
  //Error
  'resetPassword.error.passwordRequired': 'New password is required.',
  'resetPassword.error.passwordRequiredLength':
    'The password must contain at least 4 characters.',
  'resetPassword.error.passwordConfirmRequired':
    'Confirm password is required.',
  'resetPassword.error.passwordConfirmNotMatch':
    'The confirmation does not match the new password.',
  'resetPassword.error.tokenExpired':
    "Sorry, your token expired! You'll need to resend your email.",
};
