import { css } from 'styled-components';

export const addCustomerStyles = (props: any) => {
  return css`
    display: flex;
    flex-direction: column;
    align-items: center;
    .error {
      color: red;
    }
    .div-container {
      display: flex;
      height: 570px;
      width: 570px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 15px;
    }
  `;
};
