import { red } from '@material-ui/core/colors';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Height } from '@material-ui/icons';
import { isAbsolute, relative } from 'path';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      width: 233,
      marginRight: '15px',
      background: '#FFFFFF',
      boxShadow: '0px 0px 20px 2px rgba(55, 55, 55, 0.08)',
      borderRadius: '20px',
      marginTop: '38px',
      maxHeight: 82,
    },
    cardContent: {
      //marginTop: '10px',
    },

    container: {
      display: 'grid',

      justifyContent: 'center',

      gridTemplateColumns: '5px 1fr',
      marginTop: '-11px',
    },
    media: {
      height: 185,
      borderRadius: '20px',
      border: '1px solid #CDCDCD',
      boxSizing: 'border-box',
    },
    label: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '8px',
    },

    description: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    cardDescription: {
      padding: '8px',
      border: '1px solid #C7C7C7',
      borderRadius: '10px',
      marginTop: '8px',
    },
    cardMenu: {
      position: 'relative',
      height: '0px',
      left: '240px',
      top: '15px',
    },

    // totalCard: {
    //   width: '230px',
    //   '& .makeStyles-emailContainer-71': {
    //     '& .makeStyles-Container-68': {
    //       border: '2px solid #FF5078 !important',
    //       justifyContent: 'flex-start !important',
    //       paddingLeft: '1rem !important',
    //       marginTop: '2rem !important',
    //       color: '#FF5078 !important ',
    //       paddingTop: '2px !important ',
    //       textTransform: 'uppercase !important',
    //     },
    //     '& .makeStyles-emailCircle-72': {
    //       backgroundColor: '#fff !important',
    //       color: '#FF5078 !important',
    //       width: '0px !important',
    //       height: '0px !important',
    //       position: 'relative !important',
    //       left: '10rem !important',
    //       alignItems: 'revert !important',
    //       fontWeight: 600,
    //       marginTop: '4px !important',
    //     },
    //     '& .MuiTypography-subtitle2': {
    //       fontSize: '0.975rem !important',

    //       fontWeight: 600,
    //     },
    //   },
    // },
    menuIcon: {
      fill: '#ffffff',
    },
    iconButton: {},
    title: {
      color: 'red',
    },
    circle: {
      position: 'relative',
      height: '85px',
      width: '85px',
      backgroundColor: '#404040',
      borderRadius: '50%',
      zIndex: 5,
      top: 23,
    },
    countText: {
      position: 'absolute',
      textAlign: 'center',
      top: '2rem',
      left: '2.5rem',
      color: 'red',
    },
    Container: {
      //position: 'relative',
      width: 231,
      // marginRight: '50px',
      background: '#FFFFFF',
      boxShadow: '0px 0px 20px 2px rgba(55, 55, 55, 0.08)',
      borderRadius: '15px',
      //marginTop: '40px',
      minHeight: 41,
      border: '2px solid #404040',
      //left: '-25px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      left: '-2rem',
      top: '-1rem',
      zIndex: -1,
    },
    BoxContainer: {
      height: '50px',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      width: '230px',
      maxHeight: '55px',
      //border: '2px solid #404040',
      boxShadow: 'none',
      margin: '0px 1rem',
    },
    emailRoot: {},
    emailContainer: {
      display: 'flex',
      // marginRight: '3rem',
      alignItems: 'center',
    },
    emailCircle: {
      width: '85px',
      height: '85px',
      backgroundColor: '#404040',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '2rem',
      color: '#fff',
      // position: 'relative',
      // left: '2rem',
    },
    clientRect: {
      width: '86px',
      height: '30px',
      backgroundColor: '#404040',
      borderRadius: '7px',
      marginLeft: '45px',
      color: '#FFF',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    clientContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginRight: '30px',
    },
    clientContent: {
      //position: 'relative',
      width: 231,
      // marginRight: '50px',
      background: '#FFFFFF',
      boxShadow: '0px 0px 20px 2px rgba(55, 55, 55, 0.08)',
      borderRadius: '15px',
      //marginTop: '40px',
      minHeight: 41,
      border: '2px solid #404040',
      //left: '-25px',
      display: 'flex',
      justifyContent: 'center',
      //alignItems: 'center',
      position: 'relative',
      left: '-2rem',
      top: '-1rem',
      zIndex: -1,
      alignItems: 'center',
      height: '66px',
      //justifyContent: 'center',
    },
    clientTotalCard: {
      width: '230px',
      '& .makeStyles-clientContainer-74': {
        '& .makeStyles-clientContent-75': {
          border: '2px solid #FF5078 !important',
          justifyContent: 'flex-start',
          paddingLeft: '1rem',
          marginTop: '2rem',
          color: '#FF5078',
          paddingTop: '2px',
          textTransform: 'uppercase',
          height: '0px',
        },
        '& .makeStyles-clientRect-73': {
          backgroundColor: '#fff !important',
          color: '#FF5078',
          width: '0px',
          height: '0px',
          position: 'relative',
          left: '9rem',
          alignItems: 'revert',
          fontWeight: 600,
          marginTop: '4px',

          top: '29px',
        },
        '& .MuiTypography-subtitle2': {
          fontSize: '0.975rem',

          fontWeight: 600,
        },
      },
    },
  })
);

export const childStyles = makeStyles((theme: Theme) =>
  createStyles({
    totalCard: {
      width: '230px',
      // border: '2px solid #FF5078 ',
      marginTop: '31px',
    },
    emailContainer: {
      //display: 'flex',
      // marginRight: '3rem',
      // alignItems: 'center',
    },
    emailCircle: {
      position: 'relative',
      backgroundColor: '#fff ',
      color: '#FF5078 ',
      width: '0px ',
      height: '0px ',
      left: '10rem',
      top: '-6px',
      alignItems: 'revert ',
      fontWeight: 600,
      marginTop: '4px ',
    },
    Container: {
      width: 231,
      // marginRight: '50px',
      paddingLeft: '13px',
      background: '#FFFFFF',
      boxShadow: '0px 0px 20px 2px rgba(55, 55, 55, 0.08)',
      borderRadius: '15px',
      //marginTop: '40px',
      minHeight: 41,
      border: '2px solid #FF5078',
      //left: '-25px',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      position: 'relative',
      left: '-2rem',
      top: '-1rem',
      zIndex: -1,
      color: '#FF5078',
      textTransform: 'capitalize',
      '& .MuiTypography-subtitle2': {
        fontWeight: 600,
        marginLeft: '7px',
        marginTop: '7px',
        fontSize: '0.975rem',
      },
    },

    clientContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginRight: '30px',
    },
    clientRect: {
      backgroundColor: '#fff !important',
      color: '#FF5078',
      width: '0px',
      height: '0px',
      position: 'relative',
      left: '7rem',
      alignItems: 'revert',
      fontWeight: 600,
      //marginTop: '4px',

      top: '-4px',
    },
    clientContent: {
      //position: 'relative',
      width: 231,
      // marginRight: '50px',
      background: '#FFFFFF',
      boxShadow: '0px 0px 20px 2px rgba(55, 55, 55, 0.08)',
      borderRadius: '15px',
      //marginTop: '40px',
      minHeight: 41,
      border: '2px solid #FF5078 !important',
      //left: '-25px',
      display: 'flex',
      justifyContent: 'flex-start',
      //alignItems: 'center',
      position: 'relative',
      left: '-2rem',
      top: '-1rem',
      zIndex: -1,
      color: '#FF5078',
      alignItems: 'center',
      height: '0px',
      //justifyContent: 'center',
      '& .MuiTypography-subtitle2': {
        fontWeight: 600,
        fontSize: '0.975rem',
        marginTop: '7px',
        marginLeft: '7px',
      },
    },
    interne: {
      clientRect: {
        backgroundColor: '#9D30BB',
      },
      clientContent: {
        border: ' 2px solid #9D30BB',
        color: '#9D30BB',
      },
    },
    interneRect: {
      backgroundColor: '#9D30BB',
    },
  })
);

export const internesStyles = makeStyles((theme: Theme) =>
  createStyles({
    clientRect: {
      backgroundColor: '#9D30BB',
    },
    clientContent: {
      border: ' 2px solid #9D30BB',
      color: '#9D30BB',
    },
  })
);
export const externesStyles = makeStyles((theme: Theme) =>
  createStyles({
    clientRect: {
      backgroundColor: '#691FDE',
    },
    clientContent: {
      border: ' 2px solid #691FDE',
      color: '#691FDE',
    },
  })
);
export const crmStyles = makeStyles((theme: Theme) =>
  createStyles({
    clientRect: {
      backgroundColor: '#FF5078',
    },
    clientContent: {
      border: ' 2px solid #FF5078',
      color: '#FF5078',
    },
  })
);
