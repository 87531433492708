import React from 'react';
import { useIntl } from 'react-intl';

import { Route, Switch, RouteComponentProps } from 'react-router-dom';
import SideBar from '../../components/Drawer';

import WalletDetail from '../../modules/Wallet';
import { useStyles } from './walletRouter.styles';

//Modules

const WalletsRouter: React.FC<RouteComponentProps> = (props) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.rootAppRouter}>
      <SideBar title={intl.formatMessage({ id: 'drawer.menuList.wallet' })}>
        <Switch>
          <Route path='/wallet/:walletId' component={WalletDetail} />
        </Switch>
      </SideBar>
    </div>
  );
};
//5f05d26935a683561b1871be

export default WalletsRouter;
