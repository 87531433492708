import { addCustumerToWallet } from '../apis/addCustomerApi';
import {
  ADD_CUSTOMER_PROPS_CHANGED,
  ADD_CUSTOMER_FORM_CUSTOMER,
  ADD_CUSTOMER_INITIAL_STATE,
  AppThunk,
  WALLET_GET_WALLET_DETAIL,
  WALLET_PROPS_CHANGED,
  ADD_CUSTOMER_TO_CUSTOMERS_LIST,
  SNACKBAR_SUCCESS,
  SNACKBAR_ERROR,
} from './types';
import { validateFieldsAddCustomer } from '../modules/AddCustomer/addCustomer.utils';

import {
  AddCustomerState,
  AddCustomerPropsChangedAction,
  AddCustomerInitialStateAction,
} from '../modules/AddCustomer/addCustomer.types';
import { Customer } from '../apis/ApiTypes';

export const addCustomerPropsChanged = (
  prop: string,
  value: any
): AddCustomerPropsChangedAction => {
  return { type: ADD_CUSTOMER_PROPS_CHANGED, prop, value };
};

export const addCustomer = (
  walletId: string,
  props: AddCustomerState,
  callback: () => void
): AppThunk => (dispatch) => {
  const { newProps, valid } = validateFieldsAddCustomer(props);

  dispatch({ type: ADD_CUSTOMER_FORM_CUSTOMER, newProps });
  if (valid) {
    dispatch({
      type: ADD_CUSTOMER_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });
    addCustumerToWallet(walletId, newProps).then((res) => {
      dispatch({
        type: ADD_CUSTOMER_PROPS_CHANGED,
        prop: 'loading',
        value: false,
      });

      if (res.data.status === 'success') {
        let load = Object.assign(res.data.response.data.newCustomerResult, {
          status: 'inactif',
          isDeleted: false,
          devices: null,
        });
        dispatch({
          type: ADD_CUSTOMER_TO_CUSTOMERS_LIST,
          payload: [
            {
              ...load,
              history: [],
              loading: false,
            },
          ],
        });
        dispatch({
          type: SNACKBAR_SUCCESS,
          value: "L'utilisateur a été ajouté avec succès ",
        });
        callback();
      } else if (res.status === 500) {
        if (
          res.data.response?.data.smsStatus == false &&
          res.data.response?.data.emailSentStatus == false
        ) {
          dispatch({
            type: ADD_CUSTOMER_PROPS_CHANGED,
            prop: 'emailBarcodeError',
            value: "Problème d'envoi de SMS et de mail",
          });
          return;
        }

        if (
          res.data.response?.data.smsStatus == false &&
          res.data.response?.data.emailSentStatus == null
        ) {
          dispatch({
            type: ADD_CUSTOMER_PROPS_CHANGED,
            prop: 'emailBarcodeError',
            value: "Problème d'envoi de SMS",
          });
          return;
        }
        if (
          res.data.response?.data.smsStatus == null &&
          res.data.response?.data.emailSentStatus == false
        ) {
          dispatch({
            type: ADD_CUSTOMER_PROPS_CHANGED,
            prop: 'emailBarcodeError',
            value: "Problème d'envoi de email",
          });
          return;
        }

        if (res.data.includes('credit not available')) {
          dispatch({
            type: ADD_CUSTOMER_PROPS_CHANGED,
            prop: 'emailBarcodeError',
            value: 'Crédit insuffisant',
          });
        } else if (res.data.includes('phoneNumber already')) {
          dispatch({
            type: ADD_CUSTOMER_PROPS_CHANGED,
            prop: 'emailBarcodeError',
            value: 'Le numéro de téléphone existe déjà ',
          });
        } else if (res.data.includes('email already')) {
          dispatch({
            type: ADD_CUSTOMER_PROPS_CHANGED,
            prop: 'emailBarcodeError',
            value: "L'adresse mail existe déjà",
          });
        } else {
          dispatch({
            type: ADD_CUSTOMER_PROPS_CHANGED,
            prop: 'emailBarcodeError',
            value: "Erreur lors de l'obtention du crédit",
          });
        }
      } else if (res.data.status === 'failed' || res.status >= 400) {
        dispatch({
          type: ADD_CUSTOMER_PROPS_CHANGED,
          prop: 'emailBarcodeError',
          value: "Erreur lors de l'envoi de l'email",
        });
      } else {
        dispatch({
          type: SNACKBAR_ERROR,
          value: 'Erreur est survenue',
        });
      }
    });
  }
};

export const addCustomerInitialState = (): AddCustomerInitialStateAction => {
  return { type: ADD_CUSTOMER_INITIAL_STATE };
};
