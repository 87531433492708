import 'date-fns';
import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
//Style
import styled from 'styled-components';
import { offerStyles } from './offer.styles';
//Material
import {
  FormControlLabel,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';

import Radio from '@material-ui/core/Radio';

import { KeyboardDatePicker } from '@material-ui/pickers';
//Components
import InputLabel from '../../commons/Inputs/InputLabel';
//Commomns
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import Button from '../../commons/Button';

//Actions
import {
  offerPropsChanged,
  editOfferConfirm,
  offerInitialState,
} from '../../actions';
//Reducers
import { RootState } from '../../reducers';
//Type

import { OfferFormProps, OfferStateError, OfferState } from './offer.types';
import InputFile from '../../commons/Inputs/InputFile';
import Text from '../../commons/Text';
import moment, { Moment } from 'moment';

const EditOffer: React.FC<any> = (props) => {
  const intl = useIntl();
  const {
    walletId,
    offerId,
    image,
    startDate,
    endDate,
    description,
    unlimited,
    imageError,
    startDateError,
    endDateError,
    descriptionError,
    messageError,
    loading,
    status,
    statusError,
    onSaveChanged,
    onCancel,
  } = props;

  const handleDateChange = (prop: string) => (date: Date | null) => {
    const today = moment(new Date());

    props.offerPropsChanged('endDateError', '');
    props.offerPropsChanged('statusError', '');
    props.offerPropsChanged(prop, date);
    if (prop === 'startDate')
      if (
        moment(date).isSameOrAfter(today, 'day') &&
        moment(endDate).isSameOrAfter(today, 'day')
      ) {
        props.offerPropsChanged('status', 'ACTIF');
      }

    if (prop === 'endDate')
      if (
        moment(startDate).isSameOrAfter(today, 'day') &&
        moment(date).isSameOrAfter(today, 'day')
      ) {
        props.offerPropsChanged('status', 'ACTIF');
      }
  };

  const handleFileChange = (event: any, prop: string) => {
    const file = event.target.files[0];
    props.offerPropsChanged(prop, file);
  };

  const handleEventChange = (event: React.ChangeEvent<any>) => {
    if (event.target.name == 'description') {
      props.offerPropsChanged('descriptionError', '');
      if (event.target.value.length > 200) {
        props.offerPropsChanged(
          'descriptionError',
          'La longueur maximale du texte a été atteinte '
        );
        return;
      }
    }
    if (event.target.name == 'unlimited') {
      props.offerPropsChanged(event.target.name, event.target.checked);
      return;
    }
    props.offerPropsChanged(event.target.name, event.target.value);
  };

  const handleClickConfirm = () => {
    props.editOfferConfirm(walletId, offerId, props, unlimited, () =>
      onSaveChanged()
    );
  };

  const getFormatedMessage = (prop: OfferStateError) => {
    return props[prop] !== ''
      ? intl.formatMessage({ id: props[prop] })
      : props[prop];
  };

  const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.offerPropsChanged('statusError', '');
    if (event.target.checked) {
      const today = moment(new Date());

      if (
        endDate.isSameOrAfter(today, 'day') &&
        startDate.isSameOrAfter(today, 'day')
      )
        return props.offerPropsChanged(event.target.name, 'ACTIF');

      if (today.isAfter(moment(endDate), 'day'))
        return props.offerPropsChanged(
          'statusError',
          "L'offre a déjà expiré, veuillez d'abord modifier la date de fin."
        );

      if (today.isAfter(moment(startDate), 'day'))
        return props.offerPropsChanged(
          'statusError',
          "La date de début doit être égale à aujourd'hui ou à une date ultérieure."
        );
    }
    return props.offerPropsChanged(event.target.name, 'INACTIF');
  };

  return (
    <Wrapper>
      <div className='header'>
        <Text color='#404040' fontSize={16} fontWeight={600} lineHeight={24}>
          Modifier une offre
        </Text>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            background={'white'}
            border={'1px solid #FF5078'}
            color={'#FF5078'}
            onClick={onCancel}
          >
            Annuler
          </Button>
          <Button onClick={handleClickConfirm} width={135}>
            {loading ? (
              <CircularProgress size={20} color='inherit' />
            ) : (
              'Sauvgarder'
            )}
          </Button>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          marginRight: '14rem',
        }}
      >
        <InputFile
          width={270}
          label={intl.formatMessage({ id: 'offer.label.image' })}
          image={image}
          defaultImage={'null'}
          error={getFormatedMessage('imageError')}
          onChange={(e) => {
            handleFileChange(e, 'image');
          }}
        ></InputFile>
      </div>
      <div className='div-container'>
        <div className='div-content' style={{ marginTop: 20 }}>
          <div>
            <div className='select'>
              <p>Status</p>

              <FormGroup row>
                <FormControlLabel
                  control={
                    <Switch
                      checked={status == 'ACTIF' || status == 'OPENED'}
                      onChange={(e) => handleChangeCheckBox(e)}
                      name='status'
                    />
                  }
                  label="Activer l'offer"
                />
              </FormGroup>
              {statusError && (
                <span className='input-text-error'>{statusError}</span>
              )}
            </div>
            <InputLabel
              inputType='textArea'
              label={intl.formatMessage({ id: 'offer.label.description' })}
              name='description'
              value={description}
              error={getFormatedMessage('descriptionError')}
              width={500}
              placeholder={intl.formatMessage({
                id: 'offer.placeholder.description',
              })}
              onChange={(e) => handleEventChange(e)}
            />
          </div>
        </div>
        <div style={{ marginRight: '24rem', marginTop: '-1rem' }}>
          <FormControlLabel
            control={
              <Checkbox
                name='unlimited'
                onChange={(e) => {
                  handleEventChange(e);
                }}
              />
            }
            checked={unlimited}
            label='Illimité'
          />
        </div>
        <div className='div-date-cont'>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '2rem',
              width: '500px',
              marginTop: '-1rem',
            }}
          >
            <KeyboardDatePicker
              required
              margin='normal'
              id='start-date'
              label={intl.formatMessage({ id: 'offer.label.startDate' })}
              format='DD/MM/yyyy'
              value={startDate}
              onChange={handleDateChange('startDate')}
              error={startDateError !== ''}
              helperText={getFormatedMessage('startDateError')}
              disablePast
              inputVariant='outlined'
            />
            <KeyboardDatePicker
              required
              margin='normal'
              id='end-date'
              label={intl.formatMessage({ id: 'offer.label.endDate' })}
              format='DD/MM/yyyy'
              inputVariant='outlined'
              value={endDate}
              onChange={handleDateChange('endDate')}
              error={endDateError !== ''}
              helperText={getFormatedMessage('endDateError')}
              disablePast
              minDate={startDate}
              disabled={unlimited}
            />
          </div>
        </div>
        {/* <div className='div-date-cont'>
          <div style={{ marginTop: '43px' }}>
            <KeyboardDatePicker
              required
              margin='normal'
              id='start-date'
              label={intl.formatMessage({ id: 'offer.label.startDate' })}
              format='DD/MM/yyyy'
              value={startDate}
              onChange={handleDateChange('startDate')}
              error={startDateError !== ''}
              helperText={getFormatedMessage('startDateError')}
              disablePast
              inputVariant='outlined'
            />
          </div>
          <span style={{ width: 60 }}></span>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <KeyboardDatePicker
              required
              margin='normal'
              id='end-date'
              label={intl.formatMessage({ id: 'offer.label.endDate' })}
              format='DD/MM/yyyy'
              inputVariant='outlined'
              value={endDate}
              onChange={handleDateChange('endDate')}
              error={endDateError !== ''}
              helperText={getFormatedMessage('endDateError')}
              disablePast
              minDate={startDate}
              disabled={unlimited}
            />
          </div>
        </div> */}
      </div>
      <Typography color='error'>
        {getFormatedMessage('messageError')}
      </Typography>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  ${(props) => offerStyles(props)}
`;

const mapStateToProps = ({ offer }: RootState) => {
  console.log(
    '🚀 ~ file: index.tsx ~ line 269 ~ mapStateToProps ~ offer',
    offer
  );
  const {
    image,
    startDate,
    endDate,
    description,
    loading,
    unlimited,
    status,
    statusError,
    //errors
    imageError,
    startDateError,
    endDateError,
    descriptionError,
    messageError,
  } = offer;

  return {
    image,
    startDate,
    unlimited,
    endDate,
    description,
    loading,
    status,
    statusError,
    //errors
    imageError,
    startDateError,
    endDateError,
    descriptionError,
    messageError,
  };
};

export const connector = connect(mapStateToProps, {
  offerPropsChanged,
  editOfferConfirm,
  offerInitialState,
});

export default connector(EditOffer);
