import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import QRCode from 'qrcode.react';
import TextEditor from '../../components/TextEditor';
import { ic_warning } from 'react-icons-kit/md/ic_warning';
import {
  isNumber,
  getNatural,
  getDecimal,
  isUrl,
  isEmail,
  isPhoneNumber,
} from '../../utils/validations';

//Style
import styled from 'styled-components';
import { createEventStyles } from './createEvent.styles';
//Material
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
//Components
import InputLabel from '../../commons/Inputs/InputLabel';
//Commomns
import ColorPicker from '../../commons/ColorPicker';
import Button from '../../commons/Button';

import MomentUtils from '@date-io/moment';
import moment from 'moment';
//Actions
import { eventPropsChanged, addEvent, eventInitialState } from '../../actions';
//Reducers
import { RootState } from '../../reducers';
import cover from '../../assets/images/lg-thumbnail.png';
import {
  EventFormProps,
  EventStateError,
  EventDateState,
} from './createEvent.types';
import InputFile from '../../commons/Inputs/InputFile';
import {
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
  Switch,
  Typography,
  Divider,
} from '@material-ui/core';
import { getDataFromCache } from '../../utils/cache';
import MapComponent from '../../components/MapComponent';

import Icon from 'react-icons-kit';
import EventWireframeAndroid from '../../components/EventWireframeAndroid';
import EventWireframeIPhone from '../../components/EventWireframeIPhone';
import { Prompt } from 'react-router';
import Calendar from '../../icons/Calendar';
//onDateChange?: (date: Moment | null) => void;

const CreateEvent: React.FC<EventFormProps> = (props) => {
  const intl = useIntl();
  const {
    address,
    contactPhone,
    date,
    email,
    strip,
    backgroundColor,
    foregroundColor,
    labelColor,
    locationName,
    emailSubscriptionMessage,
    subscriptionMessage,
    name,
    price,
    terms,
    website,
    loading,
    locations,
    imageError,
    nameError,
    locationNameError,
    addressError,
    openingHourError,
    closingHourError,
    doorOpeningHourError,
    priceError,
    termsError,
    contactPhoneError,
    emailError,
    websiteError,
    labelColorError,
    backgroundColorError,
    foregroundColorError,
    subscriptionMessageError,
    emailSubscriptionMessageError,
    onSaveChanged,
  } = props;
  const objectUser = getDataFromCache('user');
  const myRef = useRef(null);

  React.useEffect(() => {
    props.eventInitialState();
    /* eslint-disable-next-line */
    //   myRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  React.useEffect(() => {
    var options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };

    function success(pos: any) {
      var crd = pos.coords;
      handleLocationChange(
        { lat: crd.latitude, lng: crd.longitude },
        'locations',
        'latLng'
      );
    }

    function error(err: any) {
      console.warn(`ERROR(${err.code}): ${err.message}`);
    }

    navigator.geolocation.getCurrentPosition(success, error, options);
  }, []);
  const [hasStateChanged, setHasStateChange] = React.useState<boolean>(false);
  const [switchPreview, setSwitchPreview] = React.useState<string>('android');
  const [QRcode, setQRcode] = React.useState<any>({ state: true, value: '' });
  const handlePreviewChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSwitchPreview((event.target as HTMLInputElement).value);
  };

  const handleDateChange = (prop: string) => (dateRes: Date | null) => {
    setHasStateChange(true);
    let dateClone = { ...date };
    switch (prop) {
      case 'openingHour': {
        dateClone.openingHour = moment(dateRes);
        break;
      }
      case 'closingHour': {
        dateClone.closingHour = moment(dateRes);
        break;
      }
      case 'doorOpeningHour': {
        dateClone.doorOpeningHour = moment(dateRes);
        break;
      }
      default:
        break;
    }
    props.eventPropsChanged('date', dateClone);
  };

  const handleColorChange = (color: any, prop: string) => {
    setHasStateChange(true);

    props.eventPropsChanged(prop, color);
  };

  const handleFileChange = (event: any, prop: string) => {
    setHasStateChange(true);

    const file = event.target.files[0];
    props.eventPropsChanged(prop, file);
  };

  const isPriceValid = (value: string): boolean => {
    if (!isNumber(value)) return false;
    if (getNatural(value).toString().length > 5) {
      props.eventPropsChanged(
        'priceError',
        'Vous avez dépassé le nombre maximum avant le point/virgule'
      );
      return false;
    }

    if (getDecimal(value).toString().length > 2) {
      props.eventPropsChanged(
        'priceError',
        'Vous avez dépassé le nombre maximum aprés le point/virgule'
      );
      return false;
    }
    return true;
  };

  const areTextAreasValid = (fieldName: string, value: string): boolean => {
    if (value.length > 200) {
      props.eventPropsChanged(
        `${fieldName}Error`,
        'La longueur maximale a été atteinte'
      );
      return false;
    }
    return true;
  };

  const isInputTextValid = (fieldName: string, value: string): boolean => {
    if (value.length > 25) {
      props.eventPropsChanged(
        `${fieldName}Error`,
        'La longueur maximale a été atteinte'
      );
      return false;
    }
    if (fieldName === 'website')
      if (!isUrl(value)) {
        props.eventPropsChanged(fieldName, value);
        props.eventPropsChanged(`${fieldName}Error`, 'URL is invalid');
        return false;
      }
    if (fieldName === 'email') {
      if (!isEmail(value)) {
        props.eventPropsChanged(fieldName, value);
        props.eventPropsChanged(`${fieldName}Error`, 'Email is invalid');
        return false;
      }
    }

    if (fieldName === 'contactPhone') {
      if (!isPhoneNumber(value)) {
        props.eventPropsChanged(fieldName, value);
        props.eventPropsChanged(`${fieldName}Error`, 'Phone is invalid');
        return false;
      }
    }

    return true;
  };

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHasStateChange(true);
    const textAreas = [
      'terms',
      'emailSubscriptionMessage',
      'subscriptionMessage',
    ];

    const inputFeilds = [
      address,
      'contactPhone',
      'date',
      'email',
      'locationName',
      'name',
      'website',
      'address',
    ];
    const value = event.target.value;
    const fieldName = event.target.name;
    props.eventPropsChanged(`${fieldName}Error`, '');

    if (fieldName === 'price') {
      if (!isPriceValid(value)) return;
    }

    if (fieldName === 'name') {
      if (value.length > 25) {
        return;
      }
      setemailMessageEditorValue(
        `<p>Vous étes invités à l'événement ${value}.</p> <p>Pour avoir plus de détails veuillez cliquer sur le bouton ci-dessous.</p>`
      );
    }
    if (textAreas.includes(fieldName))
      if (!areTextAreasValid(fieldName, value)) return;

    //all other fields
    if (inputFeilds.includes(fieldName))
      if (!isInputTextValid(fieldName, value)) return;

    props.eventPropsChanged(fieldName, value);
  };

  const handleClickConfirm = () => {
    setHasStateChange(false);
    props.addEvent(objectUser.wallet, props, () => history.push('/event'));
  };

  const [emailMessageEditorValue, setemailMessageEditorValue] = React.useState<
    string
  >(
    `<p>Vous étes invités à l'événement.</p> <p>Pour avoir plus de détails veuillez cliquer sur le bouton ci-dessous.</p>`
  );

  const handleEmailMessageChange = (value: string) => {
    setHasStateChange(true);

    props.eventPropsChanged('emailSubscriptionMessage', value);
    // setemailMessageEditorValue(value);
  };

  const getFormatedMessage = (prop: EventStateError) => {
    return props[prop] !== ''
      ? intl.formatMessage({ id: props[prop] })
      : props[prop];
  };

  const handleLocationChange = (value: any, prop: string, type: string) => {
    setHasStateChange(true);

    let locationClone = [...locations];
    if (type === 'latLng') {
      locationClone[0].latitude = value.lat;
      locationClone[0].longitude = value.lng;
    } else if (type === 'description') {
      locationClone[0].relevantText = value;
    }
    props.eventPropsChanged(prop, locationClone);
  };

  const history = useHistory();

  return (
    <Wrapper style={{ display: 'flex', flexDirection: 'row' }}>
      <Prompt
        when={hasStateChanged}
        message='Vous avez des changements non enregistrés. Êtes-vous sûr de vouloir quitter cette page ?'
      />
      <div>
        <div className='form-header'>
          <span className='title'>
            {intl.formatMessage({ id: 'event.label.title' })}
          </span>
          {/* <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginLeft: '47rem',
            }}
          >
            <Button
              background='#FFFFFF'
              border='1px solid #FF5078'
              color='#FF5078'
              onClick={() => history.push('/event')}
            >
              Annuler
            </Button>
            <Button styled='primary' onClick={handleClickConfirm}>
              {loading ? (
                <CircularProgress size={20} color='inherit' />
              ) : (
                intl.formatMessage({ id: 'event.label.create' })
              )}
            </Button>
          </div> */}
          <div className='action'>
            {(imageError ||
              nameError ||
              locationNameError ||
              addressError ||
              openingHourError ||
              closingHourError ||
              doorOpeningHourError ||
              priceError ||
              termsError ||
              contactPhoneError ||
              emailError ||
              websiteError ||
              labelColorError ||
              backgroundColorError ||
              foregroundColorError ||
              subscriptionMessageError ||
              emailSubscriptionMessageError) && (
              <div className='error-icon'>
                <Icon
                  icon={ic_warning}
                  size={24}
                  style={{ color: '#FF5078' }}
                />
              </div>
            )}
          </div>
        </div>
        <div className='div-container'>
          <div className='section first-section'>
            {/* <h3 className='section-title'>
              {intl.formatMessage({ id: 'event.label.section.style' })}
            </h3> */}
            <div className='section-row'>
              <InputFile
                className='input-label'
                width={280}
                label={intl.formatMessage({ id: 'event.label.image' })}
                image={strip}
                defaultImage={cover}
                error={getFormatedMessage('imageError')}
                onChange={(e) => {
                  handleFileChange(e, 'strip');
                }}
              ></InputFile>
            </div>
            <div
              className='section-row'
              style={{ marginLeft: '2rem', marginTop: '2rem' }}
            >
              <ColorPicker
                label={intl.formatMessage({ id: 'event.label.labelColor' })}
                color={labelColor}
                error={getFormatedMessage('labelColorError')}
                onChange={(color: any) => {
                  handleColorChange(color, 'labelColor');
                }}
              />

              <ColorPicker
                label={intl.formatMessage({
                  id: 'coupon.label.foregroundColor',
                })}
                color={foregroundColor}
                error={getFormatedMessage('foregroundColorError')}
                onChange={(color: any) => {
                  handleColorChange(color, 'foregroundColor');
                }}
              />
              <ColorPicker
                label={intl.formatMessage({
                  id: 'coupon.label.backgroundColor',
                })}
                color={backgroundColor}
                error={getFormatedMessage('backgroundColorError')}
                onChange={(color: any) => {
                  handleColorChange(color, 'backgroundColor');
                }}
              />
            </div>
            <div className='section-row'>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  //margin: '5 0 5 0',

                  marginTop: '5rem',
                }}
              >
                <Typography style={{ display: 'flex', alignItems: 'center' }}>
                  Code à barres
                </Typography>
                <Switch
                  size='medium'
                  checked={QRcode.state}
                  onChange={() => {
                    setQRcode((prev: any) => {
                      return { ...prev, state: !prev.state };
                    });
                  }}
                  name='checkedA'
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
                <Typography style={{ display: 'flex', alignItems: 'center' }}>
                  Code QR
                </Typography>
              </div>
            </div>
          </div>
          <div className='wrapper'>
            <div className='grid-one'>
              <h3 className='section-title'>
                {intl.formatMessage({ id: 'event.label.section.info' })}
              </h3>
              <InputLabel
                label={intl.formatMessage({ id: 'event.label.name' })}
                name='name'
                value={name}
                error={getFormatedMessage('nameError')}
                placeholder={intl.formatMessage({ id: 'event.label.name' })}
                onChange={(e) => handleEventChange(e)}
              />
              <InputLabel
                label={intl.formatMessage({ id: 'event.label.price' })}
                name='price'
                value={price}
                error={getFormatedMessage('priceError')}
                placeholder={intl.formatMessage({ id: 'event.label.price' })}
                onChange={(e) => handleEventChange(e)}
              />
            </div>
            <div>
              <h3 className='section-title'>
                {intl.formatMessage({ id: 'event.label.section.timeLocation' })}
              </h3>
              <div style={{ marginTop: '2rem', width: '86%' }}>
                <KeyboardDateTimePicker
                  required
                  fullWidth
                  margin='normal'
                  label={intl.formatMessage({
                    id: 'event.label.doorOpeningHour',
                  })}
                  format='LLL'
                  ampm={false}
                  inputVariant='outlined'
                  value={date.doorOpeningHour}
                  onChange={handleDateChange('doorOpeningHour')}
                  error={doorOpeningHourError !== ''}
                  helperText={getFormatedMessage('doorOpeningHourError')}
                  disablePast
                  keyboardIcon={
                    <>
                      <Divider
                        className='divider'
                        orientation='vertical'
                        variant='inset'
                        light
                      />
                      <Calendar />
                    </>
                  }
                />
                <KeyboardDateTimePicker
                  required
                  fullWidth
                  margin='normal'
                  id='start-date'
                  label={intl.formatMessage({ id: 'event.label.openingHour' })}
                  format='LLL'
                  value={date.openingHour}
                  onChange={handleDateChange('openingHour')}
                  error={openingHourError !== ''}
                  ampm={false}
                  helperText={getFormatedMessage('openingHourError')}
                  disablePast
                  inputVariant='outlined'
                  minDate={date.doorOpeningHour}
                  keyboardIcon={
                    <>
                      <Divider
                        className='divider'
                        orientation='vertical'
                        variant='inset'
                        light
                      />
                      <Calendar />
                    </>
                  }
                />
              </div>
            </div>
            <div style={{ marginTop: '3.8rem', width: '86%' }}>
              <KeyboardDateTimePicker
                required
                fullWidth
                margin='normal'
                ampm={false}
                label={intl.formatMessage({ id: 'event.label.closingHour' })}
                format='LLL'
                inputVariant='outlined'
                value={date.closingHour}
                onChange={handleDateChange('closingHour')}
                error={closingHourError !== ''}
                helperText={getFormatedMessage('closingHourError')}
                disablePast
                minDate={date.openingHour}
                keyboardIcon={
                  <>
                    <Divider
                      className='divider'
                      orientation='vertical'
                      variant='inset'
                      light
                    />
                    <Calendar />
                  </>
                }
              />
            </div>
          </div>

          {/* <div className='second-section'>
            <h3 className='section-title'>
              {intl.formatMessage({ id: 'event.label.section.info' })}
            </h3>
            <div className='second-section-row'>
              <InputLabel
                label={intl.formatMessage({ id: 'event.label.name' })}
                name='name'
                value={name}
                error={getFormatedMessage('nameError')}
                placeholder={intl.formatMessage({ id: 'event.label.name' })}
                onChange={(e) => handleEventChange(e)}
              />
              <InputLabel
                label={intl.formatMessage({ id: 'event.label.price' })}
                name='price'
                value={price}
                error={getFormatedMessage('priceError')}
                placeholder={intl.formatMessage({ id: 'event.label.price' })}
                onChange={(e) => handleEventChange(e)}
              />
            </div>
          </div> */}

          <div className='section'>
            <h3 className='section-title'>
              {intl.formatMessage({ id: 'event.label.selectLocation' })}
            </h3>
            <div className='section-row'>
              <InputLabel
                width={480}
                label={intl.formatMessage({ id: 'event.label.locationName' })}
                name='locationName'
                value={locationName}
                error={getFormatedMessage('locationNameError')}
                placeholder={intl.formatMessage({
                  id: 'event.label.locationName',
                })}
                onChange={(e) => handleEventChange(e)}
              />

              <InputLabel
                width={480}
                label={intl.formatMessage({ id: 'event.label.address' })}
                name='address'
                value={address}
                error={getFormatedMessage('addressError')}
                placeholder={intl.formatMessage({
                  id: 'event.label.address',
                })}
                onChange={(e) => handleEventChange(e)}
              />
            </div>
            <div className='map'>
              <div className='map-label'>
                {/* <span>
                  {intl.formatMessage({ id: 'event.label.selectLocation' })}
                </span> */}
                <span>
                  Lat: {locations[0].latitude}, Lng: {locations[0].longitude}
                </span>
              </div>
              <MapComponent
                onChange={handleLocationChange}
                locations={locations}
              />
            </div>
          </div>
          <div className='section'>
            <h3 className='section-title' style={{ marginTop: '2rem' }}>
              {intl.formatMessage({ id: 'event.label.section.contact' })}
            </h3>
            <div className='section-row'>
              <InputLabel
                label={intl.formatMessage({ id: 'event.label.contactPhone' })}
                name='contactPhone'
                value={contactPhone}
                error={getFormatedMessage('contactPhoneError')}
                placeholder={intl.formatMessage({
                  id: 'event.label.contactPhone',
                })}
                onChange={(e) => handleEventChange(e)}
              />
              <InputLabel
                label={intl.formatMessage({ id: 'event.label.email' })}
                name='email'
                value={email}
                error={getFormatedMessage('emailError')}
                placeholder={intl.formatMessage({ id: 'event.label.email' })}
                onChange={(e) => handleEventChange(e)}
              />
              <InputLabel
                label={'Site Web*'}
                name='website'
                value={website}
                error={getFormatedMessage('websiteError')}
                placeholder={intl.formatMessage({ id: 'event.label.website' })}
                onChange={(e) => handleEventChange(e)}
              />
            </div>
            <div className='section-row'>
              <InputLabel
                inputType='textArea'
                label={intl.formatMessage({ id: 'event.label.terms' })}
                name='terms'
                value={terms}
                error={getFormatedMessage('termsError')}
                // width={320}
                placeholder={intl.formatMessage({
                  id: 'event.label.terms',
                })}
                onChange={(e) => handleEventChange(e)}
              />
              <InputLabel
                ref={myRef}
                inputType='textArea'
                label={intl.formatMessage({ id: 'event.label.subMessage' })}
                name='subscriptionMessage'
                value={subscriptionMessage}
                error={getFormatedMessage('subscriptionMessageError')}
                // width={320}
                placeholder={intl.formatMessage({
                  id: 'event.label.subMessage',
                })}
                onChange={(e) => handleEventChange(e)}
              />
            </div>
          </div>
          <div className='section'>
            <h3 className='section-title'>
              {intl.formatMessage({ id: 'event.label.section.emailMessage' })}
            </h3>
            <div className='section-row'>
              <TextEditor
                label={intl.formatMessage({
                  id: 'email.label.emailMessage',
                })}
                placeholder={intl.formatMessage({
                  id: 'email.label.emailMessage',
                })}
                oldState={emailMessageEditorValue}
                changeValue={handleEmailMessageChange}
                error={getFormatedMessage('emailSubscriptionMessageError')}
              />
            </div>
          </div>
          {/* <div className='div-content'> */}
        </div>
      </div>

      <div
        className='section wireframe-section'
        style={{ marginTop: 100, marginLeft: 5, width: '326px' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: '-4.8rem',
            marginBottom: '1rem',
          }}
        >
          <Button
            background='#FFFFFF'
            border='1px solid #FF5078'
            color='#FF5078'
            onClick={() => history.push('/event')}
          >
            Annuler
          </Button>
          <Button styled='primary' onClick={handleClickConfirm}>
            {loading ? (
              <CircularProgress size={20} color='inherit' />
            ) : (
              intl.formatMessage({ id: 'event.label.create' })
            )}
          </Button>
        </div>
        <RadioGroup
          name='previews'
          value={switchPreview}
          onChange={handlePreviewChange}
          className='radioGroup'
          style={{ display: 'flex', flexDirection: 'row', width: 200 }}
        >
          <FormControlLabel
            value='android'
            control={<Radio />}
            label='Android'
          />
          <FormControlLabel value='iOS' control={<Radio />} label='iOS' />
        </RadioGroup>

        {switchPreview === 'android' && (
          <EventWireframeAndroid
            backgroundColor={backgroundColor}
            strip={strip}
            price={price}
            startDate={date.openingHour}
            endDate={date.closingHour}
            openDate={date.doorOpeningHour}
            terms={terms}
            name={name}
            locationName={locationName}
            subscriptionMessage={subscriptionMessage}
            QrCode={QRcode}
          />
        )}

        {switchPreview === 'iOS' && (
          <EventWireframeIPhone
            backgroundColor={backgroundColor}
            contactPhone={contactPhone}
            locations={locations}
            email={email}
            strip={strip}
            price={price}
            startDate={date.openingHour}
            endDate={date.closingHour}
            openDate={date.doorOpeningHour}
            terms={terms}
            name={name}
            locationName={locationName}
            titleColor={labelColor.hex}
            textColor={foregroundColor.hex}
            subscriptionMessage={subscriptionMessage}
            QrCode={QRcode}
          />
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  ${(props) => createEventStyles(props)}
`;

const mapStateToProps = ({ event }: RootState) => {
  const {
    address,
    contactPhone,
    date,
    email,
    strip,
    backgroundColor,
    foregroundColor,
    labelColor,
    locationName,
    emailSubscriptionMessage,
    subscriptionMessage,
    name,
    price,
    terms,
    website,
    loading,
    locations,
    //errors
    imageError,
    nameError,
    locationNameError,
    addressError,
    openingHourError,
    closingHourError,
    doorOpeningHourError,
    priceError,
    termsError,
    contactPhoneError,
    emailError,
    websiteError,
    labelColorError,
    backgroundColorError,
    foregroundColorError,
    subscriptionMessageError,
    emailSubscriptionMessageError,
  } = event;
  return {
    address,
    contactPhone,
    date,
    email,
    strip,
    backgroundColor,
    foregroundColor,
    labelColor,
    locationName,
    emailSubscriptionMessage,
    subscriptionMessage,
    name,
    price,
    terms,
    website,
    loading,
    locations,
    //errors
    imageError,
    nameError,
    locationNameError,
    addressError,
    openingHourError,
    closingHourError,
    doorOpeningHourError,
    priceError,
    termsError,
    contactPhoneError,
    emailError,
    websiteError,
    labelColorError,
    backgroundColorError,
    foregroundColorError,
    subscriptionMessageError,
    emailSubscriptionMessageError,
  };
};

export const connector = connect(mapStateToProps, {
  eventPropsChanged,
  addEvent,
  eventInitialState,
});

export default connector(CreateEvent);
