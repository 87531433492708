import { css } from 'styled-components';

export const getColorPickerStyles = (props: any) => {
  return css`
    margin: 8px 0;
    .label {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: #1a2d35;
      margin: 5px;
      display: block;
    }
    .div-contain {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: space-between;
      flex-direction: column-reverse;

      //width: 280px;
      //border-radius: 8px;
      margin: 5px 0;
      padding: 6px;
      // box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
      @media (max-width: 850px) {
        width: 230px;
      }
    }
    .color {
      width: 65px;
      height: 69px;
      border-radius: 25px;
      background: ${props.color};
      border: 1px solid #c0c0c0;
    }
    .swatch {
      cursor: pointer;
    }
    .popover {
      bottom: 10%;
      position: absolute;
      z-index: 2;
    }
    .cover {
      position: fixed;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: '0px';
    }
    .text-error {
      /* align-self: flex-start; */
      font-size: 14px;
      color: #ed0723;
      margin-bottom: 8px;
      margin: 5px;
    }
  `;
};
