import {
  FERRY_FORM_PROPS,
  FERRY_FORM_PROPS_CHANGED,
  FERRY_GET_FERRY_PROPS,
  FERRY_INITIAL_STATE,
} from '../actions/types';
import { FerryState, FerryActionTypes } from '../modules/Ship/ship.types';
import moment from 'moment';

const INITIAL_STATE: FerryState = {
  loading: false,
  labelColor: {
    hex: '#000000',
    rgb: { r: 0, g: 0, b: 0, a: 1 },
  },
  backgroundColor: {
    hex: '#ffffff',
    rgb: { r: 255, g: 255, b: 255, a: 1 },
  },
  foregroundColor: {
    hex: '#000000',
    rgb: { r: 0, g: 0, b: 0, a: 1 },
  },
  number: '',
  originStationCode: '',
  originName: '',
  destinationStationCode: '',
  destinationName: '',
  departureTime: '',
  arrivalTime: '',
  tarif: '',
  details: '',
  terms: '',
  customerServiceNumber: '',
  unlimited: false,
  //errors
  numberError: '',
  originStationCodeError: '',
  originNameError: '',
  destinationStationCodeError: '',
  destinationNameError: '',
  departureTimeError: '',
  arrivalTimeError: '',
  tarifError: '',
  detailsError: '',
  termsError: '',
  customerServiceNumberError: '',
  imageError: '',
  strip: null,
  image: null,
  stripError: '',
  labelColorError: '',
  backgroundColorError: '',
  foregroundColorError: '',
};

export default (state = INITIAL_STATE, action: FerryActionTypes) => {
  switch (action.type) {
    case FERRY_FORM_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };
    case FERRY_FORM_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };
    case FERRY_FORM_PROPS:
      return { ...state, ...action.newState, loading: false };
    case FERRY_GET_FERRY_PROPS:
      return { ...state, ...action.payload };
    case FERRY_INITIAL_STATE:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};
