import axios from 'axios';
import { URL } from './config';

export const getFerry = (walletId: string, ferryId: string) => {
  const requestURL = `${URL}/wallets/${walletId}/transits/${ferryId}`;

  return axios
    .get(requestURL)
    .then(({ data }: { data: any }) => {
      return data;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const createFerry = (walledId: string | null, ferry: any) => {
  // ferry.locations = [
  //   {
  //     longitude: 0,
  //     latitude: 0,
  //   },
  // ];
  // ferry.type = 'ferry';
  const requestURL = `${URL}/wallets/${walledId}/transits?=ferry`;
  return axios
    .post(requestURL, ferry)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

export const updateFerry = (
  walledId: string | null,
  ferryId: string | null,
  ferry: any
): Promise<any> => {
  const requestURL = `${URL}/wallets/${walledId}/transits/${ferryId}`;
  return axios
    .put(requestURL, ferry)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

export const removeFerry = (ferryId: string, walletId: string) => {
  const requestURL = `${URL}/wallets/${walletId}/transits/${ferryId}`;

  return axios
    .delete(requestURL)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};
