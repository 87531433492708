import axios from 'axios';
import { URL } from './config';
import { SettingsState } from '../modules/Settings/settings.types';
import { SettingsApiResponse } from './ApiTypes';

export const getSettings = (walletID: string) => {
  const requestURL = `${URL}/settings/${walletID}`;

  return axios
    .get(requestURL)
    .then(({ data }: { data: SettingsApiResponse }) => {
      return data;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const addSettingsToWallet = (
  walletId: String,
  props: SettingsState,
  walletName: string
) => {
  const requestURL = `${URL}/settings/${walletId}`;
  return axios
    .patch(requestURL, {
      successMessage: props.successMessage,
      emailMessage: props.emailMessage,
      subscriptionMessage: props.subscriptionMessage,
      newOfferEmailMessage: props.newOfferEmailMessage,
      newOfferNotificationMessage: props.newOfferNotificationMessage,
      newCouponEmailMessage: props.newCouponEmailMessage,
      newCouponNotificationMessage: props.newCouponNotificationMessage,
      expiredOfferEmailMessage: props.expiredOfferEmailMessage,
      expiredOfferNotificationMessage: props.expiredOfferNotificationMessage,
      newUpdateEmailMessage: props.newUpdateEmailMessage,
      newUpdateNotificationMessage: props.newUpdateNotificationMessage,
      enableNewUpdateEmailMessage: props.enableNewUpdateEmailMessage,
      enableOfferEmailMessage: props.enableOfferEmailMessage,
      enableOfferSMSMessage: props.enableOfferSMSMessage,
      showBarcode: props.showBarcode,

      enableCouponEmailMessage: props.enableCouponEmailMessage,
      communicationChannels: props.communicationChannels,
      smsTitle: props.smsTitle ? props.smsTitle : walletName,
      newAccountSMSMessage: props.newAccountSMSMessage,
      newEventSMSMessage: props.newEventSMSMessage,
      newOfferSMSMessage: props.newOfferSMSMessage,
      expiredOfferSMSMessage: props.expiredOfferSMSMessage,
      newCouponSMSMessage: props.newCouponSMSMessage,
      newUpdateSMSMessage: props.newUpdateSMSMessage,
      wallet: walletId,
    })
    .then(({ data }: { data: SettingsApiResponse }) => {
      return data;
    })
    .catch((error) => {
      return error.response;
    });
};
