import 'date-fns';
import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
//Style
import styled from 'styled-components';
import { couponStyles } from './coupon.styles';
//Material
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
//Components
import InputLabel from '../../commons/Inputs/InputLabel';
//Commomns
import ColorPicker from '../../commons/ColorPicker';

import Button from '../../commons/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '../../commons/Inputs/Input';
import Select from '@material-ui/core/Select';
//Actions
import {
  couponPropsChanged,
  couponInitialState,
  editCouponConfirm,
} from '../../actions';
//Reducers
import { RootState } from '../../reducers';
//Type
import { CouponFormProps, CouponStateError } from './coupon.types';

import cover from '../../assets/images/lg-thumbnail.png';

import InputFile from '../../commons/Inputs/InputFile';
import { isNumber, getNatural, getDecimal } from '../../utils/validations';

import { Currency } from '../../apis/ApiTypes';
import CouponWireframeIPhone from '../../components/CouponWireframeIPhone';
import CouponWireframeAndroid from '../../components/CouponWireframeAndroid';

const EditCoupon: React.FC<CouponFormProps> = (props) => {
  const intl = useIntl();
  const {
    couponId,
    walletId,
    expirationDate,
    labelColor,
    backgroundColor,
    foregroundColor,
    percentReduction,
    promoCode,
    image,
    source,
    description,
    loading,
    currencies,
    symbol,
    name,
    template,
    expirationDateError,
    percentReductionError,
    promoCodeError,
    nameError,
    imageError,
    descriptionError,
    onSaveChanged,
    onCancel,
  } = props;
  /*
  React.useEffect(() => {
    props.couponInitialState();
  }, []);
*/

  const handleColorChange = (color: any, prop: string) => {
    props.couponPropsChanged(prop, color);
  };

  const [switchPreview, setSwitchPreview] = React.useState<string>('android');

  const handleFileChange = (event: any, prop: string) => {
    const file = event.target.files[0];
    props.couponPropsChanged(prop, file);
  };

  const handleDateChange = (prop: string) => (date: Date | null) => {
    props.couponPropsChanged(prop, date);
  };

  const handleClickConfirm = () => {
    props.editCouponConfirm(walletId, couponId, props, () => onSaveChanged());
  };

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    const name = event.target.name;
    if (value == '') {
      props.couponPropsChanged(name, value);
    }
    props.couponPropsChanged('promoCodeError', '');
    props.couponPropsChanged('percentReductionError', '');
    if (name == 'description' && value.length > 200) return;
    if (name !== 'description' && value.length > 25) return;
    if (name === 'percentReduction' && !isNumber(Number.parseInt(value))) {
      return;
    }

    if (name === 'percentReduction') {
      if (symbol == '%') {
        if (value.indexOf('.') != -1 || value.indexOf(',') != -1) {
          props.couponPropsChanged(
            'percentReductionError',
            'Seuls les chiffres sont autorisés'
          );
          return;
        }
        if (Number.parseInt(value) > 100) {
          props.couponPropsChanged(
            'percentReductionError',
            '100% est le pourcentage maximum autorisé'
          );
          return;
        }
      } else {
        if (getNatural(value).toString().length > 4) {
          props.couponPropsChanged(
            'percentReductionError',
            'Vous avez dépassé le nombre maximum avant le point/virgule'
          );
          return;
        }

        if (getDecimal(value).toString().length > 2) {
          props.couponPropsChanged(
            'percentReductionError',
            'Vous avez dépassé le nombre maximum aprés le point/virgule'
          );
          return;
        }
      }
    }

    if (name == 'promoCode') {
      if (value.length > 9) {
        props.couponPropsChanged(
          'promoCodeError',
          'Promo code ne peut pas comporter plus de 9 caractères'
        );
        return;
      }
    }
    props.couponPropsChanged(name, value);
  };

  const getFormatedMessage = (prop: CouponStateError) => {
    return props[prop] !== ''
      ? intl.formatMessage({ id: props[prop] })
      : props[prop];
  };
  const handleSelectChangePercent = (event: any) => {
    props.couponPropsChanged('symbol', event.target.value);
  };

  const handleSelectChange = (event: any) => {
    props.couponPropsChanged('source', event.target.value);
  };

  const handlePreviewChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSwitchPreview((event.target as HTMLInputElement).value);
  };
  return (
    <Wrapper>
      <span className='title'>ModifierCoupon</span>
      <div className='div-container' style={{ marginLeft: '15px' }}>
        <div className='div-content'>
          <div style={{ marginLeft: '-99px' }}>
            <InputFile
              child={template}
              width={220}
              label={intl.formatMessage({ id: 'coupon.label.image' })}
              image={image}
              defaultImage={cover}
              error={getFormatedMessage('imageError')}
              onChange={(e) => {
                handleFileChange(e, 'image');
              }}
            ></InputFile>
          </div>

          <ColorPicker
            label={intl.formatMessage({ id: 'coupon.label.textColor' })}
            color={labelColor}
            error={getFormatedMessage('labelColorError')}
            onChange={(color: any) => {
              handleColorChange(color, 'labelColor');
            }}
          />

          <ColorPicker
            label={intl.formatMessage({ id: 'coupon.label.foregroundColor' })}
            color={foregroundColor}
            error={getFormatedMessage('foregroundColorError')}
            onChange={(color: any) => {
              handleColorChange(color, 'foregroundColor');
            }}
          />
          <ColorPicker
            label={intl.formatMessage({ id: 'coupon.label.backgroundColor' })}
            color={backgroundColor}
            error={getFormatedMessage('backgroundColorError')}
            onChange={(color: any) => {
              handleColorChange(color, 'backgroundColor');
            }}
          />
        </div>
        <div className='div-content' style={{ marginLeft: '30px' }}>
          <InputLabel
            label={intl.formatMessage({ id: 'coupon.label.name' })}
            name='name'
            value={name}
            error={getFormatedMessage('nameError')}
            placeholder='Nom'
            onChange={(e) => handleEventChange(e)}
          />
          <div className='select'>
            <p>Source</p>
            <Select
              // open={selectOpen}
              // onOpen={() => setSelectOpen(true)}
              label={intl.formatMessage({ id: 'coupon.label.source' })}
              onChange={(e) => handleSelectChange(e)}
              defaultValue='ALL'
              value={source}
            >
              <MenuItem value='ALL'>
                {intl.formatMessage({ id: 'coupon.label.All' })}
              </MenuItem>
              <MenuItem value='internal'>
                {intl.formatMessage({ id: 'coupon.label.Internal' })}
              </MenuItem>
              <MenuItem value='external'>
                {intl.formatMessage({ id: 'coupon.label.External' })}
              </MenuItem>
              <MenuItem value='crm'>
                {intl.formatMessage({ id: 'coupon.label.crm' })}
              </MenuItem>
            </Select>
          </div>
          <KeyboardDatePicker
            required
            margin='normal'
            id='start-date'
            label={intl.formatMessage({ id: 'coupon.label.expirationDate' })}
            format='DD/MM/yyyy'
            value={expirationDate}
            onChange={handleDateChange('expirationDate')}
            error={expirationDateError !== ''}
            helperText={getFormatedMessage('expirationDateError')}
            disablePast
            fullWidth
            inputVariant='outlined'
          />
          <span className='percentLabel'>
            {intl.formatMessage({ id: 'coupon.label.percent' })}
          </span>
          <div className='div-input-filter'>
            <div
              className='select'
              style={{
                marginBottom: 'initial',
                display: 'flex',
                width: '90%',
                alignItems: 'center',
              }}
            >
              <Select
                value={symbol}
                onChange={(e) => handleSelectChangePercent(e)}
                name='symbol'
                placeholder='%'
              >
                <MenuItem value='%'>
                  <span>%</span>
                </MenuItem>
                {currencies.map((currency: Currency, index: number) => {
                  return (
                    <MenuItem value={currency.currencySymbol} key={index}>
                      <span>{currency.currencySymbol}</span>
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            <Input
              name='percentReduction'
              value={percentReduction}
              placeholder='20'
              onChange={(e) => handleEventChange(e)}
              className='inputPercent'
            />
          </div>
          <span className='input-text-error'>
            {getFormatedMessage('percentReductionError')}
          </span>
          <InputLabel
            label={intl.formatMessage({ id: 'coupon.label.promoCode' })}
            name='promoCode'
            value={promoCode}
            error={getFormatedMessage('promoCodeError')}
            placeholder='Promo Code'
            onChange={(e) => handleEventChange(e)}
          />
          <InputLabel
            inputType='textArea'
            label={intl.formatMessage({ id: 'coupon.label.description' })}
            name='description'
            value={description}
            error={getFormatedMessage('descriptionError')}
            width={415}
            placeholder={intl.formatMessage({
              id: 'coupon.placeholder.description',
            })}
            onChange={(e) => handleEventChange(e)}
          />
        </div>
        <div
          className='section wireframe-section'
          style={{ marginLeft: 25, marginRight: 25, maxWidth: 356 }}
        >
          <RadioGroup
            name='previews'
            value={switchPreview}
            onChange={handlePreviewChange}
            className='radioGroup'
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <FormControlLabel
              value='android'
              control={<Radio />}
              label='Android'
            />
            <FormControlLabel value='iOS' control={<Radio />} label='iOS' />
          </RadioGroup>

          {switchPreview === 'android' && (
            <CouponWireframeAndroid
              backgroundColor={backgroundColor}
              strip={image}
              promotion={percentReduction}
              promoCode={promoCode}
              description={description}
              expires={expirationDate?.toString() || ''}
              name={name}
              symbol={symbol}
            />
          )}

          {switchPreview === 'iOS' && (
            <CouponWireframeIPhone
              backgroundColor={backgroundColor}
              image={image}
              promotion={percentReduction}
              promoCode={promoCode}
              description={description}
              titleColor={labelColor.hex}
              textColor={foregroundColor.hex}
              name={name}
              symbol={symbol}
            />
          )}
        </div>
      </div>{' '}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
        }}
      >
        <Button onClick={onCancel}>Annuler</Button>
        <Button onClick={handleClickConfirm}>
          {loading ? (
            <CircularProgress size={20} color='inherit' />
          ) : (
            'Sauvgarder'
          )}
        </Button>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  ${(props) => couponStyles(props)}
`;

const mapStateToProps = ({ coupon, walletForm }: RootState) => {
  const {
    expirationDate,
    labelColor,
    backgroundColor,
    foregroundColor,
    percentReduction,
    promoCode,
    image,
    source,
    template,
    description,
    loading,
    symbol,
    name,
    expirationDateError,
    labelColorError,
    backgroundColorError,
    foregroundColorError,
    percentReductionError,
    promoCodeError,
    nameError,
    imageError,
    descriptionError,
  } = coupon;
  return {
    expirationDate,
    template,
    labelColor,
    backgroundColor,
    foregroundColor,
    percentReduction,
    promoCode,
    image,
    source,
    description,
    loading,
    symbol,
    name,
    currencies: walletForm.currencies,
    expirationDateError,
    labelColorError,
    backgroundColorError,
    foregroundColorError,
    percentReductionError,
    promoCodeError,
    nameError,
    imageError,
    descriptionError,
  };
};

export const connector = connect(mapStateToProps, {
  couponPropsChanged,
  couponInitialState,
  editCouponConfirm,
});

export default connector(EditCoupon);
