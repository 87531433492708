import React from 'react';
import { useIntl } from 'react-intl';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
//Style
import styled from 'styled-components';
import { loginStyles } from './login.styles';
//Commomns
import Input from '../../commons/Inputs/Input';
import Button from '../../commons/Button';
import ForgotPasswpordModal from '../../components/Modal';
import ForgotPassword from '../ForgotPassword';
//Actions
import { loginPropsChanged, onLogin } from '../../actions';

import logo from '../../assets/images/BestWallets_Logo.png';
import Wallet from '../../assets/images/Wallet';
import { RootState } from '../../reducers';
import { LoginFormProps, LoginStateError } from './login.types';
import { CircularProgress } from '@material-ui/core';
import { getDataFromCache } from '../../utils/cache';
import clsx from 'clsx';
const Login: React.FC<LoginFormProps> = (props) => {
  const intl = useIntl();
  const [open, setOpen] = React.useState<boolean>(false);
  const { username, password, error, loading } = props;

  const token = getDataFromCache('token');
  const objectUser: any = getDataFromCache('user');

  // if (token && objectUser.wallet) {
  //   return <Redirect to={{ pathname: `/wallet/${walletId}` }} />;
  // }

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.loginPropsChanged(event.target.name, event.target.value);
  };

  const handleLogin = () => {
    if (username.trim() === '' && password.trim() === '') {
      props.loginPropsChanged(
        'error',
        intl.formatMessage({ id: 'login.error.fieldsReqiured' })
      );
    } else
      props.onLogin(username, password, (walletId: string) => {
        // props.history.push(`/wallet/${walletId}`);
        props.history.push(`/statistics`);
      });

    //  else {
    //   props.loginPropsChanged(
    //     'error',
    //     intl.formatMessage({ id: 'login.error.errorMessage' })
    //   );
    // }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13 || event.which === 13) {
      handleLogin();
    }
  };

  const getFormatedMessage = (prop: LoginStateError) => {
    return props[prop] !== ''
      ? intl.formatMessage({ id: props[prop] })
      : props[prop];
  };
  const style = {
    display: 'flex',
    justifyContent: 'flex-end',
    // Adding media querry..
    '@media (max-width: 500px)': {
      svg: {
        visibility: 'hidden',
      },
    },
  };
  return (
    <Wrapper>
      <ForgotPasswpordModal open={open} onClose={() => setOpen(!open)}>
        <ForgotPassword onSaveChanged={() => setOpen(!open)}></ForgotPassword>
      </ForgotPasswpordModal>
      <div style={style}>
        <Wallet />
      </div>
      {/* <span className='login-text'>Login</span> */}
      <div className='form-container'>
        <div className='signin'>
          <div className={clsx('div-inputs', 'sign-in-form')}>
            <label className='title'>Adresse Mail</label>
            <Input
              // error={intl.formatMessage({ id: 'login.error.errorMessage' })}
              value={username}
              type='text'
              name='username'
              placeholder={"Nom d'Utilisateur"}
              onChange={(e) => handleEventChange(e)}
              onKeyPress={(e) => handleKeyPress(e)}
              margin={'15px 0px'}
            />
            <label className='title'>Mot de Passe</label>
            <Input
              // error={intl.formatMessage({ id: 'login.error.errorMessage' })}
              value={password}
              type='password'
              name='password'
              placeholder={intl.formatMessage({
                id: 'login.placeholder.password',
              })}
              onChange={(e) => handleEventChange(e)}
              onKeyPress={(e) => handleKeyPress(e)}
              margin={'15px 0px'}
            />
            <span
              onClick={() => setOpen(!open)}
              className='forgot-password-text'
            >
              {intl.formatMessage({ id: 'login.label.forgotPassword' })}
            </span>
            {error && (
              <span className='login-text-error'>
                {getFormatedMessage('error')}
                {/* {intl.formatMessage({ id: 'login.error.errorMessage' })} */}
              </span>
            )}

            <Button onClick={handleLogin} margin='10px' background='#FF5078'>
              {loading ? (
                <CircularProgress size={20} color='inherit' />
              ) : (
                intl.formatMessage({ id: 'login.button.login' })
              )}
            </Button>
          </div>
        </div>
      </div>
      <div className='panels-container'>
        <div className='panel left-panel'>
          <div className='Logo '>
            <img src={logo} alt='logo' />
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  ${(props) => loginStyles(props)}
`;

const mapStateToProps = ({ login }: RootState) => {
  return {
    username: login.username,
    password: login.password,
    error: login.error,
    loading: login.loading,
  };
};

export const connector = connect(mapStateToProps, {
  loginPropsChanged,
  onLogin,
});

export default connector(Login);
