import { css } from 'styled-components';

export type OffersStylesProps = {
  loading: string;
};

export const offersStyles = (props: OffersStylesProps) => {
  let flex = 0;
  let justifyContent = 'flex-start';

  return css`
    display: flex;
    flex-direction: column;
    /* padding: 10px; */

    .div-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 15px;
    }

    .div-input-filter {
        min-width: 400px;
        margin-right: 15px;
      }

      .filter-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      > input {
        background-color: #fff;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #404040;
        box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.08);
        border-radius: 15px;
      }
    } 

    .filter-button{
        display: flex;
        position: absolute;
        left: 290px;
        width: 100px;
        align-items: center;
        justify-content: space-between;
        background: #4914F4;
        height: 46px;
        box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.08);
        border-radius: 15px;
        padding: 0 10px;
        transition: all 0.3s ease;
        :hover {
          cursor: pointer;
          background: #6438f5;
        }
        > h3{
          /* font-family: Poppins; */
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: white;
          width: 80px;
        }
      }
      .filter-opened{
        transform: rotate(180deg)
      }

      .select-input {
        width: 150px;
        visibility: hidden;
        position: absolute;
        left: 260px;
        top: 70px;
      }
    .div-search {
      display: flex;
      align-items: center;
      margin-top: 30px;
      margin-bottom: 15px;
      /* width: 100%; */
      .div-search-inputs{
        display:flex;
        flex: 1;  
       }

      .div-search-date {
        display: flex;
        gap: 30px
        /* position: relative; */
        /* .div-checkbox {
          position: absolute;
          bottom: -35px; */
        }
      }
    }
   
    .div-list {
      margin: 15px 0;
      display: flex;
      flex: ${flex};
      justify-content: ${justifyContent};
      flex-wrap: wrap;
      /* min-height: 500px; */
      align-items: center;
     // gap: 10px;
    }
    .div-list-container {
      margin: 15px 0;
      display: flex;
      flex: ${flex};
      justify-content: center;
      flex-wrap: wrap;
      /* min-height: 500px; */
      align-items: center;
     // gap: 10px;
     position: relative;
    }
    .divider {
      height: 28px;
      width: 2px;
      margin: 4px;
      margin-right: 15px;
      background: #404040;
    }
    .MuiDivider-root {
      background-color: #c7c7c7 !important;
    }
    .MuiOutlinedInput-adornedEnd {
      background-color: #fff;
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #404040;
      box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.08);
      border-radius: 15px;
    }
    .MuiIconButton-root.Mui-disabled {
      color: rgba(0, 0, 0, 0.38) !important;
    }
    .email-card {
      //background-color: red;
      .makeStyles-root-54 {
        width: 230px ;
        max-height: 55px;
        border: 2px solid #404040;
        box-shadow: none;
      }
      .makeStyles-container-56{
        display: flex;
      }
      
      /* .MuiTypography-h4{
        background-color: #404040;
        border-radius: 50%;
      } */
    }
    .total-card {
      .makeStyles-Container-68 {
         border: '2px solid #FF5078' !important
         
       }
    }
.externe{
  .makeStyles-clientRect-73 {
    background-color: #691FDE;
  }
  .makeStyles-clientContent-75{
    border: 2px solid #691FDE;
    color: #691FDE;
  }
  .MuiTypography-subtitle2{
    font-weight: 600;
    

  }
}
.interne{
  .clientRect {
    background-color: #9D30BB;
  }
  .clientContent{
    border: 2px solid #9D30BB;
    color: #9D30BB;
  }
  .MuiTypography-subtitle2{
    font-weight: 600;
    

  }
}
.crm{
  .makeStyles-clientRect-73 {
    background-color: #FF5078;
  }
  .makeStyles-clientContent-75{
    border: 2px solid #FF5078;
    color: #FF5078;
  }
  .MuiTypography-subtitle2{
    font-weight: 600;
    

  }
}
.lines-one {
  position: absolute;
  top: 2.1rem;
    /* left: 157px; */
    right: 11rem;
}
.lines-two {
  position: absolute;
  top: 2.1rem;
    right: -22rem;
}

   
  
  `
};
