import { addSettingsToWallet, getSettings } from '../apis/settingsApi';
import {
  SETTINGS_PROPS_CHANGED,
  SETTINGS_INITIAL_STATE,
  SETTINGS_GET_WALLET_SETTINGS,
  SETTINGS_GET_WALLET_DETAIL_FAILED,
  AppThunk,
  SETTINGS_GET_WALLET_FORM,
  SNACKBAR_SUCCESS,
  SNACKBAR_ERROR,
} from './types';
import {
  SettingsInitialStateAction,
  SettingsState,
} from '../modules/Settings/settings.types';
import {
  SettingsApiResponse,
  GetWalletApiError,
  WalletApiResponse,
} from '../apis/ApiTypes';
import { getWalletById } from '../apis/walletApi';

const checkPropLength = (
  errorProp: string,
  value: string,
  errorValue: string,
  maxLength: number
): AppThunk => (dispatch) => {
  if (value.length > maxLength) {
    dispatch({
      type: SETTINGS_PROPS_CHANGED,
      prop: errorProp,
      value: errorValue,
    });
  } else {
    dispatch({
      type: SETTINGS_PROPS_CHANGED,
      prop: errorProp,
      value: '',
    });
  }
};

export const settingsPropsChanged = (prop: string, value: any): AppThunk => (
  dispatch
) => {
  const maxLength = 120;

  if (prop === 'smsTitle') {
    dispatch(
      checkPropLength(
        'smsTitleError',
        value,
        ' La longueur maximale du titre est de 11 caractères ',
        11
      )
    );
  }
  if (prop === 'newCouponSMSMessage') {
    dispatch(
      checkPropLength(
        'newCouponSMSMessageError',
        value,
        `La longueur maximale est ${maxLength} caractères `,
        maxLength
      )
    );
  }
  if (prop === 'newOfferSMSMessage') {
    dispatch(
      checkPropLength(
        'newOfferSMSMessageError',
        value,
        `La longueur maximale est ${maxLength} caractères `,
        maxLength
      )
    );
  }
  if (prop === 'newAccountSMSMessage') {
    dispatch(
      checkPropLength(
        'newAccountSMSMessageError',
        value,
        `La longueur maximale est ${maxLength} caractères `,
        maxLength
      )
    );
  }
  dispatch({ type: SETTINGS_PROPS_CHANGED, prop, value });
};

export const getWalletSettings = (walletId: string): AppThunk => (dispatch) => {
  getSettings(walletId).then((res: SettingsApiResponse) => {
    dispatch({
      type: SETTINGS_GET_WALLET_SETTINGS,
      payload: res.response.data,
    });
  });
};

export const showSMSAccountError = (
  hasSMSAccount: boolean,
  intl: any
): AppThunk => (dispatch) => {
  dispatch({
    type: SNACKBAR_ERROR,
    value: intl.formatMessage({
      id: hasSMSAccount
        ? 'settings.communicationChannel.noCreditsMessage'
        : `settings.communicationChannel.noAccountMessage`,
    }),
  });
};

export const confirmSettings = (
  walletId: string,
  props: SettingsState,
  saveCommunication: boolean
): AppThunk => (dispatch) => {
  if (saveCommunication === true) {
    dispatch({
      type: SETTINGS_PROPS_CHANGED,
      prop: 'loadingSaveCommunicationChannels',
      value: true,
    });
  } else {
    let smsTitle = props.smsTitle.trim();
    if (smsTitle == '') {
      // Save wallet name as sms title
      if (props.walletForm.name.length > 11) {
        smsTitle = props.walletForm.name.substring(0, 11);
      }
      dispatch({
        type: SETTINGS_PROPS_CHANGED,
        prop: 'smsTitle',
        value: smsTitle,
      });
    }

    if (
      props.newAccountSMSMessageError ||
      props.newOfferSMSMessageError ||
      props.smsTitleError
    ) {
      return;
    }

    dispatch({
      type: SETTINGS_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });
  }
  addSettingsToWallet(walletId, props, props.walletForm.name).then(
    (res: SettingsApiResponse) => {
      if (res.status === 500 || res.status === 400) {
        dispatch({
          type: SNACKBAR_ERROR,
          value: 'Une erreur est survenue',
        });
      }
      if (res.status === 'success') {
        dispatch({
          type: SNACKBAR_SUCCESS,
          value: 'Les paramètres sont sauvegardés avec succès',
        });
      }
      dispatch({
        type: SETTINGS_PROPS_CHANGED,
        prop: 'loadingSaveCommunicationChannels',
        value: false,
      });
      dispatch({
        type: SETTINGS_PROPS_CHANGED,
        prop: 'loading',
        value: false,
      });
    }
  );
};

export const settingsInitialState = (): SettingsInitialStateAction => {
  return { type: SETTINGS_INITIAL_STATE };
};

export const getWalletDetail = (walletId: string): AppThunk => (dispatch) => {
  getWalletById(
    walletId,
    (error: GetWalletApiError, res: Array<WalletApiResponse>) => {
      if (error && error.response) {
        let message = '';
        if (error.response.status === 401) {
          message = "Vous n'êtes pas autorisé.";
        } else if (error.response.status === 404) {
          message = 'Wallet est introuvable';
        }
        dispatch({
          type: SETTINGS_GET_WALLET_DETAIL_FAILED,
          payload: message,
        });
      } else {
        dispatch({
          type: SETTINGS_GET_WALLET_FORM,
          payload: res[0],
        });
      }
    }
  );
};
