import React from 'react';
import { Wrapper } from './couponWireframe.style';
import { ic_close } from 'react-icons-kit/md/ic_close';

import { ratio } from 'wcag-color';
import barcode from '../../assets/images/barcode.png';

import cover from '../../assets/images/lg-thumbnail.png';

import { ic_more_vert } from 'react-icons-kit/md/ic_more_vert';
import Icon from 'react-icons-kit';

import moment from 'moment';

import { getDataFromCache } from '../../utils/cache';
import { URL_IMAGE } from '../../apis/config';

type WireframeProps = {
  backgroundColor: any;
  promoCode: any;
  strip: any;
  expires: any;
  promotion: any;
  description: any;
  name: string;
  symbol: string;
};

const CouponWireframeAndroid: React.FC<WireframeProps> = (props) => {
  const {
    backgroundColor,
    promoCode,
    strip,
    expires,
    promotion,
    description,
    name,
    symbol,
  } = props;
  const currentWallet = getDataFromCache('wallet');

  return (
    <Wrapper
      backgroundColor={backgroundColor}
      mainContentTextColor={
        ratio('#ffffff', backgroundColor.hex) > 3.0 ? '#ffffff' : '#000000'
      }
    >
      <div className='mainSection'>
        <div className='mainSection-header'>
          <Icon
            size={18}
            icon={ic_close}
            style={
              ratio('#ffffff', backgroundColor.hex) > 3.0
                ? { color: '#ffffff' }
                : { color: '#000000' }
            }
          />
          <Icon
            size={18}
            icon={ic_more_vert}
            style={
              ratio('#ffffff', backgroundColor.hex) > 3.0
                ? { color: '#ffffff' }
                : { color: '#000000' }
            }
          />
        </div>
        <div className='mainSection-header-logo'>
          <img
            src={`${URL_IMAGE}/${currentWallet.logoPictureUrl}`}
            alt='wallet Logo'
          />
        </div>
        <div className='mainSection-content-enseigne'>
          <h4>{name || 'Coupon'}</h4>
          <h3 className='walletName'>{currentWallet.name || 'wallet'}</h3>
        </div>
        <div className='mainSection-content-banner'>
          <img
            src={
              !strip
                ? cover
                : typeof strip === 'string'
                ? strip
                : URL.createObjectURL(strip)
            }
            alt='banner'
          />
        </div>
        <div className='mainSection-content-barCode'>
          <div className='barCode'>
            <img src={barcode} alt='barcode' />
          </div>
          <p className='walletId'>{promoCode || 'promoCode'}</p>
        </div>
      </div>
      <div className='dataSection'>
        <span className='divider'></span>
        <div className='sectionLabel'>
          <h3 className='label'>Date d'expiration :</h3>
          <h3 className='data'>
            {moment(expires).format('MMM DD, YYYY, h:mm a')}
          </h3>
        </div>
        <span className='divider'></span>
        <div className='sectionLabel'>
          <h3 className='label'>De</h3>
          <h3 className='data'>{currentWallet.name || 'wallet'}</h3>
        </div>
        <span className='divider'></span>
        <div className='sectionLabel'>
          <img
            src={
              !strip
                ? cover
                : typeof strip === 'string'
                ? strip
                : URL.createObjectURL(strip)
            }
            alt='banner'
            style={{ height: 150, width: 300 }}
          />
        </div>
        <span className='divider'></span>
        <div className='sectionLabel'>
          <h3 className='label'>description</h3>
          <h3 className='data'>{description || 'description'}</h3>
        </div>
        <span className='divider'></span>
        <div className='sectionLabel'>
          <h3 className='label'>Valeur</h3>
          <h3 className='data'>{promotion || 0 + ' ' + symbol}</h3>
        </div>
        <span className='divider'></span>
        <div className='sectionLabel'>
          <h3 className='label'>Promo Code</h3>
          <h3 className='data'>{promoCode || 'promoCode'}</h3>
        </div>
        <span className='divider'></span>
        <div className='sectionLabel'>
          <h3 className='label'>Id coupon</h3>
          <h3 className='data'>61dc3b460779650007267001</h3>
        </div>
      </div>
    </Wrapper>
  );
};

export default CouponWireframeAndroid;
