import {
  updatePassword,
  updateEmailSettings,
  updateEmail,
} from '../apis/profileApi';
import { getSettings } from '../apis/settingsApi';

import {
  PROFILE_PROPS_CHANGED,
  PROFILE_VALIDATION_PROPS,
  PROFILE_INITIAL_STATE,
  PROFILE_GET_WALLET_EMAIL_SETTINGS,
  AppThunk,
  SNACKBAR_SUCCESS,
  SNACKBAR_ERROR,
  SNACKBAR_INFO,
} from './types';
import {
  ProfilePropsChangedAction,
  ProfileState,
  ProfileInitialStateAction,
  ProfileEmailSettings,
} from '../modules/Profile/profile.types';

import {
  validateFieldsProfileUpdatePassword,
  validateFieldsProfileCommunicationEmailSettings,
} from '../modules/Profile/profile.utils';
import { getDataFromCache, setInStorage } from '../utils/cache';

export const profilePropsChanged = (prop: string, value: any): AppThunk => (
  dispatch
) => {
  dispatch({ type: PROFILE_PROPS_CHANGED, prop, value });
};

export const profileConfirmUpdatePassword = (
  userId: string,
  props: ProfileState,
  callback: () => void
): AppThunk => (dispatch) => {
  const { newState, valid } = validateFieldsProfileUpdatePassword(props);
  dispatch({ type: PROFILE_VALIDATION_PROPS, newState });
  if (valid) {
    dispatch({
      type: PROFILE_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });
    updatePassword(userId, props).then((res: any) => {
      if (res.status === 200) {
        dispatch({
          type: PROFILE_PROPS_CHANGED,
          prop: 'loading',
          value: false,
        });
        callback();
      } else if (res.status === 401) {
        dispatch({
          type: PROFILE_PROPS_CHANGED,
          prop: 'oldPasswordError',
          value: 'profile.error.oldPasswordIncorrect',
        });
        dispatch({
          type: PROFILE_PROPS_CHANGED,
          prop: 'loading',
          value: false,
        });
      } else {
        dispatch({
          type: PROFILE_PROPS_CHANGED,
          prop: 'loading',
          value: false,
        });
      }
    });
  }
};

export const updateManagerEmail = (userEmail: string): AppThunk => (
  dispatch
) => {
  const is_empty = validateFieldsProfileCommunicationEmailSettings(userEmail);

  if (is_empty) {
    dispatch({
      type: PROFILE_PROPS_CHANGED,
      prop: 'userEmailError',
      value: 'profile.error.managerEmail',
    });
  } else {
    updateEmail(userEmail).then((res: any) => {
      if (res.status === 200) {
        dispatch({
          type: PROFILE_PROPS_CHANGED,
          prop: 'successMessage',
          value: 'profile.success.successMessage',
        });
        const objectUser: any = getDataFromCache('user');

        setInStorage('user', {
          ...objectUser,
          email: userEmail,
        });

        dispatch({
          type: PROFILE_PROPS_CHANGED,
          prop: 'loading',
          value: false,
        });
      } else if (res.data.indexOf('email already exists') > 0) {
        dispatch({
          type: SNACKBAR_INFO,
          value: "L'email existe déjà",
        });
      } else if (res.data.indexOf('valid email') > 0) {
        dispatch({
          type: PROFILE_PROPS_CHANGED,
          prop: 'userEmailError',
          value: "L'email est invalid",
        });
      } else {
        dispatch({
          type: SNACKBAR_ERROR,
          value: 'Erreur est survenue',
        });
      }
    });
  }
};

export const profileUpdateEmailSettings = (
  props: ProfileEmailSettings,
  callback: () => void
): AppThunk => (dispatch) => {
  let userEmail = props?.email;
  const is_empty = validateFieldsProfileCommunicationEmailSettings(userEmail);
  if (is_empty) {
    dispatch({
      type: PROFILE_PROPS_CHANGED,
      prop: 'userEmailError',
      value: 'profile.error.managerEmail',
    });
  } else {
    dispatch({
      type: PROFILE_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });
    updateEmailSettings(props).then((res: any) => {
      if (res.status === 200) {
        dispatch({
          type: PROFILE_PROPS_CHANGED,
          prop: 'successMessage',
          value: 'profile.success.successMessage',
        });
        dispatch({
          type: PROFILE_PROPS_CHANGED,
          prop: 'loading',
          value: false,
        });
        callback();
      } else {
        if (res.status === 401) {
          dispatch({
            type: PROFILE_PROPS_CHANGED,
            prop: 'userPasswordError',
            value: 'profile.error.userPasswordError',
          });
        } else if (res.status === 400) {
          dispatch({
            type: PROFILE_PROPS_CHANGED,
            prop: 'userPrincipalEamilExistsError',
            value: res.data.message,
          });
        } else {
          dispatch({
            type: PROFILE_PROPS_CHANGED,
            prop: 'emailSettingsError',
            value: 'profile.error.emailSettingsError',
          });
        }
        dispatch({
          type: PROFILE_PROPS_CHANGED,
          prop: 'loading',
          value: false,
        });
        // dispatch({
        //   type: PROFILE_PROPS_CHANGED,
        //   prop: 'userPassword',
        //   value: '',
        // });
      }
    });
  }
};

export const profileGetWalletSettings = (
  email: string,
  walletId: string
): AppThunk => (dispatch) => {
  getSettings(walletId).then((res: any) => {
    dispatch({
      type: PROFILE_GET_WALLET_EMAIL_SETTINGS,
      payload: {
        ...res.response.data.communicationEmail,
        emailingConfig: res.response.data.emailingConfig,
      },
      email: email,
    });
  });
};

export const profileInitialState = (): ProfileInitialStateAction => {
  return { type: PROFILE_INITIAL_STATE };
};

export const profileSuccessMessage = (): AppThunk => (dispatch) => {
  dispatch({
    type: PROFILE_PROPS_CHANGED,
    prop: 'successMessage',
    value: 'profile.success.successMessage',
  });
};
