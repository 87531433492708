import React from 'react';
import { useIntl } from 'react-intl';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import ConfirmationModal from '../../Modal';
//Styles
import { useStyles } from './offerCard.styles';
import logo from '../../../assets/images/logo-orgazone.png';
import {
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Switch,
} from '@material-ui/core';
import { OfferApiResponse } from '../../../apis/ApiTypes';
import { URL_IMAGE } from '../../../apis/config';
import moment from 'moment';
import Text from '../../../commons/Text';
import Button from '../../../commons/Button';

import CircularProgress from '@material-ui/core/CircularProgress';

type OfferCardProps = {
  offer: OfferApiResponse;
  onClickUpdate: (value: string, action: string) => void;
  onClickEdit: (id: string) => void;
  onClickActivate: (id: string, status: string) => void;
};

type StatusProps = 'ACTIF' | 'OPENED';

const OfferCard: React.FC<OfferCardProps> = (props) => {
  const intl = useIntl();
  const classes = useStyles();
  const { offer, onClickUpdate, onClickEdit, onClickActivate } = props;
  const status =
    offer && (offer.status === 'ACTIF' || offer.status === 'OPENED');
  let statusLabel = '';
  switch (offer.status) {
    case 'OPENED':
      statusLabel = intl.formatMessage({ id: 'offers.select.opened' });
      break;
    case 'ACTIF':
      statusLabel = intl.formatMessage({ id: 'offers.select.actif' });
      break;
    case 'INACTIF':
      statusLabel = intl.formatMessage({ id: 'offers.select.inactif' });
      break;
    case 'EXPIRED':
      statusLabel = intl.formatMessage({ id: 'offers.select.expired' });
      break;
    case 'DELETED':
      statusLabel = intl.formatMessage({ id: 'offers.select.deleted' });
      break;
    default:
      break;
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [is_open, setIsOpen] = React.useState<any>(null);

  const [is_loading, setIsLoading] = React.useState<any>(null);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };
  const [checked, setChecked] = React.useState(false);

  const toggleChecked = () => {
    setChecked((prev) => !prev);
  };

  return (
    <Card className={classes.root}>
      {offer.status != 'DELETED' && (
        <div className={classes.cardMenu}>
          <IconButton
            aria-label='more'
            aria-controls='long-menu'
            aria-haspopup='true'
            className={classes.iconButton}
            onClick={handleClick}
            style={{
              background: 'rgba(0, 0, 0, 0.349)',
              padding: '2px',
            }}
          >
            <MoreHorizIcon fontSize='small' className={classes.menuIcon} />
          </IconButton>

          <Menu
            id='simple-menu'
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: -50 }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            keepMounted
            open={Boolean(anchorEl)}
            onClick={handleClose}
          >
            <MenuItem
              onClick={() => onClickEdit(offer._id)}
              onMouseEnter={(e) =>
                ((e.target as HTMLInputElement).style.color = '#ff5078')
              }
              onMouseLeave={(e) =>
                ((e.target as HTMLInputElement).style.color = '#000000')
              }
            >
              Modifier
            </MenuItem>

            {(offer.status == 'INACTIF' || offer.status == 'EXPIRED') && (
              <MenuItem
                onClick={() => onClickUpdate(offer._id, 'deleted')}
                onMouseEnter={(e) =>
                  ((e.target as HTMLInputElement).style.color = '#ff5078')
                }
                onMouseLeave={(e) =>
                  ((e.target as HTMLInputElement).style.color = '#000000')
                }
              >
                {intl.formatMessage({ id: 'offerCard.button.delete' })}
              </MenuItem>
            )}
          </Menu>
          <ConfirmationModal open={is_open}>
            <div className='modal-cntr'>
              <span style={{ marginTop: '15 px' }}>
                Voulez vous Supprimer l'offre ?{' '}
              </span>
              {is_open ? (
                <div
                  style={{
                    display: 'flex',
                    marginTop: '10px',
                    justifyContent: 'space-evenly',
                  }}
                >
                  <Button
                    styled='secondary'
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  >
                    Annuler
                  </Button>

                  <Button
                    styled='primary'
                    onClick={() => {
                      onClickUpdate(offer._id, 'delete');
                    }}
                  >
                    {is_loading ? (
                      <CircularProgress size={20} color='inherit' />
                    ) : (
                      ''
                    )}
                    confirmer
                  </Button>
                </div>
              ) : (
                ''
              )}
            </div>
          </ConfirmationModal>
        </div>
      )}
      <CardMedia
        className={classes.media}
        image={`${URL_IMAGE}/${offer.offerPictureUrl}`}
        title='strip'
      />

      {/* <Divider /> */}
      <CardContent className={classes.cardContent}>
        {/* <div className={classes.label}>
          <Text color='#828282' fontSize={14} lineHeight={21} fontWeight={500}>
            {intl.formatMessage({ id: 'offerCard.label.id' })}
          </Text>
          <Text color='#404040' fontSize={14} lineHeight={21} fontWeight={500}>
            {offer._id}
          </Text>
        </div> */}
        <div className={classes.label}>
          <Text color='#828282' fontSize={14} lineHeight={21} fontWeight={500}>
            {intl.formatMessage({ id: 'offerCard.label.status' })}
          </Text>
          <Text color='#FF5078' fontSize={14} lineHeight={21} fontWeight={500}>
            {statusLabel}
          </Text>
        </div>
        <div className={classes.label}>
          <Text color='#828282' fontSize={14} lineHeight={21} fontWeight={500}>
            {intl.formatMessage({ id: 'offerCard.label.startDate' })}
          </Text>
          <Text color='#404040' fontSize={14} lineHeight={21} fontWeight={500}>
            {moment(offer.startDate).format('L')}
          </Text>
        </div>
        <div className={classes.label}>
          <Text color='#828282' fontSize={14} lineHeight={21} fontWeight={500}>
            {intl.formatMessage({ id: 'offerCard.label.endDate' })}
          </Text>
          <Text color='#404040' fontSize={14} lineHeight={21} fontWeight={500}>
            {new Date(offer.endDate) < new Date('2099-01-01T01:00:00.000')
              ? moment(offer.endDate).format('L')
              : 'illimité'}
          </Text>
        </div>

        <Text color='#828282' fontSize={14} lineHeight={21} fontWeight={500}>
          {intl.formatMessage({ id: 'offerCard.label.description' })}
        </Text>
        <div className={classes.cardDescription}>
          <Text color='#404040' fontSize={12} lineHeight={21} className='clamp'>
            {offer.description}
          </Text>
        </div>
        {/* <div style={{ marginTop: '10px' }}>
          <FormControl component='fieldset'>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    size='small'
                    checked={!checked}
                    onChange={toggleChecked}
                  />
                }
                // label={`${checked ? 'Activé' : 'Désactivé'}`}
                label={statusLabel}
              />
            </FormGroup>
          </FormControl>
        </div> */}
      </CardContent>
      <CardActions
        style={{
          padding: '1px 16px 16px 16px',
          justifyContent: 'space-between',
          marginTop: '-10px',
        }}
      >
        <div className={classes.activateCoupon}>
          <Switch
            // disabled={disabled}
            checked={status}
            size='medium'
            onChange={() => onClickActivate(offer._id, offer.status)}
          />
          <Text
            fontSize={12}
            lineHeight={21}
            // color={disabled ? '#767676' : '#000000'}
          >
            {intl.formatMessage({ id: 'couponCard.switch.activate' })}
          </Text>
        </div>
      </CardActions>
      {/* {status && (
        <CardActions>
          <Button
            size='small'
            color='primary'
            onClick={() => onClickDeactivate(offer._id, offer.status)}
          >
            {intl.formatMessage({ id: 'offerCard.button.desactivate' })}
          </Button>
        </CardActions>
      )} */}
    </Card>
  );
};

export default OfferCard;
