import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='45'
      height='45'
      fill='none'
      viewBox='0 0 54 49'
    >
      <path
        fill='#FF5078'
        fillRule='evenodd'
        d='M47.955 24.303c0 2.196 1.803 3.983 4.02 3.983 1.118 0 2.025.9 2.025 2.007v7.226c0 6.11-5.017 11.081-11.183 11.081h-31.63C5.018 48.6 0 43.63 0 37.519v-7.226c0-1.108.907-2.007 2.025-2.007 2.22 0 4.023-1.787 4.023-3.983 0-2.14-1.73-3.751-4.023-3.751a2.03 2.03 0 01-1.431-.589A1.998 1.998 0 010 18.545l.005-7.461C.005 4.974 5.022 0 11.19 0H42.81c6.167 0 11.186 4.973 11.186 11.084L54 18.313c0 .53-.213 1.043-.591 1.418-.381.377-.894.588-1.434.588-2.217 0-4.02 1.787-4.02 3.984zm-14.121 6.089a8.34 8.34 0 012.43-7.414c.553-.53.745-1.31.505-2.033a1.97 1.97 0 00-1.61-1.34 8.485 8.485 0 01-6.366-4.586 1.99 1.99 0 00-1.788-1.105H27c-.761 0-1.447.423-1.79 1.103a8.464 8.464 0 01-6.359 4.585c-.758.107-1.38.62-1.62 1.343a1.955 1.955 0 00.505 2.033 8.346 8.346 0 012.433 7.414c-.13.749.175 1.493.796 1.94.352.248.76.377 1.172.377.316 0 .635-.078.926-.23a8.526 8.526 0 017.866-.006 1.98 1.98 0 002.106-.144 1.953 1.953 0 00.799-1.937z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}

export default Icon;
