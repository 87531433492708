import { css } from 'styled-components';
import { lighten, darken } from 'polished';

export const buttonStyles = (props: any) => {
  const height = props.height ? `${props.height}px` : 'auto';
  const width = props.width ? `${props.width}px` : 'max-content';
  const margin = props.margin ? `${props.margin}px` : '5px';
  const background = props.background
    ? props.background
    : 'linear-gradient(265.47deg, #9D30BB 3.98%, #FF5078 96.03%)';
  const color = props.color ? props.color : '#fbfafa';
  const border = props.border ? props.border : 'none';
  const fontSize = props.fontSize ? `${props.fontSize}px` : '16px';

  return css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${height};
    width: ${width};
    min-width: 85px;
    max-width: 100%;
    background: ${background};
    border: ${border};
    border-radius: 15px;
    padding: 12px 30px;
    margin: ${margin};
    box-sizing: border-box;
    box-shadow: 0px 0px 20px rgba(55, 55, 55, 0.05);
    cursor: pointer;
    color: ${color};
    font-style: normal;
    font-size: ${fontSize};
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    text-decoration: none;
    transition: all;
    transition-duration: 0.3s;
    white-space: nowrap;
    outline: none;
    position: relative;
  `;
};
