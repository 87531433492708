import axios from 'axios';
import { URL } from './config';
import { FerriesApiResponse } from './ApiTypes';

export const getFerriesList = (walletID: string) => {
  const requestURL = `${URL}/wallets/${walletID}/transits?type=ferry`;

  return axios
    .get(requestURL)
    .then(({ data }: { data: Array<FerriesApiResponse> }) => {
      return data;
    })
    .catch((error) => {
      throw new Error(error);
    });
};
