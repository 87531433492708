import 'date-fns';
import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
//Style
import styled from 'styled-components';
import { offerStyles } from './offer.styles';
//Material
import { FormControlLabel, Typography } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker } from '@material-ui/pickers';
//Components
import InputLabel from '../../commons/Inputs/InputLabel';
//Commomns
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import Button from '../../commons/Button';

//Actions
import {
  offerPropsChanged,
  offerConfirm,
  offerInitialState,
} from '../../actions';
//Reducers
import { RootState } from '../../reducers';
//Type

// import { AddCustomerFormProps } from './addCustomer.types';

import cover from '../../assets/images/lg-thumbnail.png';
import { OfferFormProps, OfferStateError } from './offer.types';
import InputFile from '../../commons/Inputs/InputFile';
import { CircularProgress } from '@material-ui/core';
import Text from '../../commons/Text';
import moment from 'moment';
//onDateChange?: (date: Moment | null) => void;
const Offer: React.FC<OfferFormProps> = (props) => {
  const intl = useIntl();
  const {
    walletId,
    image,
    startDate,
    endDate,
    description,
    imageError,
    startDateError,
    endDateError,
    descriptionError,
    messageError,
    loading,
    onSaveChanged,
  } = props;

  const [unlimited, setUnlimited] = React.useState<boolean>(false);

  React.useEffect(() => {
    props.offerInitialState();
  }, []);

  const handleDateChange = (prop: string) => (date: Date | null) => {
    props.offerPropsChanged(prop, date);
  };

  const handleFileChange = (event: any, prop: string) => {
    const file = event.target.files[0];
    props.offerPropsChanged(prop, file);
  };

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.offerPropsChanged('descriptionError', '');

    if (event.target.name == 'description')
      if (event.target.value.length > 200) {
        props.offerPropsChanged(
          'descriptionError',
          'La longueur maximale du texte a été atteinte '
        );
        return;
      }
    props.offerPropsChanged(event.target.name, event.target.value);
  };

  const handleClickConfirm = () => {
    props.offerConfirm(walletId, props, unlimited, () => onSaveChanged());
  };

  const getFormatedMessage = (prop: OfferStateError) => {
    return props[prop] !== ''
      ? intl.formatMessage({ id: props[prop] })
      : props[prop];
  };

  return (
    <Wrapper>
      <div className='header'>
        <Text color='#404040' fontSize={16} fontWeight={600} lineHeight={24}>
          {intl.formatMessage({ id: 'offer.label.title' })}
        </Text>
        {/* <Button
            width={135}
            background={'white'}
            border={'1px solid #FF5078'}
            color={'#FF5078'}
          >
            {intl.formatMessage({ id: 'offer.button.cancel' })}
          </Button> */}

        <Button onClick={handleClickConfirm} width={135}>
          {loading ? (
            <CircularProgress size={20} color='inherit' />
          ) : (
            intl.formatMessage({ id: 'offer.button.add' })
          )}
        </Button>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          marginRight: '14rem',
        }}
      >
        <InputFile
          className='btn_parcourir '
          width={270}
          label={intl.formatMessage({ id: 'offer.label.image' })}
          image={image}
          defaultImage={cover}
          error={getFormatedMessage('imageError')}
          onChange={(e) => {
            handleFileChange(e, 'image');
          }}
        ></InputFile>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          margin: '10px 0px !important',
        }}
      >
        <InputLabel
          inputType='textArea'
          label={intl.formatMessage({ id: 'offer.label.description' })}
          name='description'
          value={description}
          error={getFormatedMessage('descriptionError')}
          width={500}
          placeholder={intl.formatMessage({
            id: 'offer.placeholder.description',
          })}
          onChange={(e) => handleEventChange(e)}
        />
      </div>
      <div style={{ marginRight: '24rem', marginTop: '-1rem' }}>
        <FormControlLabel
          control={<Checkbox name='unlimited' />}
          checked={unlimited}
          onChange={() => {
            setUnlimited(!unlimited);
          }}
          label='Illimité'
        />
      </div>
      <div className='div-container'>
        <div className='div-date-cont'>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '2rem',
              width: '500px',
              marginTop: '1rem',
              marginBottom: '-1rem',
            }}
          >
            <KeyboardDatePicker
              required
              margin='normal'
              id='start-date'
              label={intl.formatMessage({ id: 'offer.label.startDate' })}
              format='DD/MM/yyyy'
              value={startDate}
              onChange={handleDateChange('startDate')}
              error={startDateError !== ''}
              helperText={getFormatedMessage('startDateError')}
              disablePast
              inputVariant='outlined'
              // KeyboardButtonProps={{
              //   'aria-label': 'change date',
              // }}
            />
            <KeyboardDatePicker
              required
              margin='normal'
              id='end-date'
              label={intl.formatMessage({ id: 'offer.label.endDate' })}
              format='DD/MM/yyyy'
              inputVariant='outlined'
              value={endDate}
              onChange={handleDateChange('endDate')}
              error={endDateError !== ''}
              helperText={getFormatedMessage('endDateError')}
              disablePast
              minDate={startDate}
              disabled={unlimited}
            />
          </div>
        </div>
      </div>
      <Typography color='error'>
        {getFormatedMessage('messageError')}
      </Typography>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  ${(props) => offerStyles(props)}
`;

const mapStateToProps = ({ offer }: RootState) => {
  const {
    image,
    startDate,
    endDate,
    description,
    loading,
    //errors
    imageError,
    startDateError,
    endDateError,
    descriptionError,
    messageError,
  } = offer;
  return {
    image,
    startDate,
    endDate,
    description,
    loading,
    //errors
    imageError,
    startDateError,
    endDateError,
    descriptionError,
    messageError,
  };
};

export const connector = connect(mapStateToProps, {
  offerPropsChanged,
  offerConfirm,
  offerInitialState,
});

export default connector(Offer);
