import React from 'react';

function wIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1132'
      height='740'
      fill='none'
      viewBox='0 0 1132 913'
    >
      <g filter='url(#filter0_d_190_25256)'>
        <path
          fill='#fff'
          fillOpacity='0.17'
          d='M66.287 174.158l4.612-5.125c89.168-98.4 241.882-106.087 340.275-16.913l206.521 187.061L937.984 50.134c98.906-89.174 251.106-81.487 340.276 17.425l4.61 5.125c89.17 98.911 81.48 251.122-17.42 340.297L871.876 768.14l-99.93 92.249c-97.367 89.687-249.568 84.049-339.249-13.837l-85.581-92.762L82.173 513.942C-15.195 424.768-22.88 272.557 66.287 174.158z'
          shapeRendering='crispEdges'
        ></path>
      </g>
      <defs>
        <filter
          id='filter0_d_190_25256'
          width='1549'
          height='944'
          x='0'
          y='-12'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feColorMatrix
            in='SourceAlpha'
            result='hardAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          ></feColorMatrix>
          <feOffset dy='4'></feOffset>
          <feGaussianBlur stdDeviation='2'></feGaussianBlur>
          <feComposite in2='hardAlpha' operator='out'></feComposite>
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'></feColorMatrix>
          <feBlend
            in2='BackgroundImageFix'
            result='effect1_dropShadow_190_25256'
          ></feBlend>
          <feBlend
            in='SourceGraphic'
            in2='effect1_dropShadow_190_25256'
            result='shape'
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}

export default wIcon;
