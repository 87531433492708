import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='45'
      height='45'
      fill='none'
      viewBox='0 0 54 44'
    >
      <path
        fill='#FC9877'
        fillRule='evenodd'
        d='M53.425 17.655a1.985 1.985 0 01-1.383.558c-1.933 0-3.5 1.522-3.5 3.374 0 1.865 1.549 3.38 3.463 3.4 1.078.01 1.995.74 1.995 1.788v6.507c0 5.476-4.57 9.918-10.21 9.918h-8.512c-.88 0-1.593-.693-1.593-1.547v-5.48c0-1.065-.862-1.902-1.959-1.902-1.07 0-1.958.837-1.958 1.902v5.48c0 .855-.713 1.547-1.59 1.547H10.21C4.596 43.2 0 38.76 0 33.281v-6.506c0-1.048.917-1.778 1.995-1.788 1.917-.02 3.462-1.535 3.462-3.4 0-1.8-1.514-3.17-3.499-3.17a1.986 1.986 0 01-1.384-.559A1.874 1.874 0 010 16.514v-6.57C0 4.474 4.606 0 10.236 0h17.942c.877 0 1.59.693 1.59 1.547v6.494c0 1.04.888 1.903 1.958 1.903 1.097 0 1.959-.863 1.959-1.903V1.547c0-.854.713-1.547 1.593-1.547h8.512C49.43 0 54 4.44 54 9.918v6.393c0 .507-.209.99-.575 1.344zM31.726 29.35c1.097 0 1.958-.863 1.958-1.903V17.301c0-1.04-.861-1.903-1.958-1.903-1.07 0-1.958.862-1.958 1.902v10.147c0 1.04.887 1.903 1.958 1.903z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}

export default Icon;
